import {
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControlLabel,
  Tooltip,
  FormControl,
  Checkbox,
} from '@mui/material'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useState } from 'react'
import { Edit } from '@mui/icons-material'

import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { AnimalDto, AnimalTakeOverDtoOrigin, AnimalTakeOverDtoReason } from '../../../../interactors/gen/backendClient'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { LoadingButton } from '@mui/lab'
import { ControlledDateField } from '../../../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../../../utils/date/isValidPastDateCoherence'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { omit } from 'lodash'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { getReadableTakeOverOrigin, getReadableTakeOverReason } from '../../../../domain/Animal/AnimalDisplay'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

type FormParams = {
  takeOverAt: string | ''
  addressOfOrigin: string | ''
  cityOfOrigin: string | ''
  firstName: string | ''
  lastName: string | ''
  email: string | ''
  phoneNumber: string | ''
  city: string | ''
  ownerFirstName: string | ''
  ownerLastName: string | ''
  ownerEmail: string | ''
  ownerPhoneNumber: string | ''
  ownerCity: string | ''
  reason: AnimalTakeOverDtoReason | ''
  origin: AnimalTakeOverDtoOrigin | ''
  takeOverContactIsAlsoOwner: boolean
}

interface Props {
  animal: AnimalDto
}

export const EditAnimalTakeOverPrompt: FC<Props> = ({ animal }) => {
  const dialogOpenedState = useDialogOpened()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    takeOverAt: animal.takeOver.takeOverAt || '',
    cityOfOrigin: animal.takeOver.cityOfOrigin || '',
    addressOfOrigin: animal.takeOver.addressOfOrigin || '',
    reason: animal.takeOver.reason || '',
    origin: animal.takeOver.origin || '',
    firstName: animal.takeOver.takeOverBy.firstName || '',
    lastName: animal.takeOver.takeOverBy.lastName || '',
    email: animal.takeOver.takeOverBy.email || '',
    phoneNumber: animal.takeOver.takeOverBy.phoneNumber || '',
    city: animal.takeOver.takeOverBy.city || '',
    takeOverContactIsAlsoOwner:
      animal.takeOver.takeOverContactIsAlsoOwner === undefined ? false : animal.takeOver.takeOverContactIsAlsoOwner,
    ownerFirstName: animal.takeOver?.takeOverFrom?.firstName || '',
    ownerLastName: animal.takeOver?.takeOverFrom?.lastName || '',
    ownerEmail: animal.takeOver?.takeOverFrom?.email || '',
    ownerPhoneNumber: animal.takeOver?.takeOverFrom?.phoneNumber || '',
    ownerCity: animal.takeOver?.takeOverFrom?.city || '',
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormExtended(animal, getDefaultValues)

  const [serverError, setServerError] = useState('')

  const editAnimalMutation = useEditAnimalMutation({
    onSuccess: dialogOpenedState.closeDialog,
    onError: (error) => setServerError(JSON.stringify(error)),
  })

  const onSubmit = async (data: FormParams) => {
    console.log('On submit', data)

    const newAnimal = {
      ...animal,
      ...omit(
        data,
        'takeOverAt',
        'cityOfOrigin',
        'takeOverAt',
        'addressOfOrigin',
        'cityOfOrigin',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'reason'
      ),
      takeOver: {
        ...animal.takeOver,
        takeOverAt: data.takeOverAt,
        cityOfOrigin: data.cityOfOrigin,
        addressOfOrigin: data.addressOfOrigin,
        reason: data.reason as AnimalTakeOverDtoReason,
        origin: data.origin as AnimalTakeOverDtoOrigin,
        takeOverContactIsAlsoOwner: data.takeOverContactIsAlsoOwner,
        takeOverBy: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          city: data.city,
        },
        takeOverFrom: data.takeOverContactIsAlsoOwner
          ? undefined
          : {
              firstName: data.ownerFirstName,
              lastName: data.ownerLastName,
              email: data.ownerEmail,
              phoneNumber: data.ownerPhoneNumber,
              city: data.ownerCity,
            },
      },
    }

    const newAnimalWithoutEmptyString = omitEmptyString(newAnimal)

    await editAnimalMutation.mutate(newAnimalWithoutEmptyString)
  }

  const spacingItem = { marginTop: 2 }

  const takeOverContactIsAlsoOwner = watch('takeOverContactIsAlsoOwner')
  const canEditAnimal = useCanEditAnimal()

  if (!canEditAnimal) {
    return null
  }

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog} disabled={!canEditAnimal}>
        <Tooltip title="Editer le profil">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>🐈 Editer le profil de {animal.name}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}>
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ControlledDateField
                sx={spacingItem}
                control={control}
                validate={(value) => isValidPastDateCoherence(value) || !value}
                error={errors.takeOverAt}
                fieldName={'takeOverAt'}
                label="Date de Prise en Charge par l'association"
                requiredRule={undefined}
              />

              <TextField
                id="address-of-origin-input"
                label="Adresse de prise en charge"
                type="text"
                fullWidth
                {...register('addressOfOrigin')}
                sx={spacingItem}
                error={!!errors.addressOfOrigin}
                helperText={errors.addressOfOrigin?.message}
              />

              <TextField
                id="city-of-origin-input"
                label="Ville de prise en charge"
                type="text"
                fullWidth
                {...register('cityOfOrigin')}
                sx={spacingItem}
                error={!!errors.cityOfOrigin}
                helperText={errors.cityOfOrigin?.message}
              />

              <ControlledSelectField
                sx={spacingItem}
                control={control}
                error={errors.origin}
                fieldName="origin"
                label="Origine"
                options={Object.values(AnimalTakeOverDtoOrigin).map((origin) => ({
                  label: getReadableTakeOverOrigin(origin),
                  value: origin,
                }))}
                requiredRule={undefined}
              />

              <ControlledSelectField
                sx={spacingItem}
                control={control}
                error={errors.reason}
                fieldName="reason"
                label="Motif"
                options={Object.values(AnimalTakeOverDtoReason).map((reason) => ({
                  label: getReadableTakeOverReason(reason),
                  value: reason,
                }))}
                requiredRule={undefined}
              />

              <Typography variant="h6" sx={{ marginTop: 6 }}>
                🚹 Contact de prise en charge
              </Typography>

              <TextField
                id="first-name-input"
                label="Prénom"
                type="text"
                fullWidth
                {...register('firstName')}
                sx={spacingItem}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />

              <TextField
                id="last-name-input"
                label="Nom de Famille"
                type="text"
                fullWidth
                {...register('lastName')}
                sx={spacingItem}
              />

              <TextField id="email" label="Email" type="email" fullWidth {...register('email')} sx={spacingItem} />

              <FormControl fullWidth variant="outlined" sx={spacingItem}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value: string | undefined) => {
                      console.log(value)
                      return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                        ? true
                        : 'Tel is invalid'
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      {...field}
                      label="Numero de Téléphone"
                      defaultCountry="FR"
                      helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </FormControl>

              <TextField
                id="city-of-takeOver-contact-input"
                label="Ville"
                type="text"
                fullWidth
                {...register('city')}
                sx={spacingItem}
                error={!!errors.city}
                helperText={errors.city?.message}
              />

              {!takeOverContactIsAlsoOwner && (
                <>
                  <Typography variant="h6" sx={{ marginTop: 6 }}>
                    🚹 Propriétaire à la prise en charge
                  </Typography>

                  <TextField
                    id="owner-first-name-input"
                    label="Prénom"
                    type="text"
                    fullWidth
                    {...register('ownerFirstName')}
                    sx={spacingItem}
                    error={!!errors.ownerFirstName}
                    helperText={errors.ownerFirstName?.message}
                  />

                  <TextField
                    id="owner-last-name-input"
                    label="Nom de Famille"
                    type="text"
                    fullWidth
                    {...register('ownerLastName')}
                    sx={spacingItem}
                  />

                  <TextField
                    id="ownerEmail"
                    label="Email"
                    type="email"
                    fullWidth
                    {...register('ownerEmail')}
                    sx={spacingItem}
                  />

                  <FormControl fullWidth variant="outlined" sx={spacingItem}>
                    <Controller
                      name="ownerPhoneNumber"
                      control={control}
                      rules={{
                        validate: (value: string | undefined) => {
                          console.log(value)
                          return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                            ? true
                            : 'Tel is invalid'
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <MuiTelInput
                          {...field}
                          label="Numero de Téléphone"
                          defaultCountry="FR"
                          helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                          error={!!fieldState.error}
                        />
                      )}
                    />
                  </FormControl>

                  <TextField
                    id="owner-city-input"
                    label="Ville"
                    type="text"
                    fullWidth
                    {...register('ownerCity')}
                    sx={spacingItem}
                    error={!!errors.ownerCity}
                    helperText={errors.ownerCity?.message}
                  />
                </>
              )}

              <FormControlLabel
                control={
                  <Checkbox {...register('takeOverContactIsAlsoOwner')} checked={watch('takeOverContactIsAlsoOwner')} />
                }
                label="Le contact de prise en charge est le propriétaire de l'animal à la prise en charge"
                sx={spacingItem}
              />

              {serverError && (
                <Box sx={spacingItem}>
                  <Typography color="error">{serverError}</Typography>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
                loading={editAnimalMutation.isLoading}
              >
                Mettre à jour
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
