import { Box, Button, Divider, Link, Modal, Typography } from '@mui/material'
import React from 'react'
import { blue, theme } from '../theme'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxHeight: '80%',
  maxWidth: '80%',
  overflowY: 'auto',
}

const spacingItem = {
  mt: 2,
}

export const JoinModal = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant="text" onClick={handleOpen} sx={{ textTransform: 'none', color: blue, fontSize: 15 }}>
        Je souhaite rejoindre un espace ou m&#39;inscrire gratuitement
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" fontSize={20} fontWeight={500} variant="h4" component="h2">
            Vous êtes une association ou un refuge ?
          </Typography>
          <Typography id="modal-modal-description" variant="h4" fontSize={18} sx={{ mt: 3 }}>
            Si vous n&#39;avez pas encore d&#39;espace pour votre organisme et que vous souhaitez en bénéficier:
          </Typography>
          <Link
            href="https://51lloy792xs.typeform.com/to/e0wmAmpU?typeform-source=www.petso.fr"
            target="_blank"
            color="#fff"
            underline="hover"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              backgroundColor: blue,
              padding: 2,
              borderRadius: 2,
              cursor: 'pointer',
              width: '100%',
            }}
          >
            Je vérifie mon éligibilité
          </Link>
          <Typography variant="h4" fontSize={16} sx={spacingItem}>
            Dans un cadre de luttre contre la fraude, les ressources Petso sont uniquement réservées aux associations et
            refuges sérieux qui nous impose cette vérification. Cela ne prend pas plus de 5 minutes
          </Typography>
          <Divider color={theme.palette.primary.main} sx={{ mt: 3, width: '75%', height: 4 }} />
          <Typography fontSize={20} fontWeight={500} variant="h4" component="h2" sx={{ mt: 3 }}>
            Vous êtes membres d&#39;une association ou d&#39;un refuge qui détient déjà un espace Petso ?
          </Typography>
          <Typography id="modal-modal-description" variant="h4" fontSize={16} sx={{ mt: 3 }}>
            Tournez-vous vers l&#39;administrateur de votre espace. Il s&#39;agit habituellement de votre Président(e),
            si cela n&#39;a pas été délégué en interne.
          </Typography>
          <Typography variant="h4" fontSize={16} fontStyle="italic" sx={spacingItem}>
            L&#39;administrateur sera en mesure de vous configurer un accès, vous recevrez un mail avec vos accès et
            aurez ensuite la possibilité de personnaliser votre mot de passe
          </Typography>
          <Typography fontSize={18} fontWeight={500} variant="h4" component="h2" sx={{ mt: 3 }}>
            Pour toutes questions, n&#39;hésitez pas à prendre contact avec nous !
          </Typography>
          <Link fontSize={18} color={blue} underline="hover" href="mailto:contact@petso.fr">
            contact@petso.fr
          </Link>
        </Box>
      </Modal>
    </>
  )
}
