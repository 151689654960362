import { ArrowDownward } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Collapse, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { useMutation, UseMutationResult } from 'react-query'
import {
  AdopterDto,
  AdoptionAttemptDto,
  AnimalDto,
  AnimalDtoSpecies,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../../utils/axios'
import { getAdoptionAttemptDocumentsSrc } from '../../../utils/S3-links'
import { colors, theme } from '../../theme'
import { AddAdoptionDocumentModal } from './AddAdoptionDocumentModal'
import { RefuseFileModal } from './RefuseFileModal'
import { SkipDocumentProcedureModal } from './SkipDocumentProcedureModal'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { ReviveAdopterPopUp } from './ReviveAdopterPopUp'

interface Props {
  title: string
  description: string
  setAdoptionAttempt?: React.Dispatch<React.SetStateAction<AdoptionAttemptDto | undefined>>
  adopter?: AdopterDto
  animal?: AnimalDto
  adoptionAttemptId?: string
  adoptionAttempt?: AdoptionAttemptDto
  type?: 'certificate' | 'contract' | 'sterilization'
  association?: CustomerDto | null
  originalFileKey?: string
  fileKey?: string
  validation?: boolean
  skipProcedure?: boolean
  expandable?: boolean
  defaultExpanded?: boolean
  specie?: AnimalDtoSpecies
  disabled?: boolean
  isReader?: boolean
  isLocked?: boolean
  updateAttemptMutation?: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
}

export const FileCard: React.FC<Props> = ({
  title,
  description,
  setAdoptionAttempt,
  adopter,
  animal,
  adoptionAttemptId,
  adoptionAttempt,
  type,
  fileKey,
  originalFileKey,
  association,
  validation = false,
  expandable,
  specie,
  skipProcedure = false,
  defaultExpanded = false,
  disabled = false,
  isReader = false,
  isLocked = false,
  updateAttemptMutation,
}) => {
  const globalSnackBarStore = useGlobalSnackbarStore()

  const adopterId = adopter?.id

  const docAlreadyGiven = useMutation(
    async () => {
      if (!type) throw new Error('Type is required')
      if (type === 'sterilization') throw new Error('Sterilization cannot be delivered by hand')
      const formData = new FormData()
      formData.append('deliveredByHand', 'true')
      const response: AdoptionAttemptDto = await AdoptersClient.addOriginalDocumentToAdoptionAttempt(
        adopterId || 'unknown',
        adoptionAttemptId || 'unknown',
        formData,
        type
      )
      return response
    },
    {
      onSuccess: (data) => {
        if (setAdoptionAttempt) {
          setAdoptionAttempt(data)
          if (adopter)
            adopter.adoptionAttempts = adopter.adoptionAttempts?.map((a) => (a.id === adoptionAttemptId ? data : a))
        }
        globalSnackBarStore.triggerSuccessMessage(`Vos modifications ont bien été enregistrées.`)
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  useEffect(() => {
    if (defaultExpanded) setExpanded(true)
  }, [defaultExpanded])

  const [expanded, setExpanded] = useState(defaultExpanded || !expandable)

  // Toggle expand/collapse
  const handleToggleExpand = () => {
    if (expandable) {
      setExpanded(!expanded)
    }
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card
      sx={{
        m: 0,
        height: '100%',
        border: { xs: 'none', sm: 'auto' },
        boxShadow: { xs: 'none', sm: 'auto' },
      }}
    >
      <CardContent
        sx={{
          mt: 0,
          p: { xs: 0, sm: '12px !important' },
          '&:last-child': { pb: { xs: '16px', sm: 'auto' } },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: 0,
        }}
      >
        <Box
          onClick={handleToggleExpand}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: expandable ? 'pointer' : 'default',
          }}
        >
          <Typography variant="h5" fontWeight={700} sx={{ fontSize: { xs: '16px', sm: '19px' } }}>
            {title}
          </Typography>
          {expandable && (
            <IconButton>
              <ArrowDownward
                style={{
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
                }}
              />
            </IconButton>
          )}
        </Box>
        <Collapse in={expanded} timeout="auto">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: { xs: 2, sm: 1 },
              mt: { xs: 0, sm: 2 },
              pb: { xs: 'auto', sm: 3 },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: colors.cello,
                fontSize: { xs: '14px', sm: '16px' },
                height: { xs: 'auto', sm: 100 },
              }}
            >
              {description}
            </Typography>
            {/* "Envoyé" */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              {originalFileKey && originalFileKey !== 'NOT_PROVIDED.pdf' && (
                <a href={getAdoptionAttemptDocumentsSrc(originalFileKey)} target="_blank" rel="noopener noreferrer">
                  {`Consulter le ${type === 'certificate' ? 'certificat' : 'contrat'}`}
                </a>
              )}
              {originalFileKey &&
                originalFileKey !== 'NOT_PROVIDED.pdf' &&
                isLocked &&
                updateAttemptMutation &&
                adoptionAttempt &&
                type && (
                  <LoadingButton
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      fontSize: { xs: '14px', sm: '16px' },
                      width: { xs: '100%', sm: 'auto' },
                    }}
                    loading={updateAttemptMutation.isLoading}
                    onClick={() =>
                      updateAttemptMutation.mutate({
                        ...adoptionAttempt,
                        [type]: { ...adoptionAttempt[type], isLocked: false },
                      })
                    }
                    disabled={disabled}
                  >
                    Envoyer/Débloquer le contrat
                  </LoadingButton>
                )}
              {skipProcedure &&
                type &&
                adopter &&
                adoptionAttemptId &&
                setAdoptionAttempt &&
                animal &&
                association &&
                !isLocked && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <ReviveAdopterPopUp
                      animalId={animal.id}
                      adopterId={adopter.id}
                      animalName={animal.name}
                      customerName={association.name}
                    />
                    <SkipDocumentProcedureModal
                      adopter={adopter}
                      adoptionAttemptId={adoptionAttemptId}
                      setAdoptionAttempt={setAdoptionAttempt}
                      type={type}
                      disabled={disabled}
                    />
                  </Box>
                )}
            </Box>
            {/* "A envoyer" */}
            {!validation &&
              adopter &&
              animal &&
              adoptionAttemptId &&
              type &&
              setAdoptionAttempt &&
              !isReader &&
              !skipProcedure && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {/* Remis en mains propres */}
                    <LoadingButton
                      variant="outlined"
                      fullWidth={isSmallScreen ? false : true}
                      sx={{
                        textTransform: 'none',
                        fontSize: { xs: '14px', sm: '16px' },
                        padding: { xs: 0.5, sm: 'auto' },
                        width: '100%',
                      }}
                      loading={docAlreadyGiven.isLoading || updateAttemptMutation?.isLoading}
                      disabled={disabled || docAlreadyGiven.isLoading || updateAttemptMutation?.isLoading}
                      onClick={() => {
                        docAlreadyGiven.mutate()
                      }}
                    >
                      Déjà transmis
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6}>
                    {type !== 'sterilization' && (
                      <AddAdoptionDocumentModal
                        setAdoptionAttempt={setAdoptionAttempt}
                        type={type}
                        adopter={adopter}
                        adoptionAttemptId={adoptionAttemptId}
                        specie={specie}
                        animal={animal}
                        association={association}
                        isLocked={isLocked}
                        disabled={disabled || docAlreadyGiven.isLoading || updateAttemptMutation?.isLoading}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            {fileKey && fileKey !== 'NOT_PROVIDED.pdf' && (
              <a
                href={getAdoptionAttemptDocumentsSrc(fileKey)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: '100%' }}
              >
                {`Télécharger le ${
                  type === 'certificate'
                    ? 'certificat signé'
                    : type === 'sterilization'
                    ? 'certificat de stérilisation'
                    : 'contrat signé'
                }`}
              </a>
            )}
            {validation && adoptionAttempt && type && updateAttemptMutation && (
              <Grid container spacing={1}>
                {adoptionAttempt && type && setAdoptionAttempt && (
                  <Grid item xs={6} sm={6}>
                    <RefuseFileModal
                      type={type}
                      adopterId={adopterId || 'unknown'}
                      adoptionAttempt={adoptionAttempt}
                      setAdoptionAttempt={setAdoptionAttempt}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                <Grid item xs={6} sm={6}>
                  <Button
                    sx={{
                      textTransform: 'none',
                      fontSize: { xs: '14px', sm: '16px' },
                      padding: { xs: 0.5, sm: 'auto' },
                    }}
                    variant="contained"
                    fullWidth={true}
                    onClick={() =>
                      updateAttemptMutation.mutate({
                        ...adoptionAttempt,
                        [type]: { ...adoptionAttempt[type], validated: true },
                      })
                    }
                    disabled={disabled}
                  >
                    Valider {type ? 'le document' : 'la réception du paiement'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  )
}
