import { Box } from '@mui/material'
import React from 'react'
import { StripeConnectButton } from './Adopters/StripeConnectButton'

export const StripeConnectAlertBanner: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#FFFF0', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '1200px', textAlign: 'center', p: 1 }}>
        <StripeConnectButton
          variant="text"
          color="error"
          content={`⚠️ Pensez à configurer le formulaire de Stripe Connect pour permettre le règlement des frais d'adoption en ligne`}
          sx={{ textTransform: 'none' }}
        />
      </Box>
    </Box>
  )
}
