import { Avatar, Box, Card, CardContent, CardHeader, Chip, Grid, SxProps, Theme, Typography } from '@mui/material'

import { FC, useEffect, useState } from 'react'
import {
  getEmojiAnimalSex,
  getEmojiSpecies,
  getReadableAnimalBreedTitle,
} from '../../../../domain/Animal/AnimalDisplay'
import { getAnimalRegistrationNumberType } from '../../../../domain/Animal/getAnimalRegistrationType'
import { AnimalBreedDtoBreedTitle, AnimalDto } from '../../../../interactors/gen/backendClient'

import { useNavigate } from 'react-router-dom'
import { AnimalsClient } from '../../../../interactors/clients/AnimalsClient'
import { parseMixedBreed } from '../../../../utils/parseMixedBreed'
import { getAnimalProfilePhotoSrc } from '../../../../utils/S3-links'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { PATHS } from '../../../PATHS'
import { colors } from '../../../theme'
import { SmallAnimals } from '../../AnimalBulkEdit'
import { EditAnimalBreedPrompt } from './EditAnimalBreedPrompt'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

export const AnimalBreedCard: FC<Props> = ({ animal, sx }) => {
  const [sameLitterAnimals, setSameLitterAnimals] = useState<SmallAnimals[]>([])
  const [otherRelativesAnimals, setOtherRelativesAnimals] = useState<SmallAnimals[]>([])
  const [mother, setMother] = useState<SmallAnimals | null>(null)
  const [father, setFather] = useState<SmallAnimals | null>(null)
  const defaultItemProps = { xs: 6, sm: 6, item: true, marginBottom: 2 }

  const navigate = useNavigate()

  useEffect(() => {
    const fetchAnimals = async () => {
      const ids = [
        animal.breedData?.filiation?.fatherId || '',
        animal.breedData?.filiation?.motherId || '',
        ...(animal.breedData?.filiation?.sameLitter || []),
        ...(animal.breedData?.filiation?.otherRelatives || []),
      ]

      if (ids.length === 0) return

      const response = await AnimalsClient.getAnimalsByIds(ids)
      setSameLitterAnimals(
        response
          .filter((a) => animal.breedData?.filiation?.sameLitter?.includes(a.id))
          .map((animal) => ({
            label: animal.name,
            value: animal.id,
            species: animal.species,
            adoptionStatus: animal.adoptionStatus,
            profileImageKey: animal.images.profileImageKey,
            sex: animal.sex,
          }))
      )
      setOtherRelativesAnimals(
        response
          .filter((a) => animal.breedData?.filiation?.otherRelatives?.includes(a.id))
          .map((animal) => ({
            label: animal.name,
            value: animal.id,
            species: animal.species,
            adoptionStatus: animal.adoptionStatus,
            profileImageKey: animal.images.profileImageKey,
            sex: animal.sex,
          }))
      )
      const fatherResponse = response.find((a) => a.id === animal.breedData?.filiation?.fatherId)
      const motherResponse = response.find((a) => a.id === animal.breedData?.filiation?.motherId)
      setFather(
        fatherResponse
          ? {
              label: fatherResponse.name,
              value: fatherResponse.id,
              species: fatherResponse.species,
              adoptionStatus: fatherResponse.adoptionStatus,
              profileImageKey: fatherResponse.images.profileImageKey,
              sex: fatherResponse.sex,
            }
          : null
      )
      setMother(
        motherResponse
          ? {
              label: motherResponse.name,
              value: motherResponse.id,
              species: motherResponse.species,
              adoptionStatus: motherResponse.adoptionStatus,
              profileImageKey: motherResponse.images.profileImageKey,
              sex: motherResponse.sex,
            }
          : null
      )
    }

    fetchAnimals()
  }, [animal])

  const registrationType = getAnimalRegistrationNumberType(animal.species)

  return (
    <>
      <Card sx={sx}>
        <CardHeader title="🐾 Portée et généalogie" />
        <CardContent>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={
                      animal.breedData?.breedTitle ? getReadableAnimalBreedTitle(animal.breedData?.breedTitle) : 'Race'
                    }
                    value={
                      animal.breedData?.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
                        ? parseMixedBreed(
                            animal.breedData?.filiation?.fatherBreed,
                            animal.breedData?.filiation?.motherBreed
                          )
                        : animal.breedData?.breed || '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Généalogie"
                    value={`${
                      animal.breedData?.isPedigreeRegistered
                        ? `${registrationType || 'Enregistré'} / ${animal.breedData?.registrationNumber || '..'}`
                        : `Non ${registrationType || ''}`
                    }`}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label="Nb d'animaux dans la portée"
                    value={animal.breedData?.numberOfAnimalsInLitter?.toString() || '..'}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label="N° de la portée" value={animal.breedData?.litterNumber || '..'} />
                </Grid>

                <Grid xs={12} item>
                  <Typography variant="body2" color={colors.blue} fontWeight={700} fontSize={18}>
                    Lien de parentalité
                  </Typography>
                </Grid>

                <Grid {...defaultItemProps}>
                  {animal.breedData?.filiation?.fatherId && father ? (
                    <>
                      <Typography variant="body2" color={'text.secondary'}>
                        Père
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                        <Chip
                          key={father.value}
                          clickable
                          onClick={() => navigate(`${PATHS.animals.absolute}/${father.value}`)}
                          avatar={
                            <Avatar
                              alt={`Photo de ${father.label}`}
                              src={father.profileImageKey && getAnimalProfilePhotoSrc(father.profileImageKey)}
                              sx={{ height: '35px !important', width: '35px !important' }}
                            />
                          }
                          label={`${father.label} ${getEmojiSpecies(father.species)} ${
                            father.sex ? getEmojiAnimalSex(father.sex) : ''
                          } `}
                          variant="outlined"
                          sx={{
                            fontSize: 16,
                            height: 45,
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <ReadOnlyItem label="Père" value={animal.breedData?.filiation?.fatherName || 'Inconnu'} />
                  )}
                </Grid>

                <Grid {...defaultItemProps}>
                  {animal.breedData?.filiation?.motherId && mother ? (
                    <>
                      <Typography variant="body2" color={'text.secondary'}>
                        Mère
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                        <Chip
                          key={mother.value}
                          clickable
                          onClick={() => navigate(`${PATHS.animals.absolute}/${mother.value}`)}
                          avatar={
                            <Avatar
                              alt={`Photo de ${mother.label}`}
                              src={mother.profileImageKey && getAnimalProfilePhotoSrc(mother.profileImageKey)}
                              sx={{ height: '35px !important', width: '35px !important' }}
                            />
                          }
                          label={`${mother.label} ${getEmojiSpecies(mother.species)} ${
                            mother.sex ? getEmojiAnimalSex(mother.sex) : ''
                          } `}
                          variant="outlined"
                          sx={{
                            fontSize: 16,
                            height: 45,
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <ReadOnlyItem label="Mère" value={animal.breedData?.filiation?.motherName || 'Inconnue'} />
                  )}
                </Grid>

                <Grid xs={12} item>
                  <Typography variant="body2" color={'text.secondary'}>
                    Issu(s) de la même portée
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                    {sameLitterAnimals.map((animal) => (
                      <Chip
                        key={animal.value}
                        clickable
                        onClick={() => navigate(`${PATHS.animals.absolute}/${animal.value}`)}
                        avatar={
                          <Avatar
                            alt={`Photo de ${animal.label}`}
                            src={animal.profileImageKey && getAnimalProfilePhotoSrc(animal.profileImageKey)}
                            sx={{ height: '35px !important', width: '35px !important' }}
                          />
                        }
                        label={`${animal.label} ${getEmojiSpecies(animal.species)} ${
                          animal.sex ? getEmojiAnimalSex(animal.sex) : ''
                        } `}
                        variant="outlined"
                        sx={{
                          fontSize: 16,
                          height: 45,
                        }}
                      />
                    ))}
                  </Box>
                </Grid>

                <Grid xs={12} item>
                  <Typography variant="body2" color={'text.secondary'}>
                    Autres frères/soeurs
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                    {otherRelativesAnimals.map((animal) => (
                      <Chip
                        key={animal.value}
                        clickable
                        onClick={() => navigate(`${PATHS.animals.absolute}/${animal.value}`)}
                        avatar={
                          <Avatar
                            alt={`Photo de ${animal.label}`}
                            src={animal.profileImageKey && getAnimalProfilePhotoSrc(animal.profileImageKey)}
                            sx={{ height: '35px !important', width: '35px !important' }}
                          />
                        }
                        label={`${animal.label} ${getEmojiSpecies(animal.species)} ${
                          animal.sex ? getEmojiAnimalSex(animal.sex) : ''
                        } `}
                        variant="outlined"
                        sx={{
                          fontSize: 16,
                          height: 45,
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: '40px', borderLeft: '2px solid black', display: 'flex', borderLeftColor: 'grey.200' }}>
              <EditAnimalBreedPrompt
                animal={animal}
                sameLitterAnimals={sameLitterAnimals}
                setSameLitterAnimals={setSameLitterAnimals}
                otherRelativesAnimals={otherRelativesAnimals}
                setOtherRelativesAnimals={setOtherRelativesAnimals}
                father={father}
                mother={mother}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
