export function decodeBase64Utf8(str: string): string {
  // atob returns a binary string (each character represents a byte)
  const binaryString = atob(str)
  // Convert binary string to percent-encoded string, then decode it as UTF-8
  const percentEncodedStr = binaryString
    .split('')
    .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
    .join('')
  return decodeURIComponent(percentEncodedStr)
}
