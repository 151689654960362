import { AdoptionAttemptDto } from '../interactors/gen/backendClient'

export const getAdoptionAttemptStatus = (adoptionAttempt: AdoptionAttemptDto): string => {
  if (adoptionAttempt.status === 'suspended-by-customer' || adoptionAttempt.status === 'suspended-by-user')
    return 'Adoption suspendue'
  if (adoptionAttempt.archived === true) return 'Adoption terminée'
  if (adoptionAttempt.done === true) return 'Adoption à finaliser'
  if (!adoptionAttempt.certificate || !adoptionAttempt.certificate.original) {
    return 'Envoyer le certificat'
  }
  if (!adoptionAttempt.contract || !adoptionAttempt.contract.original || adoptionAttempt.contract.isLocked) {
    return 'Envoyer le contrat'
  }
  if (adoptionAttempt.paymentSentByUser && !adoptionAttempt.paymentDone) {
    return 'Valider la réception du paiement'
  }
  if (adoptionAttempt.certificate.signed && !adoptionAttempt.certificate.validated) {
    return 'Valider le certificat'
  }
  if (adoptionAttempt.contract.signed && !adoptionAttempt.contract.validated) {
    return 'Valider le contrat'
  }
  if (!adoptionAttempt.certificate.signed || !adoptionAttempt.contract.signed) {
    return "En attente de l'adoptant"
  }
  if (!adoptionAttempt.paymentSentByUser && !adoptionAttempt.paymentDone) {
    return 'Paiement en attente'
  }
  return 'Adoption à finaliser'
}
