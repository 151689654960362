import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  isMembersFull: boolean
  almostFull: boolean
}

export const UserNumberAlertBanner: React.FC<Props> = ({ isMembersFull, almostFull }) => {
  // Only show the banner if the user has reached the limit of members that can be added with their subscription.
  if (!isMembersFull) {
    return null
  }
  return (
    <Box sx={{ backgroundColor: '#FFFF0', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '1200px', textAlign: 'center', p: 1 }}>
        <Link to="/association?tab=functionalities" style={{ color: 'black', textDecoration: 'none' }}>
          {isMembersFull && (
            <Typography>
              Vous avez atteint la limite d&#39;ajout de membre possibles avec votre abonnement,{' '}
              <span style={{ textDecoration: 'underline' }}>consultez nos autres solutions</span>.
            </Typography>
          )}
          {almostFull && (
            <Typography>
              Vous allez bientôt atteindre la limite des licences incluses dans votre offre.{' '}
              <span style={{ textDecoration: 'underline' }}>Consultez nos offres.</span>.
            </Typography>
          )}
        </Link>
      </Box>
    </Box>
  )
}
