import {
  FormControl,
  FormHelperText,
  SxProps,
  Theme,
  InputLabel,
  MenuItem,
  Select,
  BaseTextFieldProps,
  Tooltip,
} from '@mui/material'
import { Lock } from '@mui/icons-material'
import { PropsWithChildren } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'

interface Option {
  label: string
  value: string | number
  locked?: boolean
}

type Props<T extends FieldValues> = {
  sx?: SxProps<Theme>
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Array<Option>
  requiredRule?: string // Use string for error message, or undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
}

export function ControlledSelectField<T extends FieldValues>({
  sx,
  control,
  options,
  error,
  fieldName,
  label,
  requiredRule,
  size,
  disabled,
}: PropsWithChildren<Props<T>>) {
  const labelId = `${fieldName}_label`

  return (
    <FormControl fullWidth variant="outlined" sx={sx} required={Boolean(requiredRule)}>
      <InputLabel htmlFor={labelId} error={!!error} size={size === 'small' ? 'small' : 'normal'}>
        {label}
      </InputLabel>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={'' as any} // Adjust as needed for your form
        rules={{ required: requiredRule }}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={onChange}
            label={label}
            labelId={labelId}
            error={!!error}
            required={Boolean(requiredRule)}
            size={size}
            disabled={disabled}
          >
            {options.map((option) => {
              const menuItem = (
                <MenuItem value={option.value} disabled={option.locked}>
                  {option.locked && <Lock sx={{ mr: 1 }} />}
                  {option.label}
                </MenuItem>
              )

              // For locked options, wrap in a div with full width and a Tooltip.
              if (option.locked) {
                return (
                  <Tooltip
                    key={option.value}
                    title="Souscrivez à l'offre Utile pour bénéficier de cette fonctionnalité."
                    arrow
                  >
                    <div style={{ width: '100%' }}>{menuItem}</div>
                  </Tooltip>
                )
              } else {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              }
            })}
          </Select>
        )}
      />
      <FormHelperText error={!!error}>{error?.message}</FormHelperText>
    </FormControl>
  )
}
