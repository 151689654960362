import { Grid } from '@mui/material'
import React from 'react'
import {
  CustomerDto,
  CustomerPreferencesDto,
  OrganizationTypeDto,
  OrganizationTypeDtoType,
} from '../../../interactors/gen/backendClient'
import { AssociationBureauMembers } from './AssociationBureauMembers'
import { AssociationDescription } from './AssociationDescription'
import { AssociationTheAssociationCard } from './AssociationTheAssociationCard'
import { AxiosError } from 'axios'
import { UseMutationResult } from 'react-query'
import { AssociationPermissions } from './AssociationPermissions'

interface AssociationProps {
  association: CustomerDto
  editAssociationMutation: UseMutationResult<CustomerDto, Error | AxiosError<unknown, any>, CustomerDto, unknown>
}

export const AssociationMyAssociationTab: React.FC<AssociationProps> = ({ association, editAssociationMutation }) => {
  //Global submit function used by all the association form components (not the owner form)
  const onSubmit = async (
    data: Partial<CustomerDto> & Partial<CustomerPreferencesDto> & Partial<OrganizationTypeDto>
  ) => {
    const organizationType = {
      type: data.type || association.organizationType?.type,
      otherField:
        data.type === OrganizationTypeDtoType.OtherOrganizations
          ? data?.otherField || association.organizationType?.otherField
          : undefined,
    }
    const preferences = {
      species: data.species || association.preferences.species,
    }
    console.log('On submit', data)

    const { species, ...restData } = data

    const newData = {
      ...association,
      ...restData,
      organizationType: organizationType,
      preferences: preferences,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }
    console.log('newData', newData)
    editAssociationMutation.mutate(newData)
  }

  if (!association) {
    return null
  }

  return (
    <>
      <AssociationDescription association={association!} onSubmit={onSubmit} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AssociationPermissions association={association} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AssociationTheAssociationCard association={association} onSubmit={onSubmit} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AssociationBureauMembers association={association!} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </>
  )
}
