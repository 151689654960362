import { Box, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomerDto } from '../../interactors/gen/backendClient'
import { AssociationMyAssociationTab } from './MyAssociation/AssociationMyAssociationTab'
import { getBestCustomerTypeLabel } from '../../domain/Customer/CustomerDisplay'
import { AssociationDonationTrackingTab } from './DonationTracking/AssociationDonationTrackingTab'
import { FunctionalitiesTab } from './Functionalities/FunctionalitiesTab'
import { useNavigate } from 'react-router-dom'
import { AssociationDocumentsTab } from './Documents/AssociationDocumentsTab'
import { AssociationCagesTab } from './Cages/AssociationCagesTab'
import { AssociationDashboard } from './Dashboard/AssociationDashboard'
import { TemplatesList } from './Templates/TemplatesList'
import { AssociationSiteTab } from './Site/AssociationSiteTab'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../interactors/clients/CustomerClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../utils/axios'
import { Lock } from '@mui/icons-material'

interface AssociationProps {
  association: CustomerDto
  setAssociation: (association: CustomerDto) => void
}

type TabKey =
  | 'my-association'
  | 'dashboard'
  | 'donation-tracking'
  | 'functionalities'
  | 'documents'
  | 'cages'
  | 'templates'
  | 'site'

export const AssociationTabs: React.FC<AssociationProps> = ({ association, setAssociation }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('my-association')
  const navigate = useNavigate()

  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()

  //Mutation to edit the customer
  const editAssociationMutation = useMutation(
    async (data: CustomerDto) => {
      const response = await CustomersClient.editCustomer(data)
      return response
    },
    {
      onSuccess: (association) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${association.name} a été modifié.`)
        const fetchCustomer = async () => {
          const customer = await CustomersClient.getCurrentCustomer()
          setAssociation(customer)
          accountsStore.changeConnectedCustomer(customer)
        }

        fetchCustomer()
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/association?tab=${newTabKey}`)
  }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabParam = queryParams.get('tab')
    const newTabKey = tabParam as TabKey
    setCurrentTabIndex(newTabKey || 'my-association')
  }, [location.search])
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={getBestCustomerTypeLabel(association?.types, { my: true })} {...a11yProps('my-association')} />
          <Tab label="Documents" {...a11yProps('documents')} />
          <Tab label="Modèles et signature" {...a11yProps('templates')} />
          {accountsStore.isPremium() ? (
            <Tab
              label="Tableau de bord"
              {...a11yProps('dashboard')}
              disabled={!accountsStore.isPremium()}
              icon={!accountsStore.isPremium() ? <Lock /> : undefined}
              iconPosition="start"
            />
          ) : (
            <Tooltip
              title={
                !accountsStore.isPremium() ? "Souscrivez à l'offre Utile pour bénéficier de cette fonctionnalité." : ''
              }
              arrow
            >
              <span>
                <Tab
                  label="Tableau de bord"
                  {...a11yProps('dashboard')}
                  disabled={!accountsStore.isPremium()}
                  icon={!accountsStore.isPremium() ? <Lock /> : undefined}
                  iconPosition="start"
                />
              </span>
            </Tooltip>
          )}
          {accountsStore.isPremium() ? (
            <Tab
              label="Boxes"
              {...a11yProps('cages')}
              disabled={!accountsStore.isPremium()}
              icon={!accountsStore.isPremium() ? <Lock /> : undefined}
              iconPosition="start"
            />
          ) : (
            <Tooltip
              title={
                !accountsStore.isPremium() ? "Souscrivez à l'offre Utile pour bénéficier de cette fonctionnalité." : ''
              }
              arrow
            >
              <span>
                <Tab
                  label="Boxes"
                  {...a11yProps('cages')}
                  disabled={!accountsStore.isPremium()}
                  icon={!accountsStore.isPremium() ? <Lock /> : undefined}
                  iconPosition="start"
                />
              </span>
            </Tooltip>
          )}
          {accountsStore.isPremium() ? (
            <Tab
              label="Suivi des dons"
              {...a11yProps('donation-tracking')}
              disabled={!accountsStore.isPremium()}
              icon={!accountsStore.isPremium() ? <Lock /> : undefined}
              iconPosition="start"
            />
          ) : (
            <Tooltip
              title={
                !accountsStore.isPremium() ? "Souscrivez à l'offre Utile pour bénéficier de cette fonctionnalité." : ''
              }
              arrow
            >
              <span>
                <Tab
                  label="Suivi des dons"
                  {...a11yProps('donation-tracking')}
                  disabled={!accountsStore.isPremium()}
                  icon={!accountsStore.isPremium() ? <Lock /> : undefined}
                  iconPosition="start"
                />
              </span>
            </Tooltip>
          )}
          <Tab label="Mon abonnement" {...a11yProps('functionalities')} />
          <Tab label="Site de l'asso" {...a11yProps('site')} />
        </Tabs>
      </Box>

      <TabPanel value={currentTabIndex} index={'my-association' as TabKey}>
        <AssociationMyAssociationTab association={association!} editAssociationMutation={editAssociationMutation} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'dashboard' as TabKey}>
        {accountsStore.isPremium() && <AssociationDashboard />}
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'donation-tracking' as TabKey}>
        {accountsStore.isPremium() && <AssociationDonationTrackingTab association={association!} />}
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'functionalities' as TabKey}>
        <FunctionalitiesTab />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'documents' as TabKey}>
        <AssociationDocumentsTab association={association!} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'templates' as TabKey}>
        <TemplatesList association={association!} />
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'cages' as TabKey}>
        {accountsStore.isPremium() && <AssociationCagesTab association={association!} />}
      </TabPanel>

      <TabPanel value={currentTabIndex} index={'site' as TabKey}>
        <AssociationSiteTab association={association!} editAssociationMutation={editAssociationMutation} />
      </TabPanel>
    </>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
