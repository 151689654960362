import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { blue, colors } from '../theme'
import { useDropzone } from 'react-dropzone'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useMutation } from 'react-query'
import { backendClient } from '../../interactors/clients/client'
import { setOpacity } from '../../utils/setOpacity'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { ChooseToSendEmailToAdopter } from '../Adopters/ChooseToSendEmailToAdopter'
import { Add, UploadFile } from '@mui/icons-material'
import { InfoBox } from '../common/InfoBox'

interface ServerError {
  reason: string
  // add other possible properties
}

export const AnimalSheetPopUp = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  })

  const [selectedType, setSelectedType] = useState<'active' | 'old' | null>(null)
  const isDropZoneDisabled = !selectedType
  const [progress, setProgress] = useState(0)
  // This flag indicates that the submit button was clicked and we should start the progress bar.
  const [startProgress, setStartProgress] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [sendEmailToAdopter, setSendEmailToAdopter] = useState(true)
  const sx = { marginTop: 2 }

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newType: 'active' | 'old' | null) => {
    event.stopPropagation()
    setSelectedType(newType)
  }

  const resetAllAndClose = async () => {
    closeDialog()
    setStartProgress(false)
    setShowSuccessMessage(false)
    setProgress(0)
    setSelectedType(null)
    navigate(`/animaux?selectedTab=${selectedType === 'active' ? 'to-be-filled' : 'archived'}`)
  }

  const mutation = useMutation(
    async (file: File) => {
      const typeOfFile = selectedType === 'active' ? 'active' : 'old'
      const formData = new FormData()
      formData.append('file', file)
      return await backendClient.post(
        `animals/import?typeOfFile=${typeOfFile}&sendEmailToAdopter=${sendEmailToAdopter.toString()}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 300000, // 5 minutes timeout
        }
      )
    },
    {
      onError: (error: AxiosError) => {
        if (error.code === 'ECONNABORTED' || (error.message && error.message.toLowerCase().includes('timeout'))) {
          globalSnackbar.triggerInfoMessage(
            "L'import prend plus de temps que prévu et se poursuit en arrière-plan. Vos animaux pourront apparaître dans l'application dans quelques minutes. N'hésitez pas à rafraîchir la page."
          )
        } else {
          const errorMessage =
            error.response && error.response.data && (error.response.data as ServerError).reason
              ? (error.response.data as ServerError).reason
              : "L'import prend plus de temps que prévu et se poursuit en arrière-plan. Vos animaux pourront apparaître dans l'application dans quelques minutes. N'hésitez pas à rafraîchir la page."
          globalSnackbar.triggerErrorMessage(errorMessage)
        }
      },
    }
  )

  const onSubmit = async () => {
    if (acceptedFiles[0]) {
      // Start the progress immediately.
      setStartProgress(true)
      mutation.mutate(acceptedFiles[0])
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter un fichier !`)
    }
  }

  useEffect(() => {
    if (startProgress) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          // If progress reaches 99...
          if (oldProgress >= 99) {
            // If mutation is still loading, freeze progress
            if (mutation.isLoading) {
              return 99
            } else {
              // Mutation finished, complete the progress.
              clearInterval(timer)
              setShowSuccessMessage(true)
              return 100
            }
          }
          // Otherwise, increment progress normally.
          const diff = 100 / 15 // Increment such that it'll reach 100 in roughly 15 seconds.
          return Math.min(oldProgress + diff, 99)
        })
      }, 1000) // Update every second.

      return () => clearInterval(timer)
    }
  }, [startProgress, mutation.isLoading])

  return (
    <>
      <Fab key="animalSheet" color="primary" variant="extended" onClick={openDialog}>
        <Add sx={{ mr: 1 }} />
        {'Ajouter tous mes animaux'}
      </Fab>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogContent>
          {!startProgress && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
              <Typography variant="h5" textAlign="center" color={blue} sx={{ fontSize: '25px' }}>
                Importer vos animaux sur votre espace
              </Typography>
              <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
                <Typography variant="h5" sx={sx} color={blue} fontSize={19}>
                  1. Remplissez en un clic votre espace Petso grâce à votre fichier I-CAD:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href="https://www.i-cad.fr/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                      Pour gagner en temps, importer vos fichiers directement accessible depuis votre espace I-CAD
                    </a>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href="https://www.i-cad.fr/" target="_blank" rel="noreferrer">
                      <img src="/images/i-cad.png" alt="i-cad" style={{ objectFit: 'cover', maxWidth: '100%' }} />
                    </a>
                  </Grid>
                </Grid>

                <Typography variant="h5" sx={sx} color={blue} fontSize={19}>
                  2. Attention: choisissez attentivement le type de documents que vous allez importer parmi ces 2
                  possibilités:
                </Typography>

                <ToggleButtonGroup
                  exclusive
                  value={selectedType}
                  onChange={handleToggleChange}
                  aria-label="text alignment"
                  fullWidth
                >
                  <ToggleButton value="active" aria-label="Animaux suivis">
                    Animaux suivis
                  </ToggleButton>
                  <ToggleButton value="old" aria-label="Anciens animaux">
                    Anciens animaux
                  </ToggleButton>
                </ToggleButtonGroup>

                <Typography variant="h5" color={blue} fontSize={19}>
                  3. Ajoutez le document rempli ci-dessous:
                </Typography>
                <InfoBox
                  messageType="info"
                  content="Ne modifiez pas votre fichier : ne seront importé que les animaux qui ne sont pas déjà importé sur la base du numéro d’identification."
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                {...getRootProps({
                  sx: {
                    border: 3,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderColor: blue,
                    backgroundColor: isDragActive
                      ? setOpacity(colors.cello, 0.4)
                      : acceptedFiles[0]
                      ? setOpacity(colors.geyser, 0.4)
                      : 'transparent',
                    transition: 'all 0.3s',
                    pointerEvents: isDropZoneDisabled ? 'none' : 'auto',
                    opacity: isDropZoneDisabled ? 0.5 : 1,
                  },
                })}
              >
                <input {...getInputProps()} />
                <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                  Glissez-déposer ou
                  <br />
                  Insérer un document
                </Typography>
                <Box
                  marginTop={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                    Votre document (.xlsx ou .csv):
                  </Typography>

                  <Typography
                    variant="h4"
                    component="p"
                    fontSize={18}
                    textAlign="center"
                    color={colors.black}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                  </Typography>
                </Box>
                <Button variant="contained" sx={{ width: '40%', mt: 1 }}>
                  Parcourir
                </Button>
              </Box>
              <Typography>
                Nous pourrons automatiquement inscrire vos animaux sur votre espace Petso ! Libre à vous de rajouter des
                détails par la suite
              </Typography>
            </Box>
          )}

          {startProgress && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2, gap: 1 }}>
              <Typography>Merci de patienter pendant que nous traitons vos données...</Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}

          {showSuccessMessage && (
            <>
              <Typography color="green" style={{ textAlign: 'center', marginTop: '10px' }}>
                Traitement terminé avec succès !
              </Typography>
              {selectedType === 'old' && (
                <Typography style={{ textAlign: 'center', marginTop: '10px' }}>
                  Nous avons ajouté tous les animaux du fichier &quot;Mes anciens animaux&quot;. Par défaut, chaque
                  animal a été enregistré comme étant adopté.{' '}
                  <span style={{ fontWeight: 700 }}>Si ce n&apos;est pas le cas de certains,</span> pensez dès
                  maintenant à modifier l&apos;information sur sa fiche dans l&apos;onglet &quot;adoption&quot;
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column', gap: 2 }}>
          {selectedType === 'old' && acceptedFiles[0] && (
            <ChooseToSendEmailToAdopter
              sendEmailToAdopter={sendEmailToAdopter}
              setSendEmailToAdopter={setSendEmailToAdopter}
              text="import"
              vertical
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, width: '100%' }}>
            {showSuccessMessage ? (
              <Button
                data-testid="terminate"
                type="button"
                onClick={() => {
                  resetAllAndClose()
                }}
              >
                Terminer
              </Button>
            ) : (
              <>
                {!startProgress && (
                  <Button data-testid="cancel" type="button" onClick={closeDialog}>
                    Revenir
                  </Button>
                )}
                <LoadingButton
                  data-testid="submit"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!acceptedFiles[0] || isDropZoneDisabled}
                  loading={mutation.isLoading || startProgress}
                >
                  Importer
                </LoadingButton>
              </>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
