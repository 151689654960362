import { Autocomplete, BaseTextFieldProps, Chip, SxProps, TextField } from '@mui/material'
import { PropsWithChildren, useState } from 'react'
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import { blueSky } from '../theme'
import { isColorDark } from '../../utils/isColorDark'
import CancelIcon from '@mui/icons-material/Cancel'
import { AddCircleOutline } from '@mui/icons-material'

interface Option {
  label: string
  value: string | number
  color?: string
}

type AutocompleteOption = Option | string

type Props<T extends FieldValues> = {
  control: Control<T>
  error: FieldError | undefined
  label?: string
  fieldName: Path<T>
  options: Option[]
  requiredRule: string | undefined
  size?: BaseTextFieldProps['size']
  disabled?: boolean
  defaultValue: string
  chip?: boolean
  sx?: SxProps
  highlight?: boolean
  removeCustomValue?: boolean
  freeSolo?: boolean
  helperText?: string
}

export function ControlledAutoCompleteWithCustomValue<T extends FieldValues>({
  control,
  error,
  label,
  fieldName,
  options,
  size,
  disabled,
  requiredRule,
  defaultValue,
  chip = false,
  sx,
  highlight,
  removeCustomValue = false,
  freeSolo = false,
  helperText,
}: PropsWithChildren<Props<T>>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue as any}
      render={({ field: { onChange, value } }) => {
        const [localInputValue, setLocalInputValue] = useState('')

        // We will convert the `options` array into a union of `Option | string`
        const autocompleteOptions = options as AutocompleteOption[]

        // Derive "value" for the Autocomplete (the selected item)
        // 1) If `value` is empty/null, fallback to `''`.
        // 2) If `value` matches an `Option`'s .value, return that option object.
        // 3) Otherwise, treat the value as a freeSolo string.
        const selectedOptionOrString = (() => {
          if (value == null || value === '') return ''
          const found = options.find((opt) => opt.value === value)
          return found || value // might be a string
        })()

        return (
          <Autocomplete
            sx={{ ...sx, width: '100%' }}
            clearIcon={null}
            freeSolo={freeSolo}
            disabled={disabled}
            id={fieldName}
            options={autocompleteOptions}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
            // The 'value' is which item is selected in the dropdown
            value={selectedOptionOrString}
            size={size}
            // Force the input text to be empty if there's a selected value + chip mode
            inputValue={chip && value ? '' : localInputValue}
            onInputChange={(_, newVal) => {
              // This updates the typed text as the user types
              setLocalInputValue(newVal)
            }}
            onChange={(_, newVal) => {
              if (!newVal) {
                onChange(null)
                setLocalInputValue('')
                return
              }

              if (typeof newVal === 'string') {
                // user typed something not in the list
                onChange(newVal)
              } else {
                // user picked an existing Option
                onChange(newVal.value)
              }
              // Once user picks an item, clear the local input
              setLocalInputValue('')
            }}
            renderOption={(props, option, { index }) => {
              if (typeof option === 'string') {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }
              // Option is an object
              const highlightBg =
                highlight &&
                index === 0 &&
                typeof option.value === 'string' &&
                option.value.startsWith('add') &&
                !removeCustomValue
                  ? 'lightyellow'
                  : 'inherit'

              return (
                <li {...props} key={option.value} style={{ backgroundColor: highlightBg }}>
                  {highlight &&
                  index === 0 &&
                  typeof option.value === 'string' &&
                  option.value.startsWith('add') &&
                  !removeCustomValue ? (
                    <AddCircleOutline />
                  ) : null}
                  &nbsp;{option.label}
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder="Écrivez pour rechercher..."
                required={!!requiredRule && (value === null || value === undefined || value === '')}
                error={!!error}
                helperText={error?.message || helperText}
                InputProps={{
                  ...params.InputProps,
                  startAdornment:
                    chip && value ? (
                      <Chip
                        key={value}
                        label={(() => {
                          const found = options.find((opt) => opt.value === value)
                          if (found) return found.label
                          // freeSolo typed string
                          return typeof value === 'string' ? value : ''
                        })()}
                        onDelete={() => onChange(null)}
                        deleteIcon={<CancelIcon />}
                        sx={{
                          backgroundColor: options.find((opt) => opt.value === value)?.color || blueSky,
                          color: isColorDark(options.find((opt) => opt.value === value)?.color || blueSky)
                            ? 'white'
                            : 'black',
                          marginY: '-4.5px !important',
                          marginTop: '-1.5px',
                          height: '28px',
                          '& .MuiChip-label': {
                            lineHeight: '24px',
                          },
                        }}
                      />
                    ) : undefined,
                }}
              />
            )}
          />
        )
      }}
    />
  )
}
