import React from 'react'
import { useMutation } from 'react-query'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { LoadingButton } from '@mui/lab'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { Tooltip } from '@mui/material'
import { AccountDtoPermission } from '../../interactors/gen/backendClient'

export const MemberExportSheet: React.FC = () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const isReader =
    accountsStore.connectedAccount?.permission === AccountDtoPermission.Read ||
    accountsStore.connectedAccount?.permission === AccountDtoPermission.Editor

  const customer = accountsStore.connectedCustomer

  const exportExcelMutation = useMutation(AccountsClient.downloadMembersExcel, {
    onSuccess: async () => {
      globalSnackbarStore.triggerSuccessMessage(`Excel téléchargé !`)
    },
    onError: async (error) => {
      console.error(error)
      globalSnackbarStore.triggerErrorMessage(`Une erreur est survenue !`)
    },
  })
  return (
    <Tooltip
      title={
        !accountsStore.isPremium()
          ? "Cette fonctionnalité est réservée aux possesseurs de l'offre utile"
          : isReader
          ? `Votre permission ne vous permet pas d'effectuer un export. Rapprochez vous d'un admin`
          : ''
      }
    >
      <span>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => exportExcelMutation.mutate(customer?.name || 'Association')}
          loading={exportExcelMutation.isLoading}
          disabled={!accountsStore.isPremium() || isReader}
          sx={{ marginTop: 4, paddingX: 3 }}
        >
          Exporter
        </LoadingButton>
      </span>
    </Tooltip>
  )
}
