export const parseMixedBreed = (fatherBreed?: string, motherBreed?: string): string => {
  let hasFatherBreed = false
  let hasMotherBreed = false
  if (fatherBreed && fatherBreed !== '') {
    hasFatherBreed = true
  }
  if (motherBreed && motherBreed !== '') {
    hasMotherBreed = true
  }
  if (hasFatherBreed && hasMotherBreed) {
    return `${fatherBreed} x ${motherBreed}`
  } else if (hasFatherBreed) {
    return `Croisé ${fatherBreed}`
  } else if (hasMotherBreed) {
    return `Croisé ${motherBreed}`
  }

  return 'Unknown'
}
