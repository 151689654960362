import { AnimalSearchItemDto } from '../../interactors/gen/backendClient'

export const isAnimalOfferPublished = (animal: AnimalSearchItemDto): boolean => {
  let specieCondition = true
  if (['cat', 'dog', 'ferret'].includes(animal.species)) {
    if (
      !(
        (animal.identificationNumber != null && animal.identificationNumber.toString() !== '') ||
        (animal.tattoo?.number != null && animal.tattoo?.number !== '')
      )
    ) {
      specieCondition = false
    }
  }
  return (
    animal.adoptionStatus === 'adoptable' &&
    animal.images?.profileImageKey != null &&
    animal.images?.profileImageKey !== '' &&
    animal.birthdate != null &&
    animal.birthdate !== '' &&
    specieCondition
  )
}
