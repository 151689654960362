import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppBar, Box, Button, Container, Icon, IconButton, Toolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { backendClient } from '../../interactors/clients/client'
import { useAccountsStore } from '../../store/AccountsStore'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { FunctionalitiesTab } from '../Association/Functionalities/FunctionalitiesTab'
import logo from '../logo.svg' // adjust the path to your logo
import { AuthService } from '../../interactors/services/AuthService'
import { PATHS } from '../PATHS'
import { CustomersClient } from '../../interactors/clients/CustomerClient'
import { CustomerSubscriptionDtoPlan } from '../../interactors/gen/backendClient'
import { metricalpEvent } from '@metricalp/react'

/**
 * A very basic navbar that only shows the Petso logo and a disconnect button.
 */
const BasicNavbar: React.FC = () => {
  const navigate = useNavigate()
  const [plan, setPlan] = React.useState<CustomerSubscriptionDtoPlan>('free')

  useEffect(() => {
    CustomersClient.getCustomerSubscription().then((plan) => {
      setPlan(plan)
    })
  }, [])

  const handleDisconnect = () => {
    AuthService.logout()
    navigate(PATHS.login.relative)
    setTimeout(() => {
      navigate(PATHS.login.relative)
    }, 2000)
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon sx={{ width: '60px', height: '60px' }}>
              <img src={logo} alt="Logo de Petso" />
            </Icon>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', py: 2 }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  // fontFamily: 'monospace',
                  fontWeight: 400,
                  letterSpacing: '.3rem',
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: '1rem',
                  fontFamily: 'Insaniburger',
                }}
              >
                PETSO
              </Typography>
              {plan !== 'free' && (
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 22,
                    fontSize: 12,
                    color: 'white',
                    fontFamily: 'Insaniburger',
                  }}
                >
                  {plan === 'premium' ? 'Premium' : plan === 'essential' ? 'Essentiel +' : ''}
                </Typography>
              )}
            </Box>
          </Box>
          <Button
            variant="text"
            onClick={handleDisconnect}
            sx={{ textTransform: 'none', color: 'white', fontSize: 16, fontWeight: 'bold' }}
          >
            Se déconnecter
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

interface ExcessUsersPageProps {
  maxMembersNumber: number
  membersCount: number
  appLocked: boolean
}

export const ExcessUsersPage: React.FC<ExcessUsersPageProps> = ({ maxMembersNumber, membersCount, appLocked }) => {
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const [showFunctionalitiesTab, setShowFunctionalitiesTab] = React.useState(false)

  useFetchOrRefreshMembersOnMount()

  const handleArchive = async () => {
    if (!accountsStore.isAdmin) {
      return
    }
    metricalpEvent({
      type: 'archive_members',
      customerId: accountsStore.connectedAccount?.customerId,
      accountId: accountsStore.connectedAccount?.id,
      accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
    })
    await backendClient.post('/customers/archive-members')
    await accountsStore.fetchOrRefreshMembers()
    // For simplicity, we reload the page after archiving.
    window.location.reload()
  }

  const handleChangeOffer = () => {
    metricalpEvent({
      type: 'goto_offers_from_locked_page',
      customerId: accountsStore.connectedAccount?.customerId,
      accountId: accountsStore.connectedAccount?.id,
      accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
    })
    setShowFunctionalitiesTab(true)
  }

  const handleReturn = () => {
    setShowFunctionalitiesTab(false)
  }

  // If the app is not locked, navigate away from this page.
  if (!appLocked) {
    navigate('/')
    return null
  }

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f5f5f5', overflowY: 'auto' }}>
      {/* Basic Navbar */}
      <BasicNavbar />

      {/* Main content */}
      {showFunctionalitiesTab ? (
        <Box
          sx={{
            position: 'relative',
            minHeight: 'calc(100vh - 64px)', // subtract navbar height (approx. 64px)
            backgroundColor: '#f5f5f5',
            p: 3,
          }}
        >
          {/* Return arrow */}
          <IconButton
            onClick={handleReturn}
            sx={{
              position: 'absolute',
              top: 16,
              left: 16,
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          {/* FunctionalitiesTab content */}
          <Box sx={{ mt: 6 }}>
            <FunctionalitiesTab />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            minHeight: 'calc(100vh - 64px)', // subtract navbar height
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            px: 3,
            py: 6,
          }}
        >
          <Box
            sx={{
              maxWidth: 600,
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: 2,
              p: 4,
              boxShadow: 3,
            }}
          >
            <Typography variant="h4" fontWeight="bold" sx={{ mb: 3 }}>
              Offre non adaptée
            </Typography>

            <Typography variant="body1" sx={{ mb: 3, fontSize: 16 }}>
              <b>
                Vous avez actuellement {membersCount} utilisateur
                {membersCount > 1 ? 's' : ''} pour {maxMembersNumber} contenu
                {maxMembersNumber > 1 ? 's' : ''} dans votre offre.
              </b>
              <br />
              Pour continuer à utiliser Petso, veuillez régulariser votre situation. Sans action de votre part, l’accès
              aux licences excédentaires les plus récentes sera désactivé.
            </Typography>

            <Typography variant="h5" fontWeight="bold" sx={{ mb: 4 }}>
              Veuillez régulariser votre situation pour continuer à utiliser Petso.
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleArchive}
                disabled={!accountsStore.isAdmin}
                sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: 16 }}
              >
                Archiver les comptes excédentaires
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleChangeOffer}
                sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: 16 }}
              >
                Changer d’offre
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
