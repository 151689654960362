import { Edit } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, SxProps } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getColorForAnimalAdoptionStatus,
  getColorForAnimalAdoptionStep,
  getReadableAnimalAdoptionOfferType,
  getReadableAnimalAdoptionStatus,
  getReadableAnimalAdoptionStep,
} from '../../../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { ReadOnlyChip } from '../../../common/ReadOnlyChip'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { useAdoptersStore } from '../../../../store/AdoptersStore'
import { fullName } from '../../../../domain/Adopters/AccountDisplay'
import { useFetchOrRefreshAdoptersOnMount } from '../../../../store/useFetchOrRefreshAdoptersOnMount'

interface Props {
  animal: AnimalDto
  sx?: SxProps
}

export const AnimalResumeAdoptionCard: React.FC<Props> = ({ animal, sx }) => {
  const navigate = useNavigate()
  const canEditAnimal = useCanEditAnimal()

  useFetchOrRefreshAdoptersOnMount()

  const adoptersStore = useAdoptersStore()

  const adopter = adoptersStore.adopters.find((adopter) => adopter.id === animal.adoption.adopterId)
  const adoptionAttempt = adopter?.adoptionAttempts?.find((attempt) => attempt.animalId === animal.id)

  const defaultItemProps = { xs: 6, sm: 3, item: true, marginBottom: 2 }
  const smallItemProps = defaultItemProps
  return (
    <Card sx={sx}>
      <CardHeader
        title={`Suivi d'adoption`}
        action={
          canEditAnimal && (
            <IconButton
              onClick={() => {
                navigate('./?tab=adoption')
              }}
              color="primary"
            >
              <Edit />
            </IconButton>
          )
        }
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid {...smallItemProps}>
                <ReadOnlyChip
                  label={`Statut`}
                  value={getReadableAnimalAdoptionStatus(animal.adoption.status)}
                  chipColor={getColorForAnimalAdoptionStatus(animal.adoption.status)}
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem
                  label={`Montant`}
                  value={animal.adoption?.priceInEuros ? `${animal.adoption.priceInEuros} €` : '..'}
                />
              </Grid>

              <Grid {...smallItemProps}>
                <ReadOnlyItem
                  label={`Type`}
                  value={
                    animal.adoption.offerType ? getReadableAnimalAdoptionOfferType(animal.adoption.offerType) : '..'
                  }
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyChip
                  label={`Etat d'avancement`}
                  value={animal.adoption.step ? getReadableAnimalAdoptionStep(animal.adoption.step) : '..'}
                  chipColor={animal.adoption.step ? getColorForAnimalAdoptionStep(animal.adoption.step) : undefined}
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem
                  label="Sortie physique"
                  value={
                    animal.adoption?.adoptionDate ? dayjs(animal.adoption.adoptionDate).format('DD/MM/YYYY') : '..'
                  }
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem
                  label={`Visite post-adoption`}
                  value={animal.adoption.vpaDate ? dayjs(animal.adoption.vpaDate).format('DD/MM/YYYY') : '..'}
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem
                  label={`Cession I-CAD`}
                  value={animal.adoption.exitDate ? dayjs(animal.adoption.exitDate).format('DD/MM/YYYY') : '..'}
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem label={`Nouveau nom`} value={animal.adoption.newName || '..'} />
              </Grid>

              <Grid item xs={12} sm={4}>
                <ReadOnlyItem label="Adoptant" value={adopter ? fullName(adopter) : '..'} />
              </Grid>

              <Grid item xs={12} sm={8}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    gap: 2,
                    width: '100%',
                    height: '100%',
                    pl: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() =>
                      navigate(`/suivi-adoptant/${adopter?.id}/?tab=ongoing-adoption&attemptId=${adoptionAttempt?.id}`)
                    }
                  >
                    Voir la procédure
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
