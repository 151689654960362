import { Box, Card, CardContent, Chip, Divider, Grid, IconButton, Typography } from '@mui/material'

import { FC } from 'react'
import {
  getColorForAnimalAdoptionStatus,
  getColorForAnimalVaccineStatus,
  getReadableAnimalAdoptionStatus,
  getReadableAnimalBreedTitle,
  getReadableAnimalFieldLabel,
  getReadableAnimalHealthPathology,
  getReadableAnimalVaccineStatus,
  getReadableIdentificationNumber,
  getReadableTattooLocation,
} from '../../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../../interactors/gen/backendClient'

import { Visibility } from '@mui/icons-material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useCanEditAnimal } from '../../../store/useCanEditAnimal'
import { ReadOnlyChip } from '../../common/ReadOnlyChip'
import { ReadOnlyItem } from '../../common/ReadOnlyItem'
import { spacingItem } from '../../theme'
import { EditGeneralInfoAnimalPrompt } from './EditGeneralInfoAnimalPrompt'

interface Props {
  animal: AnimalDto
}

export const AnimalGeneralCard: FC<Props> = ({ animal }) => {
  const canEditAnimal = useCanEditAnimal()
  const navigate = useNavigate()

  const defaultItemProps = { xs: 6, sm: 3, item: true, marginBottom: 2 }
  const smallItemProps = defaultItemProps

  const needsToConfirmDeworming =
    animal.health?.operations.find(
      (operation) =>
        operation.type.includes('deworming') &&
        dayjs(operation.date, 'DD/MM/YYYY').isBefore(dayjs()) &&
        !operation.validated
    )?.needsValidation === true
      ? 'RDV à valider'
      : undefined

  const nextDewormingDate =
    animal.health?.operations.find(
      (operation) => operation.type.includes('deworming') && dayjs(operation.date, 'DD/MM/YYYY').isAfter(dayjs())
    )?.date ?? needsToConfirmDeworming

  const needsToConfirmFleaControl =
    animal.health?.operations.find(
      (operation) =>
        operation.type.includes('flea-control') &&
        dayjs(operation.date, 'DD/MM/YYYY').isBefore(dayjs()) &&
        !operation.validated
    )?.needsValidation === true
      ? 'RDV à valider'
      : undefined

  const nextFleaControlDate =
    animal.health?.operations.find(
      (operation) => operation.type.includes('flea-control') && dayjs(operation.date, 'DD/MM/YYYY').isAfter(dayjs())
    )?.date ?? needsToConfirmFleaControl

  return (
    <>
      <Card sx={spacingItem}>
        <CardContent sx={{ pb: '16px !important' }}>
          <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...smallItemProps}>
                  <ReadOnlyItem
                    label="Date de naissance"
                    value={
                      animal.birthday?.date
                        ? `${dayjs(animal.birthday?.date).format('DD/MM/YYYY')} ${
                            animal.birthday?.isApproximate ? '(approx...)' : ''
                          }`
                        : '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={
                      animal.breedData?.breedTitle
                        ? getReadableAnimalBreedTitle(animal.breedData?.breedTitle)
                        : 'Type de race'
                    }
                    value={animal.breedData?.breed || animal.breed || '..'}
                  />
                </Grid>

                <Grid {...smallItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('identificationNumber')}
                    value={
                      animal.identificationNumber
                        ? getReadableIdentificationNumber(animal.identificationNumber?.toString())
                        : '..'
                    }
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem
                    label={getReadableAnimalFieldLabel('tattoo')}
                    value={`${animal?.tattoo?.number ? animal?.tattoo?.number : '..'} - ${
                      animal?.tattoo?.location ? getReadableTattooLocation(animal?.tattoo?.location) : '..'
                    } `}
                  />
                </Grid>
              </Grid>
            </Box>

            {canEditAnimal && (
              <Box
                sx={{
                  width: '40px',
                  borderLeft: '2px solid black',
                  display: 'flex',
                  borderLeftColor: 'grey.200',
                }}
              >
                <EditGeneralInfoAnimalPrompt animal={animal} />
              </Box>
            )}
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
            <Box sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid {...smallItemProps}>
                  <ReadOnlyChip
                    label={`Statut`}
                    value={getReadableAnimalAdoptionStatus(animal.adoption.status)}
                    chipColor={getColorForAnimalAdoptionStatus(animal.adoption.status)}
                  />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyChip
                    label={`Statut vaccinal`}
                    value={
                      animal.vaccination.vaccinationStatus
                        ? getReadableAnimalVaccineStatus(animal.vaccination.vaccinationStatus)
                        : '..'
                    }
                    chipColor={
                      animal.vaccination.vaccinationStatus
                        ? getColorForAnimalVaccineStatus(animal.vaccination.vaccinationStatus)
                        : undefined
                    }
                  />
                </Grid>

                <Grid {...smallItemProps}>
                  <ReadOnlyItem label={`Prochain vermifuge`} value={nextDewormingDate ?? 'Non planifié'} />
                </Grid>

                <Grid {...defaultItemProps}>
                  <ReadOnlyItem label={`Prochain antiparasitaire`} value={nextFleaControlDate ?? 'Non planifié'} />
                </Grid>

                {animal.health.pathologies && animal.health.pathologies.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant={'body2'}
                      color="textSecondary"
                      sx={{
                        lineHeight: '0.875rem',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textDecoration: 'none',
                        my: 1,
                      }}
                    >
                      Pathologies
                    </Typography>

                    {animal.health.pathologies.map((pathology) => (
                      <Chip
                        key={pathology}
                        label={getReadableAnimalHealthPathology(pathology)}
                        color="secondary"
                        sx={{ margin: '2px' }}
                      />
                    ))}
                  </Grid>
                )}
              </Grid>
            </Box>

            {canEditAnimal && (
              <Box
                sx={{
                  width: '40px',
                  borderLeft: '2px solid black',
                  display: 'flex',
                  borderLeftColor: 'grey.200',
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    navigate('./?tab=sante')
                    const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
                    chatContainer.scrollTop = 600
                  }}
                >
                  <Visibility />
                </IconButton>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
