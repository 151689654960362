import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimalDto, AnimalShareAnnounceDto } from '../../../interactors/gen/backendClient'
import { useEditAnimalMutation } from '../../../store/useEditAnimalMutation'
import { theme } from '../../theme'
import { Save } from '@mui/icons-material'

interface ShareAnimalFormProps {
  animal: AnimalDto
}

export const ShareAnimalForm: React.FC<ShareAnimalFormProps> = ({ animal }) => {
  // Use the animal.shareAnnounce values as default values if available,
  // otherwise default both switches to true.
  const { control, handleSubmit } = useForm<AnimalShareAnnounceDto>({
    defaultValues: {
      petso: animal.shareAnnounce?.petso ?? true,
      wamiz: animal.shareAnnounce?.wamiz ?? true,
    },
  })

  const editAnimalMutation = useEditAnimalMutation({})

  const onSubmit = (data: AnimalShareAnnounceDto) => {
    // Call the mutation to update the animal with the new sharing settings.
    editAnimalMutation.mutate({ ...animal, shareAnnounce: data })
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        p: 3,
        maxWidth: 500,
        backgroundColor: '#fafafa',
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {`Paramétrer la diffusion de l'annonce d'adoption`}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Votre annonce sera diffusée sur les sites sélectionnés uniquement si son statut est « adoptable » et que son
        profil est suffisamment complété.
      </Typography>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <FormControl component="fieldset">
          <FormGroup sx={{ gap: 1 }}>
            <Controller
              name="petso"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch checked={value} onChange={(_, checked) => onChange(checked)} color="primary" />}
                  label={
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}
                      component="a"
                      href="https://petso.fr"
                      target="_blank"
                    >
                      <Typography variant="body1">Partager sur</Typography>
                      <img
                        src={'https://www.petso.fr/_next/static/media/logo.19b70203.svg'}
                        alt="Petso Logo"
                        style={{ height: 32, marginLeft: 4 }}
                      />
                      <Typography
                        variant="body1"
                        fontFamily="insaniburger"
                        fontSize={20}
                        color={theme.palette.primary.main}
                      >
                        Petso
                      </Typography>
                    </Box>
                  }
                />
              )}
            />

            <Controller
              name="wamiz"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch checked={value} onChange={(_, checked) => onChange(checked)} color="primary" />}
                  label={
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}
                      component="a"
                      href="https://wamiz.com/chats/adoption/recherche"
                      target="_blank"
                    >
                      <Typography variant="body1">Partager sur</Typography>
                      <img
                        src="https://wamiz.com/build/images/newsletter/wamiz-logo.png"
                        alt="Wamiz Logo"
                        style={{ height: 24, marginLeft: 8 }}
                      />
                    </Box>
                  }
                />
              )}
            />
          </FormGroup>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <LoadingButton
            type="submit"
            variant="outlined"
            color="primary"
            disabled={editAnimalMutation.isLoading}
            loading={editAnimalMutation.isLoading}
            startIcon={<Save />}
            sx={{ textTransform: 'none' }}
          >
            Mettre à jour
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}
