import { Grid, InputAdornment, TextField, Tooltip } from '@mui/material'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import React, { useEffect } from 'react'
import { UseMutationResult } from 'react-query'
import { getReadablePaymentMethod } from '../../../domain/Adopters/AccountDisplay'
import { getReadableAnimalAdoptionOfferType } from '../../../domain/Animal/AnimalDisplay'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoPaymentMethod,
  AnimalAdoptionDtoOfferType,
  AnimalDto,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { AnimalCardSaveButton } from '../../Animals/Details/AnimalCardSaveButton'
import { CollapsableCard } from '../../common/CollapsableCard'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { colors } from '../../theme'
import { StripeConnectButton } from '../StripeConnectButton'
import { ControlledDateField } from '../../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'

interface FormParams {
  offerType?: AnimalAdoptionDtoOfferType
  priceInEuros?: number
  paymentMethod?: AdoptionAttemptDtoPaymentMethod
  paymentInstallments?: number
  paymentDone: string
  checkNumber?: string
  bankName?: string
  depositDate?: string
  depositAmount?: number
  depositStatus?: string
}

interface Props {
  animal: AnimalDto
  adoptionAttempt: AdoptionAttemptDto
  adopterId: string
  isReader: boolean
  updateAttemptMutation: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
  setAdoptionAttempt: (adoptionAttempt: AdoptionAttemptDto) => void
}

export const ContributionCard: React.FC<Props> = ({ animal, adoptionAttempt, updateAttemptMutation }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  /* const isUpMdWidth = useMediaQuery(theme.breakpoints.up('md'))
  const isDownMdWidth = useMediaQuery(theme.breakpoints.down('md')) */

  const getDefaultValues = (attempt: AdoptionAttemptDto): FormParams => ({
    offerType: animal.adoption.offerType || undefined,
    priceInEuros: animal.adoption.priceInEuros || undefined,
    paymentMethod: attempt?.paymentMethod || undefined,
    paymentInstallments: attempt?.paymentInstallments || undefined,
    paymentDone: attempt?.paymentDone ? 'true' : 'false',
    checkNumber: attempt?.checkNumber || undefined,
    bankName: attempt?.bankName || undefined,
    depositDate: attempt?.depositDate || undefined,
    depositAmount: attempt?.depositAmount || undefined,
    depositStatus: attempt?.depositStatus ? 'true' : 'false',
  })

  const accountsStore = useAccountsStore()
  const isAdmin = accountsStore.isAdmin()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useFormExtended(adoptionAttempt, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    setIsLoading(true)
    if (data.offerType !== animal.adoption.offerType || data.priceInEuros !== animal.adoption.priceInEuros) {
      animal.adoption.offerType = data.offerType
      animal.adoption.priceInEuros = data.priceInEuros
      await AnimalsClient.editAnimal(animal)
    }
    if (
      data.paymentMethod !== adoptionAttempt.paymentMethod ||
      data.paymentDone !== (adoptionAttempt.paymentDone === true ? 'true' : 'false')
    ) {
      if (data.paymentDone === 'true' && adoptionAttempt.paymentDone === false) {
        adoptionAttempt.events.push({ id: nanoid(), type: 'payment-reception', date: new Date().toISOString() })
      }
      if (data.paymentDone === 'false' && adoptionAttempt.paymentDone === true) {
        adoptionAttempt.events.push({ id: nanoid(), type: 'payment-cancelled', date: new Date().toISOString() })
      }
    }
    adoptionAttempt.paymentDone = data.paymentDone === 'true'
    adoptionAttempt.depositDate = data.depositDate
    adoptionAttempt.depositAmount = data.depositAmount
    adoptionAttempt.depositStatus = data.depositStatus === 'true'
    adoptionAttempt.paymentMethod = data.paymentMethod
    adoptionAttempt.paymentInstallments = data.paymentInstallments
    if (data.paymentMethod === AdoptionAttemptDtoPaymentMethod.Check) {
      adoptionAttempt.checkNumber = data.checkNumber
      adoptionAttempt.bankName = data.bankName
    } else {
      adoptionAttempt.checkNumber = undefined
      adoptionAttempt.bankName = undefined
    }

    await updateAttemptMutation.mutateAsync(adoptionAttempt)
    setIsLoading(false)
  }

  /* const handleCopyLink = () => {
    if (adoptionAttempt.paymentLink) {
      navigator.clipboard.writeText(adoptionAttempt.paymentLink)
      alert('Lien de paiement copié dans le presse-papiers.')
    }
  }

  const handleGenerateLink = async () => {
    if (animal.adoption.priceInEuros) {
      const response = await backendClient.post('/stripe/create-adoption-payment-link', {
        adopterId: adopterId,
        price: (Number(animal.adoption.priceInEuros) + 12) * 100,
        attemptId: adoptionAttempt?.id as string,
      })
      setAdoptionAttempt({ ...adoptionAttempt, paymentLink: response.data })
    }
  } */

  const isDonation = watch('offerType') === AnimalAdoptionDtoOfferType.Donation
  useEffect(() => {
    if (isDonation) {
      setValue('priceInEuros', 0, { shouldValidate: true })
    }
  }, [isDonation, setValue])

  const paymentDone = watch('paymentDone')
  const paymentMethod = watch('paymentMethod')
  const depositAmount = watch('depositAmount')
  const depositStatus = watch('depositStatus')
  const adoptionPrice = watch('priceInEuros')

  const leftToPay =
    paymentDone === 'true'
      ? 0
      : depositAmount && depositStatus === 'true' && adoptionPrice
      ? adoptionPrice - depositAmount
      : adoptionPrice

  return (
    <CollapsableCard title="Contribution">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: 16,
          width: '100%',
        }}
      >
        <Grid container spacing={2}>
          {/* Payment Section */}
          <Grid item xs={12}>
            {
              !accountsStore.connectedCustomer?.stripeAccountId && (
                <Tooltip title={!isAdmin ? 'Seuls les administrateurs peuvent configurer Stripe' : ''}>
                  <span>
                    <StripeConnectButton
                      sx={{ width: '100%', fontWeight: 'bold', textTransform: 'none', borderRadius: 4 }}
                    />
                  </span>
                </Tooltip>
              ) /* : !adoptionAttempt.paymentDone ? (
              adoptionAttempt.paymentLink ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCopyLink}
                  sx={{
                    width: '100%',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    borderRadius: 4,
                    marginBottom: 1,
                  }}
                >
                  Copier le lien de paiement
                </Button>
              ) : (
                <Tooltip
                  title={
                    !isAdmin
                      ? 'Seuls les administrateurs peuvent générer un lien de paiement'
                      : animal.adoption.priceInEuros
                      ? ''
                      : 'Veuillez définir un montant avant de générer le lien de paiement'
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleGenerateLink}
                      sx={{
                        width: '100%',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        borderRadius: 4,
                        marginBottom: 1,
                      }}
                      disabled={!isAdmin || !animal.adoption.priceInEuros}
                    >
                      Générer un lien de paiement
                    </Button>
                  </span>
                </Tooltip>
              )
            ) : null */
            }
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ControlledSelectField
              control={control}
              error={errors.offerType}
              fieldName="offerType"
              label="Nature de l'offre"
              options={Object.values(AnimalAdoptionDtoOfferType).map((field: AnimalAdoptionDtoOfferType) => ({
                label: getReadableAnimalAdoptionOfferType(field),
                value: field,
              }))}
              requiredRule=""
              size="small"
            />
            <ControlledDateField
              control={control}
              error={errors.depositDate}
              fieldName="depositDate"
              label="Date de l'acompte"
              requiredRule=""
              size="small"
              sx={{ marginTop: 2 }}
              validate={(value) => isValidPastDateCoherence(value) || value === null}
            />
            {paymentDone === 'true' && (
              <ControlledSelectField
                control={control}
                error={errors.paymentMethod}
                fieldName="paymentMethod"
                label="Mode de réglement"
                options={Object.values(AdoptionAttemptDtoPaymentMethod).map(
                  (field: AdoptionAttemptDtoPaymentMethod) => ({
                    label: getReadablePaymentMethod(field),
                    value: field,
                  })
                )}
                sx={{ marginTop: 2 }}
                requiredRule=""
                size="small"
              />
            )}
            {paymentMethod === AdoptionAttemptDtoPaymentMethod.Check && paymentDone === 'true' && (
              <TextField
                id="check-number-input"
                label="Numéro de chèque"
                fullWidth
                {...register('checkNumber')}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ marginTop: 2 }}
              />
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <TextField
              id="price-input"
              label="Montant"
              type="number"
              inputProps={{
                step: '0.01',
                inputMode: 'decimal',
                pattern: '[0-9]*',
              }}
              disabled={isDonation}
              fullWidth
              {...register('priceInEuros')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
            <TextField
              id="price-input"
              label="Montant de l'acompte"
              type="number"
              inputProps={{
                step: '0.01',
                inputMode: 'decimal',
                pattern: '[0-9]*',
              }}
              disabled={isDonation}
              fullWidth
              {...register('depositAmount')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: 2.3 }}
              size="small"
            />
            {paymentDone === 'true' && (
              <ControlledSelectField
                control={control}
                error={errors.paymentInstallments}
                fieldName="paymentInstallments"
                label="Echelonnage du paiement"
                options={[1, 2, 3, 4, 5].map((field: number) => ({
                  label: `${field.toString()} fois`,
                  value: field,
                }))}
                sx={{ marginTop: 2.4 }}
                requiredRule=""
                size="small"
              />
            )}
            {paymentMethod === AdoptionAttemptDtoPaymentMethod.Check && paymentDone === 'true' && (
              <TextField
                id="bank-name-input"
                label="Nom de la banque"
                fullWidth
                {...register('bankName')}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ marginTop: 2 }}
              />
            )}
          </Grid>
          {/* {isUpMdWidth && (
            <Grid item md={3}>
              <PaymentInfoCard adoptionAttempt={adoptionAttempt} animal={animal} isReader={isReader} />
            </Grid>
          )} */}
          <Grid item xs={6} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.paymentDone}
              fieldName="paymentDone"
              label="État du paiement"
              options={[
                { label: 'Validé', value: 'true', color: colors.seaGreen },
                { label: 'En attente', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              size="small"
            />
            <ColoredChipSelectField
              control={control}
              error={errors.depositStatus}
              fieldName="depositStatus"
              label="État de l'acompte"
              options={[
                { label: 'Validé', value: 'true', color: colors.seaGreen },
                { label: 'En attente', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              sx={{ marginTop: 2 }}
              size="small"
            />
          </Grid>
          <Grid item xs={6} md={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              id="price-input"
              label="Montant restant à payer"
              value={`${leftToPay}€`}
              fullWidth
              inputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              sx={{ cursor: 'disabled', input: { cursor: 'not-allowed' }, mb: 0.4 }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AnimalCardSaveButton disabled={!isDirty} isLoading={isLoading} />
          </Grid>
          {/* {isDownMdWidth && (
            <Grid item xs={12}>
              <PaymentInfoCard adoptionAttempt={adoptionAttempt} animal={animal} isReader={isReader} />
            </Grid>
          )} */}
        </Grid>
      </form>
    </CollapsableCard>
  )
}
