import { toDateOnly } from '../../utils/date/DateOnly'
import { AnimalDto, AnimalHealthOperationDto, AnimalSearchItemDto, CreateAnimalBodyDto } from '../gen/backendClient'
import { backendClient } from './client'

import { SortDirection } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { AnimalFilters } from '../../domain/Animal/AnimalFiltersType'
import { formatFiltersToSend } from '../../utils/FormatFiltersToSend'
import { downloadPdf, downloadXLSX } from '../../utils/downloadCSV'
import { SortColumn } from '../../views/Animals/AnimalsTable'

export class AnimalsClient {
  public static async createAnimal(params: CreateAnimalBodyDto): Promise<AnimalDto> {
    const config: AxiosRequestConfig<CreateAnimalBodyDto> = {
      method: 'POST',
      url: '/animals',
      data: params,
    }

    const response = await backendClient<AnimalDto>(config)

    return response.data
  }

  public static async editAnimal(params: Partial<AnimalDto & { id: string }>): Promise<AnimalDto> {
    const config: AxiosRequestConfig<Partial<AnimalDto & { id: string }>> = {
      method: 'PUT',
      url: '/animals',
      data: params,
    }

    const response = await backendClient<AnimalDto>(config)

    return response.data
  }

  public static async editManyAnimals(ids: string[], updates: Partial<AnimalDto>): Promise<{ updatedCount: number }> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: '/animals/bulk-update',
      data: {
        ids,
        updates,
      },
    }

    const response = await backendClient<{ updatedCount: number }>(config)

    return response.data
  }

  public static async deleteAnimal(animalId: string): Promise<void> {
    const config: AxiosRequestConfig<{ animalId: string }> = {
      method: 'DELETE',
      url: '/animals',
      data: {
        animalId,
      },
    }

    await backendClient<void>(config)
  }

  public static async countAllAnimal(filters?: AnimalFilters, searchQuery?: string): Promise<number> {
    const params = new URLSearchParams()

    const filtersToSend = filters ? formatFiltersToSend(filters) : undefined

    if (searchQuery) {
      params.append('searchQuery', searchQuery)
    }
    if (filtersToSend) {
      params.append('filters', JSON.stringify(filtersToSend))
    }

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/count?${params.toString()}`,
    }

    const response = await backendClient<number>(config)

    return response.data
  }

  public static async getAnimalsByIds(ids: string[]): Promise<AnimalSearchItemDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/by-ids?ids=${ids.join(',')}`,
    }

    const response = await backendClient<AnimalSearchItemDto[]>(config)

    return response.data
  }

  public static async getAllAnimal(
    page = 1,
    limit = 50,
    filters?: AnimalFilters,
    searchQuery?: string,
    sortColumn?: SortColumn,
    sortDirection?: SortDirection
  ): Promise<AnimalSearchItemDto[]> {
    const params = new URLSearchParams()
    const filtersToSend = filters ? formatFiltersToSend(filters) : undefined

    if (searchQuery) {
      params.append('searchQuery', searchQuery)
    }
    if (filtersToSend) {
      params.append('filters', JSON.stringify(filtersToSend))
    }
    if (sortColumn) {
      params.append('sortColumn', sortColumn)
    }
    if (sortDirection) {
      params.append('sortDirection', sortDirection)
    }

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals?page=${page}&limit=${limit}&${params.toString()}`,
    }

    const response = await backendClient<AnimalSearchItemDto[]>(config)

    return response.data
  }

  public static async getAllAnimalForAccountId(accountId: string): Promise<AnimalSearchItemDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/accounts/${accountId}`,
    }

    const response = await backendClient<AnimalSearchItemDto[]>(config)

    return response.data
  }

  public static async getAllAnimalForHostFamilyAccountId(accountId: string): Promise<AnimalSearchItemDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/host-family/${accountId}`,
    }

    const response = await backendClient<AnimalSearchItemDto[]>(config)

    return response.data
  }

  public static async getAllAnimalForAdopterId(adopterId: string): Promise<AnimalSearchItemDto[]> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/adopters/${adopterId}`,
    }

    const response = await backendClient<AnimalSearchItemDto[]>(config)

    return response.data
  }

  public static async getAnimal(animalId: string): Promise<AnimalDto> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/${animalId}`,
    }

    const response = await backendClient<AnimalDto>(config)

    return response.data
  }

  public static async uploadProfileImage(animalId: string, image: File): Promise<string> {
    const formData = new FormData()

    formData.append('file', image)

    const config: AxiosRequestConfig<FormData> = {
      method: 'POST',
      url: `/animals/${animalId}/upload-profile-photos`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'image/',
      },
    }

    const response = await backendClient<string>(config)

    return response.data
  }

  public static async downloadAnimalsExcel(): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/export/xlsx`,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadXLSX(response.data, `animaux-${toDateOnly(dayjs())}`)
  }

  public static async downloadAnimalsICADExcel(): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/animals/export/icad-xlsx`,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadXLSX(response.data, `animaux-ICAD-export-${toDateOnly(dayjs())}`)
  }

  public static async downloadAnimalsPdf(
    accountId?: string,
    dateToCheck?: string,
    startDate?: string,
    endDate?: string
  ): Promise<void> {
    let url = '/animals/export/pdf'
    const params = []

    if (accountId) {
      params.push(`accountId=${accountId}`)
    }

    if (startDate && endDate) {
      params.push(
        `dateToCheck=${dateToCheck}`,
        `startDate=${dayjs(startDate).toISOString()}`,
        `endDate=${dayjs(endDate).toISOString()}`
      )
    }

    if (params.length) {
      url += `?${params.join('&')}`
    }
    console.log(url)
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: url,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadPdf(response.data, `animaux-${toDateOnly(dayjs())}`)
  }

  public static async generateAnnounce(animalId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/animals/${animalId}/generate-announce`,
    }

    await backendClient<void>(config)
  }

  public static async downloadVeterinaryOrderPdf(operation: AnimalHealthOperationDto, animalId: string): Promise<void> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/animals/${animalId}/veterinary-order-form/generate`,
      data: operation,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    await downloadPdf(response.data, `bon-vétérinaire-${toDateOnly(dayjs())}`)
  }
}
