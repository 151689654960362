import {
  AdopterDto,
  AdopterDtoAdoptionFormHadAnimalBefore,
  AdopterDtoAdoptionFormMainHomeType,
  AdopterDtoAdoptionFormOwnAnimalUsedToAnimals,
  AdopterDtoAdoptionFormPetIntendedForSelfOrGift,
  AdopterDtoAdoptionFormPlanForPetDuringRelocation,
  AdopterDtoAdoptionFormSex,
  AdopterDtoAdoptionFormSterilizedOrNeutered,
  AdopterDtoAdoptionFormWorkflow,
  AdopterDtoAlreadyHasAnimalOrChild,
  AdopterDtoAlreadyHasAnimalOrChildBaby,
  AdopterDtoFirstContact,
  AdopterDtoPreferenceSex,
  AdopterDtoPreferenceSterilizedOrNeutered,
  AdopterDtoProfileHasBeenAccepted,
  AdopterFollowUpDtoType,
  AdopterFollowUpDtoVerdict,
  AdoptionAttemptDocumentDtoType,
  AdoptionAttemptDtoPaymentMethod,
  AdoptionAttemptDtoSterilizationDepositStatus,
  AdoptionAttemptEventDtoType,
} from '../../interactors/gen/backendClient'
import { colors } from '../../views/theme'

export const fullName = (adopter: { firstName: string; lastName: string }) => {
  return `${adopter.firstName} ${adopter.lastName}`
}

type AdopterPropertyWithLabel = Exclude<keyof AdopterDto, 'customerId'>

export const getReadableAdopterFieldLabel = (field: AdopterPropertyWithLabel): string => {
  const map: Record<AdopterPropertyWithLabel, string> = {
    id: 'ID',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    phoneNumber: 'Numero de Télépone',
    address: 'Adresse',
    postalCode: 'Code Postal',
    city: 'Ville',
    country: 'Pays',
    preferences: 'Préférences',
    profileHasBeenAccepted: 'Profil accepté ?',
    firstContact: 'Premier contact',
    adoptionForm: 'Formulaire de pré-adoption',
    followUp: 'Suivi',
    desiredAnimal: 'Animal souhaité',
    adoptionAttempts: "Tentatives d'adoption",
    coordinates: 'Coordonnées',
    isDeleted: 'Supprimé',
    defaultVeterinary: 'Vétérinaire par défaut',
    displayPublicly: 'Afficher publiquement',
    createdWithGoogle: 'Créé avec Google',
    tutorialStep: 'Etape du tutoriel',
    verifiedUserInformation: 'Informations vérifiées',
    civility: 'Civilité',
    nationality: 'Nationalité',
    secondPhoneNumber: 'Deuxième numéro de téléphone',
  }

  return map[field]
}

export const getReadableAdopterFirstContact = (firstContact: AdopterDtoFirstContact): string => {
  const map: Record<AdopterDtoFirstContact, string> = {
    [AdopterDtoFirstContact.WithPhone]: 'Oui (téléphone)',
    [AdopterDtoFirstContact.Physical]: 'Oui (physique)',
    [AdopterDtoFirstContact.ToDo]: 'A faire',
  }

  return map[firstContact]
}

export const getColorForAdopterFirstContact = (firstContact: AdopterDtoFirstContact): string => {
  const map: Record<AdopterDtoFirstContact, string> = {
    [AdopterDtoFirstContact.WithPhone]: '#82CFFF',
    [AdopterDtoFirstContact.Physical]: '#D2E9F7',
    [AdopterDtoFirstContact.ToDo]: '#FFE7AD',
  }

  return map[firstContact]
}

export const getReadableAdopterProfileHasBeenAccepted = (
  profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted
): string => {
  const map: Record<AdopterDtoProfileHasBeenAccepted, string> = {
    [AdopterDtoProfileHasBeenAccepted.Accepted]: 'Profil accepté',
    [AdopterDtoProfileHasBeenAccepted.Refused]: 'Profil refusé',
    [AdopterDtoProfileHasBeenAccepted.Waiting]: 'Profil à valider',
    [AdopterDtoProfileHasBeenAccepted.FormSent]: 'Formulaire envoyé',
    [AdopterDtoProfileHasBeenAccepted.FormToSent]: 'Formulaire à envoyer',
    [AdopterDtoProfileHasBeenAccepted.FormAccepted]: 'Profil à valider',
    [AdopterDtoProfileHasBeenAccepted.FormRefused]: 'Formulaire refusé',
    [AdopterDtoProfileHasBeenAccepted.Blacklisted]: 'Blacklisté',
  }

  return map[profileHasBeenAccepted]
}

export const getColorForAdopterProfileHasBeenAccepted = (
  profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted
): string => {
  const map: Record<AdopterDtoProfileHasBeenAccepted, string> = {
    [AdopterDtoProfileHasBeenAccepted.Accepted]: '#5BA578',
    [AdopterDtoProfileHasBeenAccepted.Refused]: '#BC8076',
    [AdopterDtoProfileHasBeenAccepted.Waiting]: colors.yellowSemiLight,
    [AdopterDtoProfileHasBeenAccepted.FormSent]: colors.blueSky,
    [AdopterDtoProfileHasBeenAccepted.FormToSent]: colors.lightGrey,
    [AdopterDtoProfileHasBeenAccepted.FormAccepted]: colors.yellowSemiLight,
    [AdopterDtoProfileHasBeenAccepted.FormRefused]: '#BC8076',
    [AdopterDtoProfileHasBeenAccepted.Blacklisted]: '#000000',
  }

  return map[profileHasBeenAccepted]
}

export const getReadableAdopterPreferenceSex = (preferenceSex: AdopterDtoPreferenceSex): string => {
  const map: Record<AdopterDtoPreferenceSex, string> = {
    [AdopterDtoPreferenceSex.Male]: 'Mâle',
    [AdopterDtoPreferenceSex.Female]: 'Femelle',
    [AdopterDtoPreferenceSex.NoPreferences]: 'Pas de préférence',
  }

  return map[preferenceSex]
}

export const getReadableAdopterPreferenceSterilized = (
  profileHasBeenAccepted: AdopterDtoPreferenceSterilizedOrNeutered
): string => {
  const map: Record<AdopterDtoPreferenceSterilizedOrNeutered, string> = {
    [AdopterDtoPreferenceSterilizedOrNeutered.Yes]: 'Oui',
    [AdopterDtoPreferenceSterilizedOrNeutered.No]: 'Non',
    [AdopterDtoPreferenceSterilizedOrNeutered.NoPreferences]: 'Pas de préférence',
  }

  return map[profileHasBeenAccepted]
}

export const getReadableAdopterAdoptionFormSex = (sex: AdopterDtoAdoptionFormSex): string => {
  const map: Record<AdopterDtoAdoptionFormSex, string> = {
    [AdopterDtoAdoptionFormSex.Male]: 'Mâle',
    [AdopterDtoAdoptionFormSex.Female]: 'Femelle',
    [AdopterDtoAdoptionFormSex.NoPreferences]: 'Pas de préférence',
  }

  return map[sex]
}

export const getReadableAdopterAdoptionFormSterilizedOrNeutered = (
  sterilizedOrNeutered: AdopterDtoAdoptionFormSterilizedOrNeutered
): string => {
  const map: Record<AdopterDtoAdoptionFormSterilizedOrNeutered, string> = {
    [AdopterDtoAdoptionFormSterilizedOrNeutered.SterilizedOrNeutered]: 'Stérilisé/Castré',
    [AdopterDtoAdoptionFormSterilizedOrNeutered.NotSterilizedOrNeutered]: 'Pas stérilisé/Pas castré',
    [AdopterDtoAdoptionFormSterilizedOrNeutered.NoPreferences]: 'Pas de préférence',
  }

  return map[sterilizedOrNeutered]
}

export const getReadableAdopterAdoptionFormHadAnimalBefore = (
  options: AdopterDtoAdoptionFormHadAnimalBefore
): string => {
  const map: Record<AdopterDtoAdoptionFormHadAnimalBefore, string> = {
    [AdopterDtoAdoptionFormHadAnimalBefore.SameAnimal]: 'Oui, le même animal',
    [AdopterDtoAdoptionFormHadAnimalBefore.OtherAnimal]: 'Oui, un autre animal',
    [AdopterDtoAdoptionFormHadAnimalBefore.No]: 'Non, il y a un début à tout',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormYesNo = (options: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals): string => {
  const map: Record<AdopterDtoAdoptionFormOwnAnimalUsedToAnimals, string> = {
    [AdopterDtoAdoptionFormOwnAnimalUsedToAnimals.Yes]: 'Oui',
    [AdopterDtoAdoptionFormOwnAnimalUsedToAnimals.No]: 'Non',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormMainHomeType = (options: AdopterDtoAdoptionFormMainHomeType): string => {
  const map: Record<AdopterDtoAdoptionFormMainHomeType, string> = {
    [AdopterDtoAdoptionFormMainHomeType.HouseWithGardenOrCourtyard]: 'Maison avec cour ou jardin',
    [AdopterDtoAdoptionFormMainHomeType.HouseWithoutGardenOrCourtyard]: 'Maison sans cour ou jardin',
    [AdopterDtoAdoptionFormMainHomeType.ApartmentWithBalcony]: 'Appartement avec balcon',
    [AdopterDtoAdoptionFormMainHomeType.ApartmentWithoutBalcony]: 'Appartement sans balcon',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormWorkflow = (options: AdopterDtoAdoptionFormWorkflow): string => {
  const map: Record<AdopterDtoAdoptionFormWorkflow, string> = {
    [AdopterDtoAdoptionFormWorkflow.WorkFromHome]: "A domicile: télétravail, recherche d'emploi, retraite, étudiant",
    [AdopterDtoAdoptionFormWorkflow.Teleworking]: 'En télétravail 2/3 jours par semaine',
    [AdopterDtoAdoptionFormWorkflow.WorkOutside]: 'Tout le temps en dehors de mon domicile',
    [AdopterDtoAdoptionFormWorkflow.CanGoToWorkWithAnimal]: 'Je peux aller au travail avec mon animal',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormPlanForPetDuringRelocation = (
  options: AdopterDtoAdoptionFormPlanForPetDuringRelocation
): string => {
  const map: Record<AdopterDtoAdoptionFormPlanForPetDuringRelocation, string> = {
    [AdopterDtoAdoptionFormPlanForPetDuringRelocation.TakeAnimalWithMe]: "Je l'emmènerai avec moi",
    [AdopterDtoAdoptionFormPlanForPetDuringRelocation.LeaveAnimalWithSomeone]:
      'Je le confierai à une personne de confiance',
    [AdopterDtoAdoptionFormPlanForPetDuringRelocation.LeaveAnimalInShelter]:
      "Je contacterai un refuge pour qu'il trouve une nouvelle famille",
    [AdopterDtoAdoptionFormPlanForPetDuringRelocation.LeaveAnimalToWildState]: 'Je le rendrai à son état sauvage',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormPetIntendedForSelfOrGift = (
  options: AdopterDtoAdoptionFormPetIntendedForSelfOrGift
): string => {
  const map: Record<AdopterDtoAdoptionFormPetIntendedForSelfOrGift, string> = {
    [AdopterDtoAdoptionFormPetIntendedForSelfOrGift.Self]: 'Pour moi et mon foyer',
    [AdopterDtoAdoptionFormPetIntendedForSelfOrGift.Gift]: 'En cadeau à une autre personne',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormCounter = (options: AdopterDtoAlreadyHasAnimalOrChildBaby): string => {
  const map: Record<AdopterDtoAlreadyHasAnimalOrChildBaby, string> = {
    [AdopterDtoAlreadyHasAnimalOrChildBaby.None]: '0',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.One]: '1',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Two]: '2',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Three]: '3',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Four]: '4',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Five]: '5',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.MoreThanFive]: '5+',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormHomeCounter = (options: AdopterDtoAlreadyHasAnimalOrChildBaby): string => {
  const map: Record<AdopterDtoAlreadyHasAnimalOrChildBaby, string> = {
    [AdopterDtoAlreadyHasAnimalOrChildBaby.None]: 'Non',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.One]: 'Oui, 1',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Two]: 'Oui, 2',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Three]: 'Oui, 3',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Four]: 'Oui, 4',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.Five]: 'Oui, 5',
    [AdopterDtoAlreadyHasAnimalOrChildBaby.MoreThanFive]: 'Oui, plus de 5',
  }

  return map[options]
}

export const getReadableAdopterAdoptionFormHomeComposition = (
  key: keyof AdopterDtoAlreadyHasAnimalOrChild
): string | undefined => {
  const map: Record<string, string> = {
    baby: 'Bébé',
    child: 'Enfant',
    teenager: 'Adolescent',
    dog: 'Chien',
    cat: 'Chat',
    adult: 'Adulte',
    other: 'Autre',
  }

  return map[key]
}

export const getReadableAdopterFollowUpType = (options: AdopterFollowUpDtoType): string => {
  const map: Record<AdopterFollowUpDtoType, string> = {
    [AdopterFollowUpDtoType.Email]: 'Email',
    [AdopterFollowUpDtoType.HomeTour]: 'Visite du domicile',
    [AdopterFollowUpDtoType.Phone]: 'Téléphone',
    [AdopterFollowUpDtoType.Physical]: 'Rencontre physique',
    [AdopterFollowUpDtoType.VisioConference]: 'Visio',
  }

  return map[options]
}

export const getColorForAdopterFollowUpType = (options: AdopterFollowUpDtoType): string => {
  const map: Record<AdopterFollowUpDtoType, string> = {
    [AdopterFollowUpDtoType.Email]: colors.blueSky,
    [AdopterFollowUpDtoType.HomeTour]: colors.blueSky,
    [AdopterFollowUpDtoType.Phone]: colors.blueSky,
    [AdopterFollowUpDtoType.Physical]: colors.blueSky,
    [AdopterFollowUpDtoType.VisioConference]: colors.blueSky,
  }

  return map[options]
}

export const getReadableAdopterFollowUpVerdict = (options: AdopterFollowUpDtoVerdict): string => {
  const map: Record<AdopterFollowUpDtoVerdict, string> = {
    [AdopterFollowUpDtoVerdict.Accepted]: 'Profil accepté',
    [AdopterFollowUpDtoVerdict.Refused]: 'Profil refusé',
    [AdopterFollowUpDtoVerdict.Waiting]: 'Confirmation en attente',
  }

  return map[options]
}

export const getColorForAdopterFollowUpVerdict = (options: AdopterFollowUpDtoVerdict): string => {
  const map: Record<AdopterFollowUpDtoVerdict, string> = {
    [AdopterFollowUpDtoVerdict.Accepted]: '#5BA578',
    [AdopterFollowUpDtoVerdict.Refused]: '#BC8076',
    [AdopterFollowUpDtoVerdict.Waiting]: colors.yellowSemiLight,
  }

  return map[options]
}

export const getReadableEventType = (options: AdoptionAttemptEventDtoType): string => {
  const map: Record<AdoptionAttemptEventDtoType, string> = {
    [AdoptionAttemptEventDtoType.AdoptionDone]: 'Adoption terminée',
    [AdoptionAttemptEventDtoType.HomeCheck]: 'Visite du domicile',
    [AdoptionAttemptEventDtoType.MakingContact]: 'Prise de contact',
    [AdoptionAttemptEventDtoType.MeetingWithAdopter]: "Rencontre avec l'adoptant",
    [AdoptionAttemptEventDtoType.PaymentReception]: 'Réception du paiement',
    [AdoptionAttemptEventDtoType.PaymentCancelled]: 'Paiement annulé',
    [AdoptionAttemptEventDtoType.SterilizationDeposit]: 'Dépôt de stérilisation',
    [AdoptionAttemptEventDtoType.SterilizationValidation]: 'Validation de la stérilisation',
  }

  return map[options]
}

export const getReadablePaymentMethod = (options: AdoptionAttemptDtoPaymentMethod): string => {
  const map: Record<AdoptionAttemptDtoPaymentMethod, string> = {
    [AdoptionAttemptDtoPaymentMethod.Cash]: 'Espèces',
    [AdoptionAttemptDtoPaymentMethod.Check]: 'Chèque',
    [AdoptionAttemptDtoPaymentMethod.Transfer]: 'Virement',
    [AdoptionAttemptDtoPaymentMethod.UsingPetso]: 'Via Petso',
  }

  return map[options]
}

export const getReadableDepositStatus = (options: AdoptionAttemptDtoSterilizationDepositStatus): string => {
  const map: Record<AdoptionAttemptDtoSterilizationDepositStatus, string> = {
    [AdoptionAttemptDtoSterilizationDepositStatus.Paid]: 'Déposée',
    [AdoptionAttemptDtoSterilizationDepositStatus.Pending]: 'En attente',
    [AdoptionAttemptDtoSterilizationDepositStatus.Refunded]: 'Rendue',
  }

  return map[options]
}

export const getColorForDepositStatus = (options: AdoptionAttemptDtoSterilizationDepositStatus): string => {
  const map: Record<AdoptionAttemptDtoSterilizationDepositStatus, string> = {
    [AdoptionAttemptDtoSterilizationDepositStatus.Paid]: colors.seaGreen,
    [AdoptionAttemptDtoSterilizationDepositStatus.Pending]: colors.amber,
    [AdoptionAttemptDtoSterilizationDepositStatus.Refunded]: colors.red,
  }

  return map[options]
}

export const getReadableAdoptionDocumentType = (options: AdoptionAttemptDocumentDtoType): string => {
  const map: Record<AdoptionAttemptDocumentDtoType, string> = {
    [AdoptionAttemptDocumentDtoType.AdoptionContract]: "Contrat d'adoption",
    [AdoptionAttemptDocumentDtoType.HealthBook]: 'Carnet de santé',
    [AdoptionAttemptDocumentDtoType.InformationDocument]: "Document d'information",
    [AdoptionAttemptDocumentDtoType.KnowledgeCertificate]: 'Certificat de connaissance',
    [AdoptionAttemptDocumentDtoType.Others]: 'Autres',
    [AdoptionAttemptDocumentDtoType.SellCertificate]: 'Certificat de vente',
    [AdoptionAttemptDocumentDtoType.VeterinaryCertificate]: 'Certificat vétérinaire',
  }

  return map[options]
}
