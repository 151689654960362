import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tooltip,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useMutation } from 'react-query'
import { backendClient } from '../../interactors/clients/client'
import { CustomersClient } from '../../interactors/clients/CustomerClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getCustomerProfilePhotoSrc, getCustomerSignatureSrc } from '../../utils/S3-links'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { InfoBox } from '../common/InfoBox'
import { CustomEditor } from './CustomEditor'
import { useNavigate } from 'react-router-dom'
import { useAccountsStore } from '../../store/AccountsStore'

export const EditTemplateEmail: React.FC = () => {
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [signatureUrl, setSignatureUrl] = useState<string>('')
  const [editorContent, setEditorContent] = useState<string>('')
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false)
  const [emailAddress, setEmailAddress] = useState<string>('')

  const accountsStore = useAccountsStore()
  const isAdmin = accountsStore.isAdmin()

  const globalSnackbar = useGlobalSnackbarStore()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchLogoAndSignatureUrl = async () => {
      try {
        const customer = await CustomersClient.getCurrentCustomer()
        setLogoUrl(
          customer.profileImageKey
            ? getCustomerProfilePhotoSrc(customer.profileImageKey)
            : `https://logo-customer-images.s3.eu-west-3.amazonaws.com/${customer.id}.jpg`
        )
        if (customer.emailTemplate) {
          setEditorContent(customer.emailTemplate)
        }
        if (customer.signature?.fileKey) {
          setSignatureUrl(getCustomerSignatureSrc(customer.signature.fileKey))
        }
      } catch (error) {
        console.error('Error fetching customer:', error)
      }
    }

    fetchLogoAndSignatureUrl()
  }, [])

  const saveMutation = useMutation(
    async (data: string) => backendClient.post('customers/email-template', { template: data }),
    {
      onSuccess: () => {
        globalSnackbar.triggerSuccessMessage(`Vos modifications ont bien été enregistrées`)
        navigate('/suivi-adoptant?tab=settings')
      },
      onError: () => {
        globalSnackbar.triggerErrorMessage(`Une erreur est survenue lors de l'enregistrement de votre template`)
      },
    }
  )

  const previewMutation = useMutation(
    async (email: string) =>
      backendClient.post('customers/send-test-email-template', {
        email: editorContent,
        emailAddress: email,
      }),
    {
      onSuccess: () => {
        globalSnackbar.triggerSuccessMessage(`Un email de test a été envoyé à ${emailAddress}`)
        setEmailModalOpen(false)
      },
      onError: () => {
        globalSnackbar.triggerErrorMessage(`Une erreur est survenue lors de l'envoi de l'email de test`)
      },
    }
  )

  const handleSave = () => {
    if (!editorContent.includes('{{account_creation_link}}')) {
      return globalSnackbar.triggerErrorMessage('Veuillez ajouter la variable de lien de création de compte')
    }
    saveMutation.mutate(editorContent)
  }

  const handlePreview = () => {
    setEmailModalOpen(true)
  }

  const sendPreviewEmail = () => {
    if (!editorContent.includes('{{account_creation_link}}')) {
      return globalSnackbar.triggerErrorMessage('Veuillez ajouter la variable de lien de création de compte')
    }
    if (emailAddress.trim() === '') {
      globalSnackbar.triggerErrorMessage('Veuillez entrer une adresse email valide')
      return
    }
    previewMutation.mutate(emailAddress)
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          {"Votre template d'email"}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: 3 }}>
          <InfoBox
            messageType="info"
            content={`ℹ️ Utilisez la fonctionnalité "Insérer une variable" pour ajouter du texte dynamiquement à chaque envoi de mail.`}
          />

          {logoUrl === '' ? (
            <CircularProgressPanel />
          ) : (
            <CustomEditor
              editorContent={editorContent}
              setEditorContent={setEditorContent}
              logoUrl={logoUrl}
              signatureUrl={signatureUrl}
            />
          )}
          {!editorContent.includes('{{account_creation_link}}') && (
            <InfoBox
              messageType="error"
              content={`⚠️ Veuillez ajouter la variable de lien de création de compte avant d'enregister votre template.`}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Tooltip
            title={isAdmin ? 'Envoyer un email de test' : 'Seuls les administrateurs peuvent envoyer un email de test'}
          >
            <span>
              <LoadingButton
                variant="outlined"
                color="primary"
                type="button"
                loading={previewMutation.isLoading}
                onClick={handlePreview}
                disabled={!isAdmin}
              >
                Aperçu
              </LoadingButton>
            </span>
          </Tooltip>
          <Tooltip
            title={isAdmin ? 'Enregister le modèle de mail' : 'Seuls les administrateurs peuvent éditer le modèle'}
          >
            <span>
              <LoadingButton
                variant="contained"
                color="primary"
                type="button"
                loading={saveMutation.isLoading}
                onClick={handleSave}
                disabled={!isAdmin}
              >
                Enregistrer
              </LoadingButton>
            </span>
          </Tooltip>
        </Box>
      </Paper>

      <Dialog open={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
        <DialogTitle>Entrer une adresse email</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Adresse email"
            type="email"
            value={emailAddress}
            size="small"
            onChange={(e) => setEmailAddress(e.target.value)}
            sx={{ my: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEmailModalOpen(false)}>Annuler</Button>
          <LoadingButton variant="contained" onClick={sendPreviewEmail} loading={previewMutation.isLoading}>
            Envoyer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
