import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { backendClient } from '../../interactors/clients/client'
import { useAccountsStore } from '../../store/AccountsStore'
import dayjs from 'dayjs'
import { metricalpEvent } from '@metricalp/react'

interface ExcessUsersPopUpProps {
  isOpen: boolean
  close: () => void
  maxMembersNumber: number
  membersCount: number
  lockedDate: string | null
}

export const ExcessUsersPopUp: React.FC<ExcessUsersPopUpProps> = ({
  isOpen,
  close,
  maxMembersNumber,
  membersCount,
  lockedDate,
}) => {
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()

  const handleArchive = async () => {
    metricalpEvent({
      type: 'archive_members',
      customerId: accountsStore.connectedAccount?.customerId,
      accountId: accountsStore.connectedAccount?.id,
      accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
    })
    await backendClient.post('/customers/archive-members')
    await accountsStore.fetchOrRefreshMembers()
    close()
  }

  const handleChangeOffer = () => {
    metricalpEvent({
      type: 'goto_offers_from_popup',
      customerId: accountsStore.connectedAccount?.customerId,
      accountId: accountsStore.connectedAccount?.id,
      accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
    })
    navigate('/association?tab=functionalities')
    close()
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ position: 'relative', paddingRight: '40px' }}>
        <Typography variant="h6" align="center" fontWeight="bold">
          Offre non adaptée
        </Typography>
        <IconButton
          onClick={close}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ px: 3, py: 2 }}>
        <Typography variant="body1" align="center" sx={{ mb: 2, fontSize: 16 }}>
          <b>
            Vous avez actuellement {membersCount} utilisateur
            {membersCount > 1 ? 's' : ''} pour {maxMembersNumber} contenu
            {maxMembersNumber > 1 ? 's' : ''} dans votre offre.
          </b>
          <br />
          Merci de choisir une offre adaptée. Sans action de votre part, l’accès aux licences excédentaires les plus
          récentes sera désactivé.
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            ⏳ Il vous reste {lockedDate != null ? dayjs(lockedDate).add(1, 'days').diff(dayjs(), 'days') : '3'} jours.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2, p: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleArchive}
          sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: 16 }}
        >
          Archiver les comptes excédentaires
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeOffer}
          sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: 16 }}
        >
          Changer d’offre
        </Button>
      </DialogActions>
    </Dialog>
  )
}
