import { LoadingButton } from '@mui/lab'
import React, { useState } from 'react'
import { backendClient } from '../../interactors/clients/client'
import { useAccountsStore } from '../../store/AccountsStore'
import { SxProps } from '@mui/material'

interface Props {
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  sx?: SxProps
  content?: string
}

export const StripeConnectButton: React.FC<Props> = ({ variant, color, sx, content }) => {
  const [loading, setLoading] = useState(false)

  const accountsStore = useAccountsStore()

  const handleConnect = async () => {
    setLoading(true)
    try {
      const resLink = await backendClient.get('/customers/stripe-connect-link')
      const link = resLink.data

      // Redirect the user to the Stripe Connect onboarding link.
      window.location.href = link
    } catch (error) {
      console.error('Error connecting to Stripe:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'secondary'}
      fullWidth
      onClick={handleConnect}
      disabled={loading || !accountsStore.isAdmin()}
      loading={loading}
      sx={sx}
    >
      {content ?? `Configurer mon Stripe Connect`}
    </LoadingButton>
  )
}
