import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import { getReadableSpecies } from '../../../../domain/Animal/AnimalDisplay'
import { CustomersClient } from '../../../../interactors/clients/CustomerClient'
import {
  AccountDto,
  AccountDtoPermission,
  AccountDtoRoles,
  AnimalDto,
  AnimalDtoSpecies,
  ChangeOfHostedFamilyHostedFamilyFollowUpDto,
  CustomerCageDto,
  ShiftHostedFamilyFollowUpDto,
  ShiftHostedFamilyFollowUpDtoType,
} from '../../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useFetchOrRefreshMembersOnMount } from '../../../../store/useFetchOrRefreshMembersOnMount'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'
import { toDateOnly } from '../../../../utils/date/DateOnly'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { PATHS } from '../../../PATHS'
import { ControlledAutoCompleteWithTabs } from '../../../common/ControlledAutoCompleteWithTabs'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { MainHostedFamilyFollowUp } from '../HostedFammilyFollowUpsTab/HostedFamillyFollowUpsTab'
import { InfoBox } from '../../../common/InfoBox'

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

type FormParams = {
  memberInChargeAccountId: string | null
  animalLocation: string | null
  veterinaryChargeAccountId: string | null
}

export const AnimalResponsibilitiesCard: FC<Props> = ({ animal, sx }) => {
  const queryParams = new URLSearchParams(location.search)
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()

  const [customer, setCustomer] = useState(accountsStore.connectedCustomer)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false)

  useFetchOrRefreshMembersOnMount()

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setCustomer(data)
      setIsCustomerLoaded(true)
    },
  })
  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])
  const connectedAccountId = accountsStore.connectedAccount?.id
  const connectedPermission = accountsStore.connectedAccount?.permission
  const isEditor = connectedPermission === AccountDtoPermission.Editor

  const options = accountsStore.members
  const optionsForMemberInCharge = options.filter((option) => {
    return option.roles.includes(AccountDtoRoles.Member)
  })

  const optionsForHostFamilyInCharge = options.filter((option) => {
    return option.roles.includes(AccountDtoRoles.HostFamily)
  })

  const optionsForVeterinaries = options.filter((account) => account.roles.includes(AccountDtoRoles.Veterinary))

  const initialVeterinaryOption = options.find(
    (member) => member.id === animal.responsibilities?.veterinaryChargeAccountId
  )

  if (connectedAccountId) {
    options.sort((x, y) => {
      return x.id === connectedAccountId ? -1 : y.id == connectedAccountId ? 1 : 0
    })
  }

  const initialMemberInChargeOption = accountsStore.withRemovedMembers.find(
    (member) => member.id === animal.responsibilities?.memberInChargeAccountId
  )
  const initialHostFamilyOption = accountsStore.withRemovedMembers.find(
    (member) => member.id === animal.responsibilities?.hostFamilyInChargeAccountId
  )

  const getDefaultValues = () => ({
    memberInChargeAccountId: initialMemberInChargeOption?.id || null,
    animalLocation: initialHostFamilyOption?.id || animal.responsibilities?.cageNumber,
    veterinaryChargeAccountId: initialVeterinaryOption?.id || null,
  })

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useFormExtended(animal, getDefaultValues)

  useEffect(() => {
    reset(getDefaultValues())
  }, [initialMemberInChargeOption?.id])

  useEffect(() => {
    reset(getDefaultValues())
  }, [initialHostFamilyOption?.id])

  const getOptionLabel = (option: AccountDto): string => {
    let optionLabel = fullName(option)
    if (option.id === accountsStore.connectedAccount?.id) {
      optionLabel = `Moi (${optionLabel})`
    }

    if (option.isDeleted) {
      optionLabel = `Compte supprimé (${optionLabel})`
    }

    return optionLabel
  }

  const editAnimalMutation = useEditAnimalMutation({})

  //Get readable reason for error message
  const getReason = (reason: string): string => {
    switch (reason) {
      case 'has-been-adopted':
        return 'adopté'
      case 'lost':
        return `enregistré comme perdu`
      case 'dead':
        return `enregistré comme décédé`
      case 'released':
        return `relâché`
      case 'transfer-to-another-association':
        return `transféré à une autre association`
      default:
        return ''
    }
  }

  // Helper function to create a new FollowUp
  const createNewFollowUp = (
    type: ShiftHostedFamilyFollowUpDtoType,
    destination: string,
    memberInChargeAccountId: string | undefined | null
  ): ShiftHostedFamilyFollowUpDto => {
    return {
      type,
      date: toDateOnly(dayjs()),
      destination,
      memberInChargeAccountId:
        accountsStore.connectedAccount?.id ||
        memberInChargeAccountId ||
        animal.responsibilities?.memberInChargeAccountId ||
        '',
      created: {
        by: accountsStore.connectedAccount?.id || '',
        at: dayjs().toISOString(),
      },
    }
  }

  // Helper function to create a new change-of-hosted-family FollowUp
  const createChangeOfHostedFamilyFollowUp = (
    beforeAccountId: string,
    afterAccountId: string
  ): ChangeOfHostedFamilyHostedFamilyFollowUpDto => ({
    type: 'change-of-hosted-family',
    date: toDateOnly(dayjs()),
    beforeAccountId,
    afterAccountId,
    created: {
      by: accountsStore.connectedAccount?.id || '',
      at: dayjs().toISOString(),
    },
  })

  //Check if data.animalLocation starts with "A-" if it does then setting hostFamilyInChargeAccountId if it does not then setting cageNumber
  const onSubmit = async (data: Partial<FormParams>, ignoreErrors?: boolean) => {
    let hostFamilyInChargeAccountId: string | undefined

    let cageNumber: string | undefined
    let cage: CustomerCageDto | undefined

    if (data.animalLocation === null || data.animalLocation === undefined) {
      hostFamilyInChargeAccountId = animal.responsibilities?.hostFamilyInChargeAccountId
      cageNumber = animal.responsibilities?.cageNumber
    } else if (data.animalLocation?.startsWith('A-')) {
      hostFamilyInChargeAccountId = data.animalLocation
    } else {
      cageNumber = data.animalLocation
      cage = customer?.cages?.find((cage) => cage.cageNumber === cageNumber)
    }

    if (!ignoreErrors) {
      if (
        cageNumber !== animal.responsibilities?.cageNumber &&
        cage &&
        cage.animals?.length &&
        cage.animals.length >= cage.capacity
      ) {
        setModalMessage(
          `La cage sélectionnée comporte déjà ${cage.animals.length} animaux alors qu'elle a une capacité de ${cage.capacity} animaux. Êtes-vous sûr de vouloir l'assigner à cet animal ?`
        )
        setIsModalOpen(true)
        return
      }
      if (
        cageNumber !== animal.responsibilities?.cageNumber &&
        cage &&
        !cage.suitableForSpecies.includes(animal.species)
      ) {
        setModalMessage(
          `La cage sélectionnée est prévu pour des ${cage.suitableForSpecies
            .map((specie) => getReadableSpecies(specie))
            .join(' ou ')}. Êtes-vous sûr de vouloir l'assigner à cet animal (${getReadableSpecies(animal.species)}) ?`
        )
        setIsModalOpen(true)
        return
      }
      if (animalButtonShouldBeDisabled(animal)) {
        setModalMessage(
          `L'animal a été ${getReason(
            animal.adoption.status === 'has-been-adopted'
              ? animal.adoption.status
              : animal.adoption.cannotBeAdoptedStatus || ''
          )} êtes-vous sur de vouloir changer cette responsabilité ?`
        )
        setIsModalOpen(true)
        return
      }
    }

    let newFollowUp: MainHostedFamilyFollowUp | undefined = undefined
    if (!animalButtonShouldBeDisabled(animal)) {
      if (cageNumber || hostFamilyInChargeAccountId) {
        const responsibilities = animal.responsibilities

        if (responsibilities?.cageNumber || responsibilities?.hostFamilyInChargeAccountId) {
          // Check if both cage and host family are different
          if (
            responsibilities?.cageNumber !== cageNumber &&
            responsibilities?.hostFamilyInChargeAccountId !== hostFamilyInChargeAccountId
          ) {
            const destination = cageNumber ? `Box N°${cageNumber}` : hostFamilyInChargeAccountId ?? ''
            newFollowUp = createNewFollowUp('shift', destination, data.memberInChargeAccountId)
          }
          // Check if only host family has changed
          else if (responsibilities?.hostFamilyInChargeAccountId !== hostFamilyInChargeAccountId) {
            newFollowUp = createChangeOfHostedFamilyFollowUp(
              responsibilities.hostFamilyInChargeAccountId ?? '',
              hostFamilyInChargeAccountId ?? ''
            )
          }
          // Check if only cage has changed (Add this block)
          else if (responsibilities?.cageNumber !== cageNumber) {
            const destination = `Box N°${cageNumber}`
            newFollowUp = createNewFollowUp('shift', destination, data.memberInChargeAccountId)
          }
        }
        // Handle initial placement
        else {
          const destination = cageNumber ? `Box N°${cageNumber}` : hostFamilyInChargeAccountId ?? ''
          newFollowUp = createNewFollowUp('initial-placement', destination, data.memberInChargeAccountId)
        }
      }
    }

    const newResponsibilities = {
      ...animal.responsibilities,
      cageNumber: cageNumber || undefined,
      hostFamilyInChargeAccountId: hostFamilyInChargeAccountId || undefined,
      veterinaryChargeAccountId:
        data.veterinaryChargeAccountId || animal.responsibilities?.veterinaryChargeAccountId || undefined,
    }

    newResponsibilities.memberInChargeAccountId =
      data.memberInChargeAccountId || animal.responsibilities?.memberInChargeAccountId || undefined

    return await editAnimalMutation.mutate({
      ...animal,
      responsibilities: newResponsibilities,
      hostedFamilyFollowUps: {
        ...animal.hostedFamilyFollowUps,
        main: [...(animal.hostedFamilyFollowUps.main || []), newFollowUp].filter(
          (followUp) => followUp
        ) as MainHostedFamilyFollowUp[],
      },
    })
  }

  useEffect(() => {
    if (queryParams.get('tab') === 'general') {
      const newMemberInCharge = queryParams.get('memberInCharge')

      if (newMemberInCharge !== undefined && newMemberInCharge !== null) {
        onSubmit({ memberInChargeAccountId: newMemberInCharge || '' })
      }

      const newHostFamilyInCharge = queryParams.get('hostFamilyInCharge')

      if (newHostFamilyInCharge !== undefined && newHostFamilyInCharge !== null) {
        onSubmit({ animalLocation: newHostFamilyInCharge || '' })
      }

      const newCageNumber = queryParams.get('cageNumber')

      if (newCageNumber !== undefined && newCageNumber !== null) {
        setValue('animalLocation', newCageNumber)
        const cageSpecies = queryParams.get('species')
        if (cageSpecies && cageSpecies.split(',').includes(animal.species)) {
          onSubmit({ animalLocation: newCageNumber || '' })
        } else {
          setModalMessage(
            `La cage sélectionnée est prévu pour des ${
              cageSpecies &&
              cageSpecies
                .split(',')
                .map((specie) => getReadableSpecies(specie as AnimalDtoSpecies))
                .join(' ou ')
            }. Êtes-vous sûr de vouloir l'assigner à cet animal (${getReadableSpecies(animal.species)}) ?`
          )
          setIsModalOpen(true)
        }
      }
    }
  }, [])

  const memberInCharge = watch('memberInChargeAccountId')
  useEffect(() => {
    if (memberInCharge === 'addAccount') {
      reset({ memberInChargeAccountId: null })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=general&role=${AccountDtoRoles.Member}&card=animalResponsibilitiesCard`
      )
    }
  }, [memberInCharge])

  const animalLocation = watch('animalLocation')
  useEffect(() => {
    if (animalLocation === 'addAccount') {
      reset({ animalLocation: undefined })
      navigate(
        `${PATHS.ajouterBenevole.absolute}?from=animaux/${animal.id}?tab=general&role=${AccountDtoRoles.HostFamily}&card=animalResponsibilitiesCard`
      )
    } else if (animalLocation === 'addCage') {
      reset({ animalLocation: undefined })
      navigate(
        `${PATHS.association.absolute}?tab=cages&from=animaux/${animal.id}?tab=general&card=animalResponsibilitiesCard`
      )
    }
  }, [animalLocation])

  const [isButtonHidden, setIsButtonHidden] = useState(true)
  useEffect(() => {
    if (
      ([AccountDtoPermission.Read, AccountDtoPermission.Silent] as Array<AccountDtoPermission>).includes(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        connectedPermission!
      )
    ) {
      setIsButtonHidden(true)
      return
    }

    if (connectedPermission === AccountDtoPermission.Administrator) {
      console.log('admin')
      setIsButtonHidden(false)
      return
    }

    if (connectedPermission?.includes(AccountDtoPermission.Editor)) {
      const memberChangedForbidden = !initialMemberInChargeOption?.id
        ? false
        : initialMemberInChargeOption?.id !== connectedAccountId && initialMemberInChargeOption?.id !== memberInCharge

      const hostFamilyChangedForbidden = !initialHostFamilyOption?.id
        ? false
        : initialHostFamilyOption?.id !== connectedAccountId &&
          initialHostFamilyOption?.id !== animalLocation &&
          connectedAccountId !== memberInCharge

      if (memberChangedForbidden || hostFamilyChangedForbidden) {
        setIsButtonHidden(true)
        return
      }
    }

    setIsButtonHidden(false)
  })

  if (!isCustomerLoaded) {
    return (
      <Card sx={sx}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Card sx={sx}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <CardHeader title="👷🏻‍♀️ Responsabilités" />
          <CardContent sx={{ pt: '0 !important' }}>
            {isEditor &&
              !!initialHostFamilyOption?.id &&
              initialHostFamilyOption?.id !== connectedAccountId &&
              connectedAccountId !== memberInCharge && (
                <InfoBox
                  messageType="info"
                  content="Vous ne pouvez éditer que les fiches des animaux auxquels vous êtes assigné(e) (Référent, FA et/ou vétérinaire). Si nécessaire, contactez un administrateur pour vous assigner cet animal."
                  sx={{ mb: 2 }}
                />
              )}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {animalButtonShouldBeDisabled(animal) && (
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {`${animal.name} est ${getReason(
                      animal.adoption.status === 'has-been-adopted'
                        ? animal.adoption.status
                        : animal.adoption.cannotBeAdoptedStatus || ''
                    )}, il est donc archivé. Vous ne pouvez pas modifier.`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <ControlledAutoCompleteWithCustomValue
                  control={control}
                  fieldName={'memberInChargeAccountId'}
                  defaultValue=""
                  size="small"
                  options={[
                    ...(!isEditor ? [{ value: 'addAccount', label: 'Ajouter un bénévole' }] : []),
                    ...(accountsStore.connectedAccount &&
                    accountsStore.connectedAccount.roles.includes(AccountDtoRoles.Member)
                      ? [
                          {
                            value: accountsStore.connectedAccount?.id,
                            label: getOptionLabel(accountsStore.connectedAccount),
                          },
                        ]
                      : []),
                    ...(initialMemberInChargeOption && initialMemberInChargeOption.id !== connectedAccountId
                      ? [{ value: initialMemberInChargeOption.id, label: getOptionLabel(initialMemberInChargeOption) }]
                      : []),
                    ...optionsForMemberInCharge
                      .filter(
                        (option) => option.id !== connectedAccountId && option.id !== initialMemberInChargeOption?.id
                      )
                      .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                      .map((option) => ({
                        label: getOptionLabel(option),
                        value: option.id,
                      })),
                  ]}
                  label="Référent en charge"
                  removeCustomValue={isEditor}
                  error={undefined}
                  requiredRule={undefined}
                  highlight
                  disabled={
                    ([AccountDtoPermission.Read, AccountDtoPermission.Silent] as Array<AccountDtoPermission>).includes(
                      connectedPermission!
                    ) ||
                    (isEditor &&
                      !!initialMemberInChargeOption?.id &&
                      initialMemberInChargeOption?.id !== connectedAccountId)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <ControlledAutoCompleteWithTabs
                  control={control}
                  fieldName={'animalLocation'}
                  defaultValue={initialHostFamilyOption?.id || animal.responsibilities?.cageNumber || ''}
                  size="small"
                  primaryTabLabel="Famille en charge"
                  primaryOptions={[
                    ...(!isEditor ? [{ value: 'addAccount', label: 'Ajouter une famille en charge' }] : []),
                    ...(accountsStore.connectedAccount &&
                    accountsStore.connectedAccount.roles.includes(AccountDtoRoles.HostFamily)
                      ? [
                          {
                            value: accountsStore.connectedAccount?.id,
                            label: getOptionLabel(accountsStore.connectedAccount),
                          },
                        ]
                      : []),
                    ...(initialHostFamilyOption && initialHostFamilyOption.id !== connectedAccountId
                      ? [{ value: initialHostFamilyOption.id, label: getOptionLabel(initialHostFamilyOption) }]
                      : []),
                    ...optionsForHostFamilyInCharge
                      .filter((option) => option.id !== connectedAccountId)
                      .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                      .map((option) => ({
                        label: getOptionLabel(option),
                        value: option.id,
                      })),
                  ]}
                  secondaryTabLabel="Boxes"
                  secondaryOptions={
                    accountsStore.isPremium()
                      ? [
                          ...(!isEditor ? [{ value: 'addCage', label: 'Ajouter une cage' }] : []),
                          ...(customer?.cages || [])
                            .sort((a, b) => Number(a.cageNumber) - Number(b.cageNumber))
                            .map((option) => ({
                              label: `Box N°${option.cageNumber}`,
                              value: option.cageNumber,
                            })),
                        ]
                      : []
                  }
                  label="Lieu de résidence"
                  noOptionsTexts={
                    accountsStore.isPremium()
                      ? ['Aucune famille en charge', 'Aucune cage']
                      : ['Aucune famille en charge', "Vous devez souscrire à l'offre utile pour utiliser des cages"]
                  }
                  error={undefined}
                  requiredRule={undefined}
                  highlight
                  removeCustomValue={isEditor}
                  disabled={
                    ([AccountDtoPermission.Read, AccountDtoPermission.Silent] as Array<AccountDtoPermission>).includes(
                      connectedPermission!
                    ) ||
                    (isEditor &&
                      !!initialHostFamilyOption?.id &&
                      initialHostFamilyOption?.id !== connectedAccountId &&
                      connectedAccountId !== memberInCharge) ||
                    animalButtonShouldBeDisabled(animal)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ControlledAutoCompleteWithCustomValue
                  control={control}
                  fieldName={'veterinaryChargeAccountId'}
                  defaultValue=""
                  size="small"
                  options={[
                    { value: 'addAccount', label: 'Ajouter un vétérinaire' },
                    ...(accountsStore.connectedAccount &&
                    accountsStore.connectedAccount?.roles.includes(AccountDtoRoles.Veterinary)
                      ? [
                          {
                            value: accountsStore.connectedAccount?.id,
                            label: getOptionLabel(accountsStore.connectedAccount),
                          },
                        ]
                      : []),
                    ...optionsForVeterinaries
                      .filter((option) => option.id !== accountsStore.connectedAccount?.id)
                      .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                      .map((option) => ({
                        label: getOptionLabel(option),
                        value: option.id,
                      })),
                  ]}
                  label="Vétérinaire en charge"
                  error={undefined}
                  requiredRule={undefined}
                  highlight
                  disabled={
                    ([AccountDtoPermission.Read, AccountDtoPermission.Silent] as Array<AccountDtoPermission>).includes(
                      connectedPermission!
                    ) ||
                    (isEditor &&
                      !!initialHostFamilyOption?.id &&
                      initialHostFamilyOption?.id !== connectedAccountId &&
                      connectedAccountId !== memberInCharge) ||
                    animalButtonShouldBeDisabled(animal)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {/* We don't use AnimalCardSaveButton as permissions are specific for this one */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'end' }}>
                  {!isButtonHidden && (
                    <LoadingButton
                      color="primary"
                      variant="outlined"
                      type="submit"
                      loading={editAnimalMutation.isLoading}
                      disabled={animalButtonShouldBeDisabled(animal) || !isDirty}
                      sx={{ textTransform: 'none' }}
                    >
                      <Save sx={{ marginRight: 1 }} />
                      Mettre à jour
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <DialogContent>
          <h2>Attention !</h2>
          <p>{modalMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false)
              reset(getDefaultValues())
            }}
            color="error"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(false)
              onSubmit(getValues(), true)
            }}
            color="warning"
          >
            Enregistrer quand même
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
