import { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthService } from '../../interactors/services/AuthService'
import { DashboardStructure } from '../DashboardStructure'

interface Props {
  appLocked?: boolean
}

export const PrivateRoutes: FC<Props> = ({ appLocked }) => {
  return AuthService.isLogged() ? (
    appLocked ? (
      <Navigate to="/locked" />
    ) : (
      <DashboardStructure />
    )
  ) : (
    <Navigate to="/login" />
  )
}
