import { Box, Button, FormHelperText, Grid, TextField, Typography } from '@mui/material'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { omit } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  getReadableAnimalAdoptionStatus,
  getReadableAnimalBreedTitle,
  getReadableAnimalVaccineStatus,
  getReadableSpecies,
  getReadableTattooLocation,
} from '../../domain/Animal/AnimalDisplay'
import { catBreeds, dogBreeds } from '../../domain/Animal/Breeds'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AnimalBreedDtoBreedTitle,
  AnimalDtoSpecies,
  AnimalHealthDtoSterilizedOrNeutered,
  CreateAnimalBodyDto,
  CreateAnimalBodyDtoAdoptionStatus,
  CreateAnimalBodyDtoSpecies,
  CreateAnimalBodyDtoVaccinationStatus,
  TattooDtoLocation,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useDashboardLoaderStore } from '../../store/DashboardLoaderStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { readableAxiosError } from '../../utils/axios'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { omitEmptyString } from '../../utils/omitEmptyString'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { PATHS } from '../PATHS'
import { parseMixedBreed } from '../../utils/parseMixedBreed'

// We need to omit, if not it does not work
type CreateAnimalFormParams = Omit<
  CreateAnimalBodyDto,
  | 'identificationNumber'
  | 'birthday'
  | 'status'
  | 'species'
  | 'vaccinationStatus'
  | 'adoptionStatus'
  | 'sterilizedOrNeutered'
  | 'breedData'
> & {
  identificationNumber: number | ''
  tattooLocation?: string
  tattooNumber?: string
  adoptionStatus: CreateAnimalBodyDtoAdoptionStatus | ''
  species: CreateAnimalBodyDtoSpecies | ''
  vaccinationStatus: CreateAnimalBodyDtoVaccinationStatus | ''
  sterilizedOrNeutered: AnimalHealthDtoSterilizedOrNeutered | ''
  birthdayDate: string
  // 1 = true, 2 = false --- Needed because Select component does not support boolean
  isApproximateBirthdayDate?: number
  breedTitle?: AnimalBreedDtoBreedTitle
  breed?: string
  fatherBreed?: string
  motherBreed?: string
}

export const AddAnimalScreen: FC = () => {
  const accountsStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const { setIsLoading } = useDashboardLoaderStore()
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateAnimalFormParams>({
    defaultValues: {
      name: '',
      takingChargeByTheCustomerAt: '',
      lifeBeforeAssociation: '',
      identificationNumber: '',
      tattooLocation: '',
      tattooNumber: '',
      adoptionStatus: '',
      description: '',
      species: '',
      vaccinationStatus: 'unknown',
      sterilizedOrNeutered: '',
      birthdayDate: '',
      isApproximateBirthdayDate: undefined,
      breedTitle: undefined,
      breed: '',
      fatherBreed: '',
      motherBreed: '',
    },
    mode: 'onBlur',
  })

  const [serverError, setServerError] = useState('')

  const onSubmit = async (data: CreateAnimalFormParams) => {
    setIsLoading(true)
    console.log('On submit', data)

    const formattedData = omitEmptyString({
      ...omit(data, 'birthdayDate', 'isApproximateBirthdayDate', 'breedTitle', 'breed'),
      birthday: {
        date: data.birthdayDate,
        isApproximate: data.isApproximateBirthdayDate
          ? data.isApproximateBirthdayDate === 1
            ? true
            : false
          : undefined,
      },
      breed:
        data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
          ? parseMixedBreed(data.fatherBreed, data.motherBreed)
          : data.breed,
      breedData: {
        breedTitle: data.breedTitle as AnimalBreedDtoBreedTitle,
        breed:
          data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
            ? parseMixedBreed(data.fatherBreed, data.motherBreed)
            : data.breed,
      },
    }) as CreateAnimalBodyDto

    await AnimalsClient.createAnimal(formattedData)
      .then((animal) => {
        globalSnackbarStore.triggerSuccessMessage(`Le profil de ${animal.name} a été créé.`)
        navigate(`${PATHS.animals.absolute}/${animal.id}`)
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            setServerError('Cet animal existe déjà.')
            return
          }
        }

        setServerError(readableAxiosError(error).join(' '))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const species = accountsStore.connectedCustomer?.preferences.species

  useEffect(() => {
    if (species?.length === 1) {
      setValue('species', species[0])
    }
  }, [species])

  const birthdayDate = watch('birthdayDate')
  const watchBreedTitle = watch('breedTitle')
  const watchSpecies = watch('species')

  const breedOptions =
    watchSpecies === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))

  const spacingItem = { marginTop: 2 }
  return (
    <Box sx={{ width: '900px', maxWidth: '90%', marginX: 'auto', marginY: '5rem' }}>
      <Typography variant="h4">🐱 Ajouter un Animal</Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="first-name-input"
          label="Nom"
          type="text"
          required
          fullWidth
          {...register('name', { required: 'Le nom est requis' })}
          sx={spacingItem}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.species}
          fieldName="species"
          label="Espèce"
          options={
            species
              ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                  label: getReadableSpecies(status, { withEmoji: true }),
                  value: status,
                }))
              : []
          }
          requiredRule="L'espèce est requise."
          disabled={species?.length === 1}
        />

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.sex}
          fieldName="sex"
          label="Sexe"
          options={[
            { label: 'Mâle', value: 'male' },
            { label: 'Femelle', value: 'female' },
          ]}
          requiredRule=""
        />

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ControlledDateField
              sx={spacingItem}
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || !value}
              error={errors.sex}
              fieldName={'birthdayDate'}
              label="Date de Naissance"
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              sx={spacingItem}
              control={control}
              error={errors.isApproximateBirthdayDate}
              fieldName="isApproximateBirthdayDate"
              label="Est-elle précise ?"
              options={[
                { label: 'Non', value: 1 },
                { label: 'Oui', value: 2 },
              ]}
              requiredRule={birthdayDate ? 'Vous devez préciser si la date de naissance est précise ou non.' : ''}
            />
          </Grid>
        </Grid>

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.adoptionStatus}
          fieldName="adoptionStatus"
          label="Statut"
          options={Object.values(CreateAnimalBodyDtoAdoptionStatus)
            .filter((status) => status !== CreateAnimalBodyDtoAdoptionStatus['ToBeFilled'])
            .map((status: CreateAnimalBodyDtoAdoptionStatus) => ({
              label: getReadableAnimalAdoptionStatus(status),
              value: status,
            }))}
          requiredRule="Le statut est requis"
        />

        <ControlledDateField
          sx={spacingItem}
          control={control}
          validate={(value) => isValidPastDateCoherence(value) || value === null}
          error={errors.takingChargeByTheCustomerAt}
          fieldName={'takingChargeByTheCustomerAt'}
          label="Date de Prise en Charge par l'association"
          requiredRule={undefined}
        />

        <TextField
          id="city-of-origin-input"
          label="Ville de prise en charge"
          type="text"
          fullWidth
          {...register('cityOfOrigin')}
          sx={spacingItem}
          error={!!errors.cityOfOrigin}
          helperText={errors.cityOfOrigin?.message}
        />

        <TextField
          id="identification-number-input"
          label="Identification"
          type="text"
          fullWidth
          {...register('identificationNumber')}
          sx={spacingItem}
        />

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="tattoo-number-input"
              label="Tatouage"
              type="text"
              fullWidth
              {...register('tattooNumber')}
              sx={spacingItem}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              sx={spacingItem}
              control={control}
              error={errors.tattooLocation}
              fieldName="tattooLocation"
              label="Emplacement du tatouage"
              options={Object.values(TattooDtoLocation).map((value: TattooDtoLocation) => ({
                label: getReadableTattooLocation(value),
                value: value,
              }))}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ControlledSelectField
              sx={spacingItem}
              control={control}
              error={errors.breedTitle}
              fieldName="breedTitle"
              label="Titre"
              options={Object.values(AnimalBreedDtoBreedTitle).map((value: AnimalBreedDtoBreedTitle) => ({
                label: getReadableAnimalBreedTitle(value),
                value: value,
              }))}
              requiredRule={''}
            />
          </Grid>
          {watchBreedTitle !== AnimalBreedDtoBreedTitle.MixedBreed && (
            <Grid item sm={8} xs={12}>
              {watchSpecies === 'cat' || watchSpecies === 'dog' ? (
                <ControlledAutoCompleteWithCustomValue
                  control={control}
                  fieldName="breed"
                  error={errors.breed}
                  aria-label="Race"
                  label="Race"
                  requiredRule="La race est requise"
                  options={breedOptions}
                  defaultValue=""
                  sx={spacingItem}
                  freeSolo={watchSpecies === 'dog'}
                  helperText={
                    watchSpecies === 'dog'
                      ? `Si la race n'est pas dans les propositions, écrivez là et appuyez sur la touche entrée`
                      : ''
                  }
                />
              ) : (
                <TextField
                  id="breed-input"
                  label={getReadableAnimalBreedTitle(watchBreedTitle as AnimalBreedDtoBreedTitle) || 'Race'}
                  type="text"
                  fullWidth
                  {...register('breed')}
                  sx={spacingItem}
                  error={!!errors.breed}
                  required
                />
              )}
            </Grid>
          )}
          {watchBreedTitle === AnimalBreedDtoBreedTitle.MixedBreed && (
            <>
              <Grid item sm={4} xs={12}>
                {watchSpecies === 'cat' || watchSpecies === 'dog' ? (
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    fieldName="fatherBreed"
                    error={errors.fatherBreed}
                    aria-label="Race du père"
                    label="Race du père"
                    requiredRule="La race du père est requise"
                    options={breedOptions}
                    defaultValue=""
                    sx={spacingItem}
                  />
                ) : (
                  <TextField
                    id="father-breed-input"
                    label="Race du père"
                    type="text"
                    fullWidth
                    {...register('fatherBreed')}
                    sx={spacingItem}
                    error={!!errors.fatherBreed}
                    required
                  />
                )}
              </Grid>
              <Grid item sm={4} xs={12}>
                {watchSpecies === 'cat' || watchSpecies === 'dog' ? (
                  <ControlledAutoCompleteWithCustomValue
                    control={control}
                    fieldName="motherBreed"
                    error={errors.motherBreed}
                    aria-label="Race de la mère"
                    label="Race de la mère"
                    requiredRule="La race de la mère est requise"
                    options={breedOptions}
                    defaultValue=""
                    sx={spacingItem}
                  />
                ) : (
                  <TextField
                    id="mother-breed-input"
                    label="Race de la mère"
                    type="text"
                    fullWidth
                    {...register('motherBreed')}
                    sx={spacingItem}
                    error={!!errors.motherBreed}
                    required
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>

        <ControlledSelectField
          sx={spacingItem}
          control={control}
          error={errors.vaccinationStatus}
          fieldName="vaccinationStatus"
          label="💉 Primovaccination"
          options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
            (status: CreateAnimalBodyDtoVaccinationStatus) => ({
              label: getReadableAnimalVaccineStatus(status),
              value: status,
            })
          )}
          requiredRule={undefined}
        />

        <TextField
          id="description-input"
          label="Description"
          type="text"
          fullWidth
          {...register('description')}
          sx={spacingItem}
          error={!!errors.description}
          helperText={errors.description?.message}
          multiline
          rows={4}
        />

        <TextField
          id="lifeBeforeAssociation-input"
          label="Notes et parcours avant association"
          type="text"
          fullWidth
          {...register('lifeBeforeAssociation')}
          sx={spacingItem}
          error={!!errors.lifeBeforeAssociation}
          helperText={errors.lifeBeforeAssociation?.message}
          multiline
          rows={4}
        />

        {serverError && (
          <Box sx={spacingItem}>
            <Typography color="error">{serverError}</Typography>
          </Box>
        )}

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', ...spacingItem }}>
          <Button variant="contained" sx={{ width: '100%' }} type="submit">
            Ajouter l’animal
          </Button>
        </Box>
        <FormHelperText>
          Vous pourrez modifier ou ajouter des informations dans la fiche de l{"'"}animal ultérieurement.
        </FormHelperText>
      </form>
    </Box>
  )
}
