import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { ControlledToggleButton } from '../../common/ControlledToggleButton'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'

const sx = { marginTop: 2 }

interface AssociationPermissionsProps {
  association: CustomerDto
  onSubmit: (data: FormParams) => Promise<void>
}

interface FormParams {
  editorsCanCreateAdoptions: boolean
}

export const AssociationPermissions: React.FC<AssociationPermissionsProps> = ({ association, onSubmit }) => {
  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    editorsCanCreateAdoptions: customer?.editorsCanCreateAdoptions || false,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(association, getDefaultValues)

  return (
    <Card sx={sx}>
      <CardHeader title={`Gestion des permissions`} />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography>{`Autoriser les éditeurs à créer des procédures d'adoptions :`}</Typography>
            <ControlledToggleButton<FormParams>
              control={control}
              fieldName="editorsCanCreateAdoptions"
              firstOptionLabel="Oui"
              secondOptionLabel="Non"
              firstOptionValue={true}
              secondOptionValue={false}
              error={errors.editorsCanCreateAdoptions}
              requiredRule={undefined}
              sx={{ width: 'fit-content' }}
            />
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', ...sx }}>
            <MemberCardSaveButton isOwnAccount={false} />
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}
