import { Close, ReportGmailerrorredOutlined } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { backendClient } from '../../../interactors/clients/client'
import { CustomEditor } from '../../Association/CustomEditor'
import { colors } from '../../theme'

interface Props {
  animalName: string
  customerName: string
  animalId: string
  adopterId: string
}

export const ReviveAdopterPopUp: React.FC<Props> = ({ animalId, adopterId, animalName, customerName }) => {
  const [editorContent, setEditorContent] = useState<string>('')
  const [subject, setSubject] = useState<string>(`${customerName} - Votre adoption de ${animalName}`)

  const [open, setOpen] = useState<boolean>(false)
  const close = () => setOpen(false)

  useEffect(() => {
    const fetchEmail = async () => {
      if (!animalId || !adopterId) return
      try {
        const email = await backendClient.post('adopters/generate-email', {
          animalId,
          adopterId,
        })
        setEditorContent(email.data)
      } catch (error) {
        console.error('Error fetching email:', error)
      }
    }

    fetchEmail()
  }, [])

  const sendEmailAndSubmit = async () => {
    try {
      await backendClient.post('adopters/send-email', {
        adopterId,
        email: editorContent,
        subject: subject,
      })
      close()
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        sx={{
          textTransform: 'none',
          fontSize: { xs: '14px', sm: '16px' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        Relancer l&apos;adoptant
      </Button>
      <Dialog open={open} onClose={close} fullWidth maxWidth="md">
        <DialogContent>
          <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
            <Close />
          </IconButton>

          <Box sx={{ textAlign: 'center', padding: '1.5rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 1, textAlign: 'left' }}>
              <ReportGmailerrorredOutlined sx={{ color: colors.cello, fontSize: '3rem' }} />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 'bold',
                    color: colors.cello,
                  }}
                >
                  Relance de l&apos;adoptant
                </Typography>
              </Box>
            </Box>
            <TextField
              fullWidth
              label="Sujet de l'email"
              type="email"
              value={subject}
              size="small"
              onChange={(e) => setSubject(e.target.value)}
              sx={{ my: 1 }}
            />
            <CustomEditor
              editorContent={editorContent}
              setEditorContent={setEditorContent}
              logoUrl=""
              signatureUrl=""
            />

            {/* Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => sendEmailAndSubmit()}
                sx={{
                  fontWeight: 'bold',
                  mt: 1,
                  backgroundColor: colors.cello,
                }}
              >
                Envoyer l&apos;email pour relancer l&apos;adoptant
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
