import { Grid, InputAdornment, TextField } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import React from 'react'
import { UseMutationResult } from 'react-query'
import { getColorForDepositStatus, getReadableDepositStatus } from '../../../domain/Adopters/AccountDisplay'
import {
  AdopterDto,
  AdoptionAttemptDto,
  AdoptionAttemptDtoSterilizationDepositStatus,
} from '../../../interactors/gen/backendClient'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { AnimalCardSaveButton } from '../../Animals/Details/AnimalCardSaveButton'
import { CollapsableCard } from '../../common/CollapsableCard'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { ControlledDateField } from '../../common/ControlledDateField'
import { colors } from '../../theme'
import { ExitDocumentCard } from './ExitDocumentCard'
import { FileCard } from './FileCard'

interface FormParams {
  needed: string
  depositAmount: number
  depositStatus?: string
  date: string
}

interface Props {
  adopter: AdopterDto
  setAdoptionAttempt: React.Dispatch<React.SetStateAction<AdoptionAttemptDto | undefined>>
  adoptionAttempt: AdoptionAttemptDto
  updateAttemptMutation: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
  isReader: boolean
}

export const SterilizationCard: React.FC<Props> = ({
  adopter,
  setAdoptionAttempt,
  adoptionAttempt,
  updateAttemptMutation,
  isReader,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const getDefaultValues = (attempt: AdoptionAttemptDto): FormParams => ({
    needed: attempt?.sterilization?.needed === true ? 'true' : 'false',
    depositAmount: attempt?.sterilization?.depositAmount || 0,
    depositStatus: attempt?.sterilization?.depositStatus,
    date: attempt?.sterilization?.date || '',
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormExtended(adoptionAttempt, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    setIsLoading(true)

    const sterilization = {
      ...adoptionAttempt.sterilization,
      needed: data.needed === 'true',
      depositAmount: data.depositAmount,
      depositStatus: data.depositStatus as AdoptionAttemptDtoSterilizationDepositStatus,
      date: data.date,
    }

    const updatedAdoptionAttempt = {
      ...adoptionAttempt,
      sterilization,
    }

    await updateAttemptMutation.mutateAsync(updatedAdoptionAttempt)
    setIsLoading(false)
  }

  return (
    <CollapsableCard title="Stérilisation">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: 16,
          width: '100%',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.needed}
              fieldName="needed"
              label="Stérilisation par l'adoptant"
              options={[
                { label: 'Oui', value: 'true', color: colors.seaGreen },
                { label: 'Non', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              size="small"
              sx={{ mb: 2 }}
            />
            <ControlledDateField
              control={control}
              error={errors.date}
              fieldName="date"
              label="Date de stérilisation prévue"
              requiredRule=""
              size="small"
              validate={(value) => isValidPastDateCoherence(value) || value === null}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <TextField
              id="price-input"
              label="Montant de la caution"
              type="number"
              inputProps={{
                step: '0.01',
                inputMode: 'decimal',
                pattern: '[0-9]*',
              }}
              fullWidth
              {...register('depositAmount')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
              sx={{ mb: 2 }}
            />
            <ColoredChipSelectField
              control={control}
              error={errors.depositStatus}
              fieldName="depositStatus"
              label="Etat de la caution"
              options={Object.values(AdoptionAttemptDtoSterilizationDepositStatus).map((status) => ({
                label: getReadableDepositStatus(status),
                value: status,
                color: getColorForDepositStatus(status),
              }))}
              requiredRule=""
              size="small"
              sx={{ mb: 2, mt: 0.4 }}
            />
            <AnimalCardSaveButton disabled={!isDirty} isLoading={isLoading} />
          </Grid>
          <Grid item xs={6} md={6}>
            {!adoptionAttempt.sterilization?.file ? (
              <FileCard
                title={`Certificat de stérilisation`}
                type="sterilization"
                originalFileKey={undefined}
                skipProcedure
                description={`L'adoptant vous transmettra le certificat de stérilisation de l'animal. Vous pourrez le télécharger ici.`}
                adoptionAttemptId={adoptionAttempt.id}
                adopter={adopter}
                setAdoptionAttempt={setAdoptionAttempt}
                disabled={isReader}
              />
            ) : !adoptionAttempt.sterilization?.validated ? (
              <FileCard
                title="Certificat de stérilisation à valider"
                description={`${adopter.firstName} a transmis le certificat de stérilisation le ${dayjs(
                  adoptionAttempt.sterilization?.documentSentDate
                ).format('DD/MM/YYYY')}, vous pouvez le télécharger ci-dessous et le valider`}
                fileKey={adoptionAttempt.sterilization.file}
                type="sterilization"
                validation
                adopter={adopter}
                adoptionAttemptId={adoptionAttempt.id}
                adoptionAttempt={adoptionAttempt}
                setAdoptionAttempt={setAdoptionAttempt}
                disabled={isReader}
                updateAttemptMutation={updateAttemptMutation}
              />
            ) : (
              <ExitDocumentCard
                title={'Certificat de stérilisation validé'}
                type={'others'}
                adopterId={adopter.id}
                adoptionAttemptId={adoptionAttempt.id}
                originalFileKey={adoptionAttempt.sterilization.file || ''}
                disabled={isReader}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </CollapsableCard>
  )
}
