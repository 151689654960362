import { Edit } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { getReadableAnimalBreedTitle } from '../../../../domain/Animal/AnimalDisplay'
import { catBreeds, dogBreeds } from '../../../../domain/Animal/Breeds'
import { getAnimalRegistrationNumberType } from '../../../../domain/Animal/getAnimalRegistrationType'
import { AnimalBreedDtoBreedTitle, AnimalDto } from '../../../../interactors/gen/backendClient'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useDialogOpened } from '../../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { parseMixedBreed } from '../../../../utils/parseMixedBreed'
import { ControlledAnimalsAutocomplete } from '../../../common/ControlledAnimalsAutocomplete'
import { AnimalsAutocomplete } from '../../../common/ControlledAnimalsWithDetailsAutocomplete'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { SmallAnimals } from '../../AnimalBulkEdit'

type FormParams = {
  breed?: string
  breedTitle?: string
  isPedigreeRegistered?: boolean
  registrationNumber?: string
  litterNumber?: string
  numberOfAnimalsInLitter?: number
  fatherName?: string
  fatherId?: string
  fatherBreed?: string
  fatherIdentification?: string
  fatherRegistrationNumber?: string
  motherName?: string
  motherId?: string
  motherBreed?: string
  motherIdentification?: string
  motherRegistrationNumber?: string
  sameLitter?: string[]
  otherRelatives?: string[]
}

interface Props {
  animal: AnimalDto
  sameLitterAnimals: SmallAnimals[]
  setSameLitterAnimals: Dispatch<SetStateAction<SmallAnimals[]>>
  otherRelativesAnimals: SmallAnimals[]
  setOtherRelativesAnimals: Dispatch<SetStateAction<SmallAnimals[]>>
  father: SmallAnimals | null
  mother: SmallAnimals | null
}

export const EditAnimalBreedPrompt: FC<Props> = ({
  animal,
  sameLitterAnimals,
  setSameLitterAnimals,
  otherRelativesAnimals,
  setOtherRelativesAnimals,
  father,
  mother,
}) => {
  const [isFatherOutsidePetso, setIsFatherOutsidePetso] = useState<boolean>(
    (animal.breedData?.filiation?.fatherId == null || animal.breedData?.filiation?.fatherId === '') &&
      animal.breedData?.filiation?.fatherName !== '' &&
      animal.breedData?.filiation?.fatherName != null
  )
  const [isMotherOutsidePetso, setIsMotherOutsidePetso] = useState<boolean>(
    (animal.breedData?.filiation?.motherId == null || animal.breedData?.filiation?.motherId === '') &&
      animal.breedData?.filiation?.motherName !== '' &&
      animal.breedData?.filiation?.motherName != null
  )
  const dialogOpenedState = useDialogOpened()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    breed: animal.breedData?.breed || '',
    breedTitle: animal.breedData?.breedTitle || '',
    isPedigreeRegistered: animal.breedData?.isPedigreeRegistered || undefined,
    registrationNumber: animal.breedData?.registrationNumber || '',
    litterNumber: animal.breedData?.litterNumber || '',
    numberOfAnimalsInLitter: animal.breedData?.numberOfAnimalsInLitter || undefined,
    fatherName: animal.breedData?.filiation?.fatherName || '',
    fatherId: animal.breedData?.filiation?.fatherId || '',
    fatherBreed: animal.breedData?.filiation?.fatherBreed || '',
    fatherIdentification: animal.breedData?.filiation?.fatherIdentification || '',
    fatherRegistrationNumber: animal.breedData?.filiation?.fatherRegistrationNumber || '',
    motherName: animal.breedData?.filiation?.motherName || '',
    motherId: animal.breedData?.filiation?.motherId || '',
    motherBreed: animal.breedData?.filiation?.motherBreed || '',
    motherIdentification: animal.breedData?.filiation?.motherIdentification || '',
    motherRegistrationNumber: animal.breedData?.filiation?.motherRegistrationNumber || '',
    sameLitter: animal.breedData?.filiation?.sameLitter || [],
    otherRelatives: animal.breedData?.filiation?.otherRelatives || [],
  })

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormExtended(animal, getDefaultValues)

  const [serverError, setServerError] = useState('')

  const editAnimalMutation = useEditAnimalMutation({
    onSuccess: dialogOpenedState.closeDialog,
    onError: (error) => setServerError(JSON.stringify(error)),
  })

  const onSubmit = async (data: FormParams) => {
    console.log('On submit', data)

    const newAnimal = {
      ...animal,
      breed:
        data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
          ? parseMixedBreed(data.fatherBreed, data.motherBreed)
          : data.breed,
      breedData: {
        breedTitle: data.breedTitle as AnimalBreedDtoBreedTitle,
        breed:
          data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed
            ? parseMixedBreed(data.fatherBreed, data.motherBreed)
            : data.breed || '',
        numberOfAnimalsInLitter: data.numberOfAnimalsInLitter,
        litterNumber: data.litterNumber,
        isPedigreeRegistered: data.isPedigreeRegistered,
        registrationNumber: data.isPedigreeRegistered ? data.registrationNumber : undefined,
        filiation: {
          fatherName: data.fatherName,
          fatherId: data.fatherId,
          fatherBreed: data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed ? data.fatherBreed : undefined,
          fatherIdentification: data.fatherIdentification,
          fatherRegistrationNumber: data.fatherRegistrationNumber,
          motherName: data.motherName,
          motherBreed: data.breedTitle === AnimalBreedDtoBreedTitle.MixedBreed ? data.motherBreed : undefined,
          motherIdentification: data.motherIdentification,
          motherRegistrationNumber: data.motherRegistrationNumber,
          sameLitter: sameLitterAnimals.length > 0 ? sameLitterAnimals.map((animal) => animal.value) : data.sameLitter,
          otherRelatives:
            otherRelativesAnimals.length > 0
              ? otherRelativesAnimals.map((animal) => animal.value)
              : data.otherRelatives,
        },
      },
    }

    const newAnimalWithoutEmptyString = omitEmptyString(newAnimal)

    await editAnimalMutation.mutate(newAnimalWithoutEmptyString)
  }

  const spacingItem = { marginTop: 2 }

  const watchPedigree = watch('isPedigreeRegistered')

  const registrationType = getAnimalRegistrationNumberType(animal.species)
  const watchBreedTitle = watch('breedTitle')
  const canEditAnimal = useCanEditAnimal()

  const watchFatherBreed = watch('fatherBreed')
  const watchMotherBreed = watch('motherBreed')

  const breedOptions =
    animal.species === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))

  if (!canEditAnimal) {
    return null
  }

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title="Editer portée et généalogie">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>🐾 Portée et généalogie</DialogTitle>

        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <ControlledSelectField
                    sx={spacingItem}
                    control={control}
                    error={errors.breedTitle}
                    fieldName="breedTitle"
                    label="Titre"
                    options={Object.values(AnimalBreedDtoBreedTitle).map((field: AnimalBreedDtoBreedTitle) => ({
                      label: getReadableAnimalBreedTitle(field),
                      value: field,
                    }))}
                    requiredRule={'Ce champs est requis'}
                  />
                </Grid>
                {watchBreedTitle !== AnimalBreedDtoBreedTitle.MixedBreed && (
                  <Grid item sm={8} xs={12}>
                    {animal.species === 'cat' || animal.species === 'dog' ? (
                      <ControlledAutoCompleteWithCustomValue
                        control={control}
                        fieldName="breed"
                        error={errors.breed}
                        aria-label="Race"
                        label="Race"
                        requiredRule="La race est requise"
                        options={breedOptions}
                        defaultValue=""
                        sx={spacingItem}
                        freeSolo={animal.species === 'dog'}
                        helperText={
                          animal.species === 'dog'
                            ? `Si la race n'est pas dans les propositions, écrivez là et appuyez sur la touche entrée`
                            : ''
                        }
                      />
                    ) : (
                      <TextField
                        id="breed-input"
                        label={getReadableAnimalBreedTitle(watchBreedTitle as AnimalBreedDtoBreedTitle) || 'Race'}
                        type="text"
                        fullWidth
                        {...register('breed')}
                        sx={spacingItem}
                        error={!!errors.breed}
                        required
                      />
                    )}
                  </Grid>
                )}
                {watchBreedTitle === AnimalBreedDtoBreedTitle.MixedBreed && (
                  <>
                    <Grid item sm={4} xs={12}>
                      {animal.species === 'cat' || animal.species === 'dog' ? (
                        <ControlledAutoCompleteWithCustomValue
                          control={control}
                          fieldName="fatherBreed"
                          error={errors.fatherBreed}
                          aria-label="Race du père"
                          label="Race du père"
                          requiredRule={
                            (watchFatherBreed !== undefined && watchFatherBreed !== '') ||
                            (watchMotherBreed !== undefined && watchMotherBreed !== '')
                              ? undefined
                              : 'La race du père est requise'
                          }
                          options={breedOptions}
                          defaultValue=""
                          sx={spacingItem}
                          freeSolo={animal.species === 'dog'}
                          helperText={
                            animal?.species === 'dog'
                              ? `Si la race n'est pas dans les propositions, écrivez là et appuyez sur la touche entrée`
                              : ''
                          }
                        />
                      ) : (
                        <TextField
                          id="father-breed-input"
                          label="Race du père"
                          type="text"
                          fullWidth
                          {...register('fatherBreed')}
                          sx={spacingItem}
                          error={!!errors.fatherBreed}
                          required={
                            (watchFatherBreed !== undefined && watchFatherBreed !== '') ||
                            (watchMotherBreed !== undefined && watchMotherBreed !== '')
                              ? false
                              : true
                          }
                        />
                      )}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      {animal.species === 'cat' || animal.species === 'dog' ? (
                        <ControlledAutoCompleteWithCustomValue
                          control={control}
                          fieldName="motherBreed"
                          error={errors.motherBreed}
                          aria-label="Race de la mère"
                          label="Race de la mère"
                          requiredRule={
                            (watchFatherBreed !== undefined && watchFatherBreed !== '') ||
                            (watchMotherBreed !== undefined && watchMotherBreed !== '')
                              ? undefined
                              : 'La race de la mère est requise'
                          }
                          options={breedOptions}
                          defaultValue=""
                          sx={spacingItem}
                          freeSolo={animal.species === 'dog'}
                          helperText={
                            animal?.species === 'dog'
                              ? `Si la race n'est pas dans les propositions, écrivez là et appuyez sur la touche entrée`
                              : ''
                          }
                        />
                      ) : (
                        <TextField
                          id="mother-breed-input"
                          label="Race de la mère"
                          type="text"
                          fullWidth
                          {...register('motherBreed')}
                          sx={spacingItem}
                          error={!!errors.motherBreed}
                          required={
                            (watchFatherBreed !== undefined && watchFatherBreed !== '') ||
                            (watchMotherBreed !== undefined && watchMotherBreed !== '')
                              ? false
                              : true
                          }
                        />
                      )}
                    </Grid>
                  </>
                )}
                <Grid item sm={4} xs={12}>
                  <TextField
                    id="litter-number-input"
                    label="N° de portée"
                    type="text"
                    fullWidth
                    {...register('litterNumber')}
                    sx={spacingItem}
                    error={!!errors.litterNumber}
                  />
                </Grid>
                <Grid item sm={8} xs={12}>
                  <ControlledSelectField
                    sx={spacingItem}
                    control={control}
                    error={errors.numberOfAnimalsInLitter}
                    fieldName="numberOfAnimalsInLitter"
                    label="Nombre d'animaux dans la portée"
                    options={[
                      { value: 1, label: '1' },
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                      { value: 6, label: '6' },
                      { value: 7, label: '7' },
                      { value: 8, label: '8' },
                      { value: 9, label: '9' },
                      { value: 10, label: '10' },
                      { value: 11, label: '11' },
                      { value: 12, label: '12' },
                      { value: 13, label: '13' },
                      { value: 14, label: '14' },
                      { value: 15, label: '15' },
                    ]}
                    requiredRule={undefined}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={<Checkbox {...register('isPedigreeRegistered')} checked={watch('isPedigreeRegistered')} />}
                label={`Inscription à un livre généalogique ${
                  registrationType !== undefined ? `(${registrationType})` : ''
                }`}
                sx={spacingItem}
              />

              {watchPedigree && (
                <TextField
                  id="registration-number-input"
                  label={`N° ${registrationType ? registrationType : "d'identification"}`}
                  type="text"
                  fullWidth
                  {...register('registrationNumber')}
                  sx={spacingItem}
                  error={!!errors.registrationNumber}
                />
              )}

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2, mb: -1 }}>
                    Père
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  {isFatherOutsidePetso ? (
                    <TextField
                      id="father-name-input"
                      label="Nom du père"
                      type="text"
                      fullWidth
                      {...register('fatherName')}
                      sx={spacingItem}
                      error={!!errors.fatherName}
                    />
                  ) : (
                    <ControlledAnimalsAutocomplete
                      control={control}
                      initialOptions={father ? [father] : []}
                      fieldName="fatherId"
                      label="Fiche du père"
                    />
                  )}
                </Grid>
                {watchPedigree && (
                  <Grid item sm={4} xs={12}>
                    <TextField
                      id="father-registration-number-input"
                      label={`N° ${registrationType ? registrationType : "d'identification"} du père`}
                      type="text"
                      fullWidth
                      {...register('fatherRegistrationNumber')}
                      sx={spacingItem}
                      error={!!errors.fatherRegistrationNumber}
                    />
                  </Grid>
                )}
                <Grid item sm={4} xs={12}>
                  {/* <TextField
                    id="father-identification-input"
                    label="N° généalogique du père"
                    type="text"
                    fullWidth
                    {...register('fatherIdentification')}
                    sx={spacingItem}
                    error={!!errors.fatherIdentification}
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFatherOutsidePetso}
                        onChange={() => setIsFatherOutsidePetso(!isFatherOutsidePetso)}
                      />
                    }
                    label="Extérieur à l'organisation"
                    sx={spacingItem}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2, mb: -1 }}>
                    Mère
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  {isMotherOutsidePetso ? (
                    <TextField
                      id="mother-name-input"
                      label="Nom de la mère"
                      type="text"
                      fullWidth
                      {...register('motherName')}
                      sx={spacingItem}
                      error={!!errors.motherName}
                    />
                  ) : (
                    <ControlledAnimalsAutocomplete
                      initialOptions={mother ? [mother] : []}
                      control={control}
                      fieldName="motherId"
                      label="Fiche de la mère"
                    />
                  )}
                </Grid>
                {watchPedigree && (
                  <Grid item sm={4} xs={12}>
                    <TextField
                      id="mother-registration-number-input"
                      label={`N° ${registrationType ? registrationType : "d'identification"} de la mère`}
                      type="text"
                      fullWidth
                      {...register('motherRegistrationNumber')}
                      sx={spacingItem}
                      error={!!errors.motherRegistrationNumber}
                    />
                  </Grid>
                )}
                <Grid item sm={4} xs={12}>
                  {/* <TextField
                    id="mother-identification-input"
                    label="N° généalogique de la mère"
                    type="text"
                    fullWidth
                    {...register('motherIdentification')}
                    sx={spacingItem}
                    error={!!errors.motherIdentification}
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMotherOutsidePetso}
                        onChange={() => setIsMotherOutsidePetso(!isMotherOutsidePetso)}
                      />
                    }
                    label="Extérieur à l'organisation"
                    sx={spacingItem}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Issu de la même portée
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AnimalsAutocomplete
                    selectedAnimals={sameLitterAnimals}
                    setSelectedAnimals={setSameLitterAnimals}
                    label="Animaux de la même portée"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Autres frères/soeurs
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AnimalsAutocomplete
                    selectedAnimals={otherRelativesAnimals}
                    setSelectedAnimals={setOtherRelativesAnimals}
                    label="Autres frères et soeurs"
                  />
                </Grid>
              </Grid>

              {serverError && (
                <Box sx={spacingItem}>
                  <Typography color="error">{serverError}</Typography>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
                loading={editAnimalMutation.isLoading}
              >
                Mettre à jour
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
