import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AuthService } from './interactors/services/AuthService'
import { useGlobalLoaderStore } from './store/GlobalLoaderStore'
import { AddAnimalScreen } from './views/Animals/AddAnimalScreen'
import { CircularProgressPanel } from './views/common/CircularProgressPanel'
import { LoginPage } from './views/Login/LoginPage'
import { AddMemberScreen } from './views/Members/AddMemberScreen'
import { PATHS } from './views/PATHS'
import { theme } from './views/theme'
import { PrivateRoutes } from './views/utils/PrivateRoutes'

import { MetricalpReactProvider } from '@metricalp/react'
import dayjs from 'dayjs'
import { Navigate } from 'react-router-dom'
import { backendClient } from './interactors/clients/client'
import { ErrorsClient } from './interactors/clients/ErrorClient'
import { useAccountsStore } from './store/AccountsStore'
import { useGlobalSnackbarStore } from './store/GlobalSnackBarStore'
import { ScrollProvider } from './utils/hooks/ScrollContext'
import { AddAdopterScreen } from './views/Adopters/AddAdopterScreen'
import { AdopterDetailsScreen } from './views/Adopters/AdopterDetailsScreen'
import { AdoptersListScreen } from './views/Adopters/AdoptersListScreen'
import { AssignAnimalToAdopter } from './views/Adopters/AssignAnimalToAdopter'
import { AnimalBulkEdit } from './views/Animals/AnimalBulkEdit'
import { AnimalListScreen } from './views/Animals/AnimalsListScreen'
import { AnimalDetailsScreen } from './views/Animals/Details/AnimalDetailsScreen'
import { AssociationDetailsScreen } from './views/Association/AssociationDetailsScreen'
import { AddDonatorScreen } from './views/Association/DonationTracking/AddDonatorScreen'
import { SignatureScreen } from './views/Association/EditSignatureScreen'
import { EditTemplateDocument } from './views/Association/EditTemplateDocument'
import { EditTemplateEmail } from './views/Association/EditTemplateEmail'
import { SelectZonesScreen } from './views/Association/SelectZonesScreen'
import { GlobalSnackBar } from './views/common/GlobalSnackbar'
import { AdminLoginPage } from './views/Login/AdminLoginPage'
import { MapScreen } from './views/Map/MapScreen'
import { MemberDetailsScreen } from './views/Members/MembersDetailsScreen'
import { ContactListScreen } from './views/Members/MembersListScreen'
import { VeterinariesListScreen } from './views/Members/VeterinariesListScreen'
import { ResetPasswordPage } from './views/ResetPassword/ResetPasswordPage'
import { CGUPopUp } from './views/utils/CGUPopUp'
import { ExcessUsersPage } from './views/utils/LockedScreen'
import { ExcessUsersPopUp } from './views/utils/MaxMembersPopup'
import { CustomersClient } from './interactors/clients/CustomerClient'

const queryClient = new QueryClient()

const App: FC = () => {
  const membersStore = useAccountsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const { isLoading, setIsLoading } = useGlobalLoaderStore()
  const navigate = useNavigate()
  const [isCGUPopupOpen, setIsCGUPopupOpen] = useState(false)
  const [membersGaugePopUpOpen, setMembersGaugePopUpOpen] = useState(false)
  const [maxMembersNumber, setMaxMembersNumber] = useState(0)
  const [maxMembersOutpassed, setMaxMembersOutpassed] = useState(false)

  const appLockedDate = membersStore.connectedCustomer?.subscription?.appLockedAt
  const isAppLocked = appLockedDate !== undefined && dayjs(appLockedDate).isBefore(dayjs())

  useEffect(() => {
    if (membersStore.connectedAccount && !membersStore.connectedAccount?.hasAcceptedTerms) {
      setIsCGUPopupOpen(true)
    }
  }, [membersStore.connectedAccount])
  /*   useEffect(() => {
    if (
      membersStore.connectedAccount &&
      membersStore.connectedAccount?.hasAcceptedTerms &&
      !membersStore.connectedAccount?.hasSeenNewFeatures
    ) {
      setIsNewFeaturePopupOpen(true)
    }
  }, [membersStore.connectedAccount]) */

  useEffect(() => {
    const fetchCustomerSub = async () => {
      try {
        const plan = await CustomersClient.getCustomerSubscription()
        return plan
      } catch (error) {
        console.error(error)
      }
    }

    const fetchCustomerAppLockedDate = async () => {
      try {
        const lockedDate = await CustomersClient.getCustomerAppLockedDate()
        return lockedDate
      } catch (error) {
        console.error(error)
      }
    }
    // Check if the user is eligible for the popup
    const initializeLockState = async () => {
      const appLockedDate = await fetchCustomerAppLockedDate()
      const plan = await fetchCustomerSub()
      const isPremium = plan === 'premium'
      const isEssential = plan === 'essential'
      if (!isPremium && membersStore.connectedAccount?.permission === 'administrator') {
        let currentMaxMembersNumber = 0
        if (isEssential) {
          setMaxMembersNumber(10)
          currentMaxMembersNumber = 10
        } else {
          setMaxMembersNumber(2)
          currentMaxMembersNumber = 2
        }

        if (membersStore.members.filter((a) => a.permission !== 'silent').length > currentMaxMembersNumber) {
          // If the app is locked, skip showing the popup.
          const isAppLocked = appLockedDate != null && dayjs(appLockedDate).isBefore(dayjs())
          if (isAppLocked) {
            setMaxMembersOutpassed(true)
            navigate('/locked')
            return
          }

          // Set the app locked date if not already set
          if (!appLockedDate) {
            backendClient.post('/customers/set-app-locked-date')
          }

          // Get today's date in YYYY-MM-DD format
          const today = new Date().toISOString().split('T')[0]

          // Retrieve the last seen date from local storage
          const lastPopupDate = localStorage.getItem('popupLastSeenDate')

          // Only show the popup if we haven't shown it today
          if (lastPopupDate !== today) {
            setMembersGaugePopUpOpen(true)
            // Update local storage with today's date
            localStorage.setItem('popupLastSeenDate', today)
          }
        }
      }
    }

    initializeLockState()
  }, [membersStore.members])

  useEffect(() => {
    if (membersStore.connectedAccount && membersStore.connectedAccount?.permission === 'silent') {
      AuthService.logout()
      globalSnackbarStore.triggerErrorMessage(
        "Votre compte n'a pas les permissions nécessaires pour accéder à l'application. Si vous pensez que c'est une erreur, contactez un administrateur de votre association"
      )
    }
  }, [membersStore.connectedAccount])

  useEffect(() => {
    AuthService.tryLogInWithLocalToken()
      .then((account) => {
        if (account) {
          membersStore.changeConnectedAccount(account)
          if (!account.hasAcceptedTerms) {
            setIsCGUPopupOpen(true)
          }
        }
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVh() // Set on initial load

    window.addEventListener('resize', setVh) // Set on resize

    // Cleanup function
    return () => {
      window.removeEventListener('resize', setVh) // Remove event listener on unmount
    }
  }, []) // Empty dependency array so effect only runs on mount and unmount

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CircularProgressPanel />
      </ThemeProvider>
    )
  }

  return (
    <MetricalpReactProvider
      initialSharedCustomProps={{
        screen_view: {
          customerId: membersStore.connectedAccount?.customerId ?? 'not-connected',
          customerName: membersStore?.connectedAccount?.id ?? 'not-connected',
        },
      }}
      tid="mam37"
    >
      <ScrollProvider>
        <CssBaseline />

        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100 * var(--vh))',
                maxHeight: 'calc(100 * var(--vh))',
                overflowY: 'hidden',
              }}
            >
              <Routes>
                <Route path="/" element={<PrivateRoutes appLocked={isAppLocked && maxMembersOutpassed} />}>
                  <Route index element={<Navigate to={PATHS.animals.absolute} />} />

                  <Route path={PATHS.association.relative}>
                    <Route index element={<AssociationDetailsScreen />} />
                    <Route path="template" element={<EditTemplateDocument />} />
                    <Route path="signature" element={<SignatureScreen />} />
                    <Route path="zone" element={<SelectZonesScreen />} />
                    <Route path={PATHS.ajouterDonateur.relative} element={<AddDonatorScreen />} />
                  </Route>

                  <Route path={PATHS.carte.relative}>
                    <Route index element={<MapScreen />} />
                  </Route>

                  <Route path={PATHS.animals.relative}>
                    <Route index element={<AnimalListScreen />} />
                    <Route path={PATHS.ajouterAnimal.relative} element={<AddAnimalScreen />} />
                    <Route path={PATHS.animalDetails.relative} element={<AnimalDetailsScreen />} />
                    <Route path={PATHS.multiEdition.relative} element={<AnimalBulkEdit />} />
                  </Route>

                  <Route path={PATHS.suiviBenevole.relative}>
                    <Route index element={<ContactListScreen />} />
                    <Route path={PATHS.ajouterBenevole.relative} element={<AddMemberScreen />} />
                    <Route path={PATHS.accountDetails.relative} element={<MemberDetailsScreen />} />
                  </Route>

                  <Route path={PATHS.suiviVeterinaire.relative}>
                    <Route index element={<VeterinariesListScreen />} />
                    <Route path={PATHS.ajouterVeterinaire.relative} element={<AddMemberScreen />} />
                    <Route path={PATHS.veterinaryDetails.relative} element={<MemberDetailsScreen />} />
                  </Route>

                  <Route path={PATHS.suiviAdoptant.relative}>
                    <Route index element={<AdoptersListScreen />} />
                    <Route path="email-template" element={<EditTemplateEmail />} />
                    <Route path={PATHS.ajouterAdoptant.relative} element={<AddAdopterScreen />} />
                    <Route path={PATHS.adoptantDetails.relative} element={<AdopterDetailsScreen />} />
                    <Route path={PATHS.assignerAnimal.relative} element={<AssignAnimalToAdopter />} />
                  </Route>
                </Route>
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin-login" element={<AdminLoginPage />} />
                <Route
                  path="locked"
                  element={
                    <ExcessUsersPage
                      maxMembersNumber={maxMembersNumber}
                      membersCount={membersStore.members.filter((a) => a.permission !== 'silent').length}
                      appLocked={isAppLocked && maxMembersOutpassed}
                    />
                  }
                />
              </Routes>
            </Box>

            <CGUPopUp isOpen={isCGUPopupOpen} close={() => setIsCGUPopupOpen(false)} />
            <ExcessUsersPopUp
              isOpen={membersGaugePopUpOpen}
              close={() => setMembersGaugePopUpOpen(false)}
              membersCount={membersStore.members.filter((a) => a.permission !== 'silent').length}
              maxMembersNumber={maxMembersNumber}
              lockedDate={appLockedDate || null}
            />

            <GlobalSnackBar />
          </QueryClientProvider>
        </ThemeProvider>
      </ScrollProvider>
    </MetricalpReactProvider>
  )
}

// window.onerror = function (message, url, lineNumber, columnNumber, error) {
//   ErrorsClient.notifyError({ message, url, lineNumber, currentUrl: window.location.href, error }).then(() => {
//     return true
//   })
// }

window.addEventListener('error', (event) => {
  ErrorsClient.notifyError({
    message: event.message,
    currentUrl: window.location.href,
    error: event.error.toString(),
    stack: event.error.stack,
  }).then(() => {
    return true
  })
})
export default App
