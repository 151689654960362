import { Box, Button, Container, Paper, TextField, Tooltip, Typography } from '@mui/material'
import mammoth from 'mammoth'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { getReadableCustomerTemplateType } from '../../domain/Customer/CustomerDisplay'
import { backendClient } from '../../interactors/clients/client'
import { CustomerTemplateDtoType, UploadCustomerTemplateDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getCustomerProfilePhotoSrc, getCustomerSignatureSrc, getCustomerTemplatesSrc } from '../../utils/S3-links'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { CustomEditor } from './CustomEditor'
import { AxiosRequestConfig } from 'axios'
import jsPDF from 'jspdf'
import { LoadingButton } from '@mui/lab'
import { CustomersClient } from '../../interactors/clients/CustomerClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { InfoBox } from '../common/InfoBox'
import { useAccountsStore } from '../../store/AccountsStore'

interface FormData {
  documentName: string
  documentType: CustomerTemplateDtoType
}

export const EditTemplateDocument: React.FC = () => {
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [signatureUrl, setSignatureUrl] = useState<string>('')
  const location = useLocation()
  const defaultTemplate = location.state?.isDefaultTemplate as boolean
  const template = location.state?.template as {
    id: string
    name: string
    type: CustomerTemplateDtoType
    documentKey: string
  }

  useEffect(() => {
    const fetchLogoAndSignatureUrl = async () => {
      try {
        const customer = await CustomersClient.getCurrentCustomer()
        if (!customer.profileImageKey) {
          setLogoUrl(`https://logo-customer-images.s3.eu-west-3.amazonaws.com/${customer.id}.jpg`)
        } else {
          setLogoUrl(getCustomerProfilePhotoSrc(customer.profileImageKey))
        }
        if (!customer.signature?.fileKey) return
        setSignatureUrl(getCustomerSignatureSrc(customer.signature?.fileKey))
      } catch (error) {
        console.error('Error fetching customer:', error)
      }
    }

    fetchLogoAndSignatureUrl()
  }, [])

  const { handleSubmit, control, register } = useForm<FormData>({
    defaultValues: {
      documentName: template?.name || '',
      documentType: template?.type,
    },
  })

  const [editorContent, setEditorContent] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const accountsStore = useAccountsStore()
  const isAdmin = accountsStore.isAdmin()

  const globalSnackbar = useGlobalSnackbarStore()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(
          getCustomerTemplatesSrc(defaultTemplate ? 'C-1002-H2lmUwJMPpx3otc6zYjlX.html' : template.documentKey)
        ) // Assuming this URL returns the HTML content
        const htmlContent = await response.text()
        setEditorContent(htmlContent)
      } catch (error) {
        console.error('Error fetching template content:', error)
        globalSnackbar.triggerErrorMessage('Erreur lors de la récupération du contenu du template')
      }
    }

    if (template?.documentKey || defaultTemplate) {
      fetchHtmlContent()
    }
  }, [])

  const mutation = useMutation(
    async ({ data, file }: { data: UploadCustomerTemplateDto; file: File }) => {
      const formData = new FormData()
      formData.append('file', file)
      if (data.type !== undefined) {
        formData.append('type', data.type)
      }

      if (data.name !== undefined) {
        formData.append('name', data.name)
      }
      if (template) {
        return await backendClient.put(`customers/templates/${template.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }

      return await backendClient.post(`customers/templates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: () => {
        globalSnackbar.triggerSuccessMessage(`Votre template a bien été ${template ? 'modifié' : 'ajouté'} !`)
        navigate('/association?tab=templates')
      },
    }
  )

  // Function to handle the file upload and conversion
  const handleDocxUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    const reader = new FileReader()

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const arrayBuffer = e.target?.result as ArrayBuffer
      try {
        // Convert DOCX to HTML
        const { value } = await mammoth.convertToHtml({ arrayBuffer })

        // Remove all <img> tags from the resulting HTML
        let cleanedHtml = value.replace(/<img[^>]*>/g, '')

        // Replace heading tags with <p> tags and apply font sizes based on heading level
        cleanedHtml = cleanedHtml.replace(/<h1[^>]*>/g, '<p><span style="font-size: 24pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<h2[^>]*>/g, '<p><span style="font-size: 20pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<h3[^>]*>/g, '<p><span style="font-size: 18pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<h4[^>]*>/g, '<p><span style="font-size: 16pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<h5[^>]*>/g, '<p><span style="font-size: 14pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<h6[^>]*>/g, '<p><span style="font-size: 12pt; font-weight: bold;">')
        cleanedHtml = cleanedHtml.replace(/<\/h[1-6]>/g, '</span></p>')

        // Set the cleaned HTML content in the editor
        setEditorContent(cleanedHtml)
      } catch (error) {
        console.error('Error converting .docx to HTML:', error)
      }
    }

    reader.readAsArrayBuffer(file)
  }

  const showPreviewInPdf = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      const file = new File([editorContent], 'template.html')
      formData.append('file', file)
      const config: AxiosRequestConfig = {
        method: 'POST',
        url: `/adopters/generate/template`,
        responseType: 'arraybuffer', // Ensures response is a Buffer
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }
      const response = await backendClient(config)

      // Convert the ArrayBuffer to a string
      const decoder = new TextDecoder('utf-8')
      const htmlContent = decoder.decode(new Uint8Array(response.data))

      const pdf = new jsPDF('p', 'pt', 'a4') // Use points (pt) for dimensions

      // Create a container element to inject the HTML content
      const container = document.createElement('div')
      container.style.width = '565px' // Increase width to fill more of the page
      container.style.margin = '0 auto' // Center the content
      container.innerHTML = htmlContent

      // Improve HTML/CSS for PDF rendering
      container.style.fontSize = '10pt' // Slightly larger font size for better readability
      container.style.lineHeight = '1.3' // Adjust line height to prevent overlap
      container.style.overflow = 'hidden' // Prevent overflow
      container.querySelectorAll('ul').forEach((ul) => {
        ul.style.paddingInlineStart = '10px' // Adjust list indentation
        ul.style.listStylePosition = 'outside' // Adjust list style position
      })
      container.querySelectorAll('li').forEach((li) => {
        li.style.paddingLeft = '10px' // Adjust list item indentation
      })

      // Add the HTML content to the PDF
      pdf.html(container, {
        callback: (doc) => {
          // Get the PDF as a Blob
          const pdfBlob = doc.output('blob')
          // Open the PDF in a new tab
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
          setLoading(false)
          URL.revokeObjectURL(pdfUrl)
        },
        margin: [40, 40, 40, 40], // Set margins in points (20pt ~ 7mm)
        autoPaging: 'text', // Adjust paging based on text content
        html2canvas: {
          proxy: 'https://petso-backend.herokuapp.com/v1/proxy/html2canvas', // Use a proxy to avoid CORS issues
          allowTaint: false,
          useCORS: false,
          letterRendering: true,
          logging: false,
          scale: 0.9, // Return to default scale for better content fit
        },
        width: 565, // Adjusted content width
      })
    } catch (error) {
      console.error('Error converting HTML to PDF:', error)
      setLoading(false)
    }
  }

  // Function to save or upload the edited content
  const onSubmit = (data: FormData) => {
    const uploadData: UploadCustomerTemplateDto = {
      name: data.documentName,
      type: data.documentType,
    }

    mutation.mutate({ data: uploadData, file: new File([editorContent], 'template.html') })
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          {template ? "Modification d'un template" : 'Nouveau Template de document'}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: 3 }}>
            <TextField
              label="Nom du template"
              {...register('documentName', { required: true })}
              variant="outlined"
              fullWidth
              required
            />

            <ControlledSelectField<FormData>
              control={control}
              fieldName="documentType"
              label="Type de document"
              requiredRule="true"
              options={Object.values(CustomerTemplateDtoType).map((type) => ({
                value: type,
                label: getReadableCustomerTemplateType(type),
              }))}
              error={undefined}
            />

            <Button variant="contained" component="label">
              Importer un fichier Word (.docx)
              <input type="file" accept=".docx" hidden onChange={handleDocxUpload} />
            </Button>

            <InfoBox
              messageType="info"
              content={`ℹ️ Utilisez la fonctionnalité "Insérer une variable" pour ajouter du texte dynamiquement à chaque création de document`}
            />

            {logoUrl === '' ? (
              <CircularProgressPanel />
            ) : (
              <CustomEditor
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                logoUrl={logoUrl}
                signatureUrl={signatureUrl}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Tooltip title={isAdmin ? 'Générer un aperçu' : 'Seuls les administrateurs peuvent générer un aperçu'}>
              <span>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  type="button"
                  loading={loading}
                  onClick={() => showPreviewInPdf()}
                  disabled={!isAdmin}
                >
                  Aperçu
                </LoadingButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isAdmin ? 'Enregister le modèle de document' : 'Seuls les administrateurs peuvent éditer le modèle'
              }
            >
              <span>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={mutation.isLoading}
                  disabled={!isAdmin}
                >
                  Enregister
                </LoadingButton>
              </span>
            </Tooltip>
          </Box>
        </form>
      </Paper>
    </Container>
  )
}
