import { Box, Typography } from '@mui/material'
import React from 'react'
import { AdopterDto } from '../../../interactors/gen/backendClient'
import { blue } from '../../theme'
import { AddAdoptionPopUp } from '../AddAdoptionPopUp'
import { TabKey } from '../AdopterDetailsScreen'
import { OnGoingAdoptionItem } from '../AdopterOnGoingAdoptions/OnGoingAdoptionItem'
import { OnGoingAdoptionScreen } from '../AdopterOnGoingAdoptions/OnGoingAdoptionScreen'

interface Props {
  adopter: AdopterDto
  changeTab: (_: React.SyntheticEvent, newTabKey: TabKey) => void
  sendEmailToAdopter: boolean
  setSendEmailToAdopter: (value: boolean) => void
}

export const AdopterPastAdoptionsTab: React.FC<Props> = ({ adopter, sendEmailToAdopter, setSendEmailToAdopter }) => {
  const queryParams = new URLSearchParams(window.location.search)

  const doneAdoptionAttempts = adopter.adoptionAttempts?.filter(
    (attempt) => attempt.archived === true || attempt.done === true
  )
  /* const attemptAnimalIds = doneAdoptionAttempts?.map((attempt) => attempt.animalId) */

  return queryParams.get('attemptId') != null ? (
    <OnGoingAdoptionScreen adopter={adopter} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        p: 4,
        px: 6,
      }}
    >
      {doneAdoptionAttempts?.map((attempt) => (
        <OnGoingAdoptionItem key={attempt.id} adoptionAttempt={attempt} />
      ))}
      {/*       {animals
        .filter((animal) => !attemptAnimalIds?.includes(animal.id))
        .map((animal) => (
          <AdopterAdoptionItem key={animal.id} animal={animal} />
        ))} */}
      {doneAdoptionAttempts?.length === 0 ? (
        <>
          <Typography fontSize={20} color={blue} fontWeight={700} textAlign="center">
            A ce jour, aucune adoption finalisée n&#39;est enregistrée pour cet adoptant.
            <br />
            Vous pouvez lancer une procédure ou ajouter une adoption passée pour votre historique.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <AddAdoptionPopUp
              adopter={adopter}
              sendEmailToAdopter={sendEmailToAdopter}
              setSendEmailToAdopter={setSendEmailToAdopter}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
          <AddAdoptionPopUp
            adopter={adopter}
            sendEmailToAdopter={sendEmailToAdopter}
            setSendEmailToAdopter={setSendEmailToAdopter}
          />
        </Box>
      )}
    </Box>
  )
}
