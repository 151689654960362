import React from 'react'
import { Box, Typography, Button, styled } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { colors } from '../theme'
import { Link } from 'react-router-dom'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  sendEmailToAdopter: boolean
  setSendEmailToAdopter: (value: boolean) => void
  text: 'new' | 'old' | 'general' | 'import'
  vertical?: boolean
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#F8F9FE',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TextContainer = styled(Box)({
  flex: 1,
})

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 17,
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: '24px',
  padding: theme.spacing(1, 4),
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
}))

export const ChooseToSendEmailToAdopter: React.FC<Props> = ({
  sendEmailToAdopter,
  setSendEmailToAdopter,
  text,
  vertical = false,
}) => {
  const accountsStore = useAccountsStore()
  const customer = accountsStore.connectedCustomer
  const isSubscribed = customer?.subscription?.status === 'active'

  const newAdoptionText = (
    <TextContainer sx={{ color: colors.blueSky, fontWeight: 600, fontSize: 13 }}>
      <Typography variant="h6" fontWeight={700} gutterBottom color={colors.blue} fontSize={17}>
        Envoyer un mail à l’adoptant ?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Dans le cadre de l’adoption un mail de <b>contact@petso.fr</b> sera envoyé à l’adoptant pour lui permettre
        d’accéder au carnet de santé de l’animal (prochains vaccins, etc…), de consulter et signer vos documents &amp;
        bénéficier de suggestions partenaires,{' '}
        <span style={{ textDecoration: 'underline', fontWeight: 700 }}>merci de le prévenir de notre démarche</span>.
      </Typography>
      {!isSubscribed && (
        <Typography variant="body2" fontWeight={600} color="black">
          Le choix est réservé à l’offre premium,{' '}
          <Link to="/association?tab=functionalities" style={{ textDecoration: 'underline', color: 'black' }}>
            consulter les abonnements
          </Link>
          .
        </Typography>
      )}
    </TextContainer>
  )

  const oldAdoptionText = (
    <TextContainer sx={{ color: colors.blueSky, fontWeight: 600, fontSize: 13 }}>
      <Typography variant="h6" fontWeight={700} gutterBottom color={colors.blue} fontSize={17}>
        Souhaitez-vous transmettre le carnet digital de l’animal ?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Dans le cadre de l’adoption un mail de <b>contact@petso.fr</b> sera envoyé à l’adoptant pour lui permettre
        d’accéder au carnet de santé de l’animal (prochains vaccins. etc...) et de bénéficier de suggestions
        partenaires,{' '}
        <span style={{ textDecoration: 'underline', fontWeight: 700 }}>merci de le prévenir de notre démarche</span>.
      </Typography>
      {!isSubscribed && (
        <Typography variant="body2" fontWeight={600} color="black">
          Le choix est réservé à l’offre premium,{' '}
          <Link to="/association?tab=functionalities" style={{ textDecoration: 'underline', color: 'black' }}>
            consulter les abonnements
          </Link>
          .
        </Typography>
      )}
    </TextContainer>
  )

  const generalText = (
    <TextContainer sx={{ color: colors.blueSky, fontWeight: 600, fontSize: 13 }}>
      <Typography variant="h6" fontWeight={700} gutterBottom color={colors.blue} fontSize={17}>
        Envoyer un mail à l’adoptant ?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Dans le cadre de l’adoption un mail de <b>contact@petso.fr</b> sera envoyé à l’adoptant pour lui permettre
        d’accéder au carnet de santé de l’animal (prochains vaccins. etc...), de bénéficier de suggestions partenaires
        et si nécessaire de signer vos documents transmis,{' '}
        <span style={{ textDecoration: 'underline', fontWeight: 700 }}>merci de le prévenir de notre démarche</span>.
      </Typography>
      {!isSubscribed && (
        <Typography variant="body2" fontWeight={600} color="black">
          Le choix est réservé à l’offre premium,{' '}
          <Link to="/association?tab=functionalities" style={{ textDecoration: 'underline', color: 'black' }}>
            consulter les abonnements
          </Link>
          .
        </Typography>
      )}
    </TextContainer>
  )

  const importText = (
    <TextContainer sx={{ color: colors.blueSky, fontWeight: 600, fontSize: 13 }}>
      <Typography variant="h6" fontWeight={700} gutterBottom color={colors.blue} fontSize={17}>
        Souhaitez-vous transmettre le carnet digital des animaux aux adoptants ?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Dans le cadre de l’adoption un mail de <b>contact@petso.fr</b> sera envoyé aux adoptants pour leur permettre
        d’accéder au carnet de santé de leurs animaux (prochains vaccins. etc...) et de bénéficier de suggestions
        partenaires,{' '}
        <span style={{ textDecoration: 'underline', fontWeight: 700 }}>merci de les prévenir de notre démarche</span>.
      </Typography>
      {!isSubscribed && (
        <Typography variant="body2" fontWeight={600} color="black">
          Le choix est réservé à l’offre premium,{' '}
          <Link to="/association?tab=functionalities" style={{ textDecoration: 'underline', color: 'black' }}>
            consulter les abonnements
          </Link>
          .
        </Typography>
      )}
    </TextContainer>
  )

  const renderText = () => {
    switch (text) {
      case 'new':
        return newAdoptionText
      case 'old':
        return oldAdoptionText
      case 'general':
        return generalText
      case 'import':
        return importText
      default:
        return newAdoptionText
    }
  }
  return (
    <Container sx={{ gap: 2, flexDirection: vertical ? 'column' : { xs: 'column', md: 'row' } }}>
      {renderText()}

      <Box display="flex" alignItems="center" gap={2}>
        <StyledButton
          variant={sendEmailToAdopter ? 'contained' : 'outlined'}
          color="secondary"
          onClick={() => setSendEmailToAdopter(true)}
          className={sendEmailToAdopter ? 'Mui-selected' : ''}
        >
          Oui
        </StyledButton>
        <StyledButton
          variant={!sendEmailToAdopter ? 'contained' : 'outlined'}
          color="secondary"
          onClick={() => setSendEmailToAdopter(false)}
          className={!sendEmailToAdopter ? 'Mui-selected' : ''}
          startIcon={!isSubscribed ? <LockIcon sx={{ color: colors.blue }} /> : undefined}
          disabled={!isSubscribed}
        >
          Non
        </StyledButton>
      </Box>
    </Container>
  )
}
